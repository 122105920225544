import React from 'react';

export default function TextAnswer() {
  return (
    <div className='ml-6 flex flex-col gap-4'>
      <input
        className='w-full cursor-default border-b focus:outline-none'
        placeholder={'Text answer'} // to translate
        readOnly
      />
    </div>
  );
}
