import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/forms/Buttons/Button';
import Input from '../../../components/forms/Input/Input';
import Select from '../../../components/forms/Select/Select';
import {
  acceptApplication,
  patchApplication,
} from '../../../services/application/application';
import { getDistricts } from '../../../services/catalog/catalog';
import useSnackbar from '../../../utils/hooks/useSnackbar';
import {
  getAvailableClassrooms,
  getAvailableSeats,
} from '../../../services/application/classroomService';

export default function ExaminationDetails({
  applicant,
  examDates,
  vulnerableDocsLength,
}) {
  const [t] = useTranslation();
  const { addSnackbar } = useSnackbar();

  const [districts, setDistricts] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [seats, setSeats] = useState([]);
  const [examCenters, setExamCenters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isRegion, setIsRegion] = useState(false);
  const [examTime, setExamTime] = useState();
  const [examCenter, setExamCenter] = useState();
  const [classroom, setClassroom] = useState();
  const [districtsLoading, setDistrictsLoading] = useState(false);
  const [classroomsLoading, setClassroomsLoading] = useState(false);
  const [seatsLoading, setSeatsLoading] = useState(false);

  const {
    formState: { errors },
    control,
    // register,
    handleSubmit,
    setValue,
    // watch,
  } = useForm();

  useEffect(() => {
    if (applicant?.school?.uuid) {
      setDistrictsLoading(true);
      getDistricts({ school: applicant?.school?.uuid })
        .then((res) => {
          const options = res.results
            .filter((dist) => dist?.is_geographical)
            .map((item) => ({
              label: item.name,
              value: item.uuid,
              code: item.district_id,
            }));
          setDistricts(options);

          const centers = res.results
            .filter((dist) => dist?.is_exam_center)
            .map((item) => ({
              label: item.name + (item?.address ? ' - ' + item.address : ''),
              value: item.uuid,
            }));
          setExamCenters(centers);
        })
        .finally(() => setDistrictsLoading(false));
    }

    return () => {};
  }, [applicant]);

  useEffect(() => {
    setSeats([]);
    setClassroom(null);
    setValue('classroom_id', null);
    setValue('seat_id', null);

    if (examTime && examCenter) {
      setClassroomsLoading(true);
      getAvailableClassrooms({
        exam_time: examTime,
        exam_center: examCenter,
        page_size: 50,
      })
        .then((res) => {
          setClassrooms(
            res.results?.sort((a, b) =>
              parseInt(a.classroom_id) > parseInt(b.classroom_id) ? 1 : -1,
            ),
          );
        })
        .finally(() => setClassroomsLoading(false));
    } else {
      setClassrooms([]);
    }
  }, [examTime, examCenter, setValue]);

  useEffect(() => {
    setValue('seat_id', null);
    if (classroom) {
      setSeatsLoading(true);
      getAvailableSeats({
        classroom,
        page_size: 500,
      })
        .then((res) => {
          setSeats(
            res.results?.sort((a, b) =>
              parseInt(a.seat_id) > parseInt(b.seat_id) ? 1 : -1,
            ),
          );
        })
        .finally(() => setSeatsLoading(false));
    } else {
      setSeats([]);
    }
  }, [classroom, setValue]);

  useEffect(() => {
    if (applicant?.uuid) {
      setValue('district', applicant?.district?.uuid);
      setValue('examCenter', applicant?.exam_center?.uuid);
      setExamCenter(applicant?.exam_center?.uuid);
    }

    return () => {};
  }, [applicant, setValue]);

  const getAspStatus = () => {
    const socialDocs = applicant?.social_doc_type;
    if (socialDocs?.uuid) {
      if (socialDocs?.files?.length === vulnerableDocsLength) {
        return 2;
      } else {
        return 1;
      }
    }
    return 0;
  };

  const onSubmit = (data) => {
    setLoading(true);
    const body = {
      exam_center: data.examCenter,
      district: data.district,
      classroom: data.room,
      seat: data.place,
      exam: data.exam,
      seat_id: data.seat_id,
      classroom_id: data.classroom_id,
      asp_status: getAspStatus(),
    };
    // console.log('body', body);
    patchApplication(applicant?.uuid, body)
      .then(() => {
        acceptApplication(applicant.uuid, {})
          .then(() => {
            addSnackbar(t('Успешно'), 'success');
          })
          .catch(() => {
            addSnackbar(t('Ошибка'), 'error');
          });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className=''>
      <div className='border-b px-4 py-4'>
        <h3 className='text-lg font-medium leading-6 text-gray-900'>
          Указать данные экзамена #1
        </h3>
      </div>
      <div className='mt-10 sm:mt-0'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='mt-5 md:col-span-3 md:mt-0'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='shadow sm:rounded-md'>
                <div className='bg-white px-4 py-5 sm:p-6'>
                  <div className='grid grid-cols-6 gap-6'>
                    <Controller
                      control={control}
                      name='examCenter'
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select
                          label={'Экзаменационный центр'}
                          loading={districtsLoading}
                          required
                          errorMsg={errors?.district?.type ? 'Ошибка' : ''}
                          className='col-span-6'
                          value={field.value}
                          options={examCenters}
                          // placeholder={t('announcement.select_positions')}
                          onChange={(e) => {
                            setExamCenter(e.value);
                            // setSelectedDistrict(e);
                            field.onChange(e.value);
                          }}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name='district'
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select
                          label={'Район'}
                          loading={districtsLoading}
                          required
                          errorMsg={errors?.district?.type ? 'Ошибка' : ''}
                          className='col-span-6'
                          value={field.value}
                          options={districts}
                          // placeholder={t('announcement.select_positions')}
                          onChange={(e) => {
                            // setSelectedDistrict(e);
                            let isRegion = !(
                              ['003', '21']?.includes(
                                applicant.school.school_id,
                              )
                                ? ['01', '99']
                                : ['01']
                            )?.includes(e.code);
                            setIsRegion(isRegion);
                            if (isRegion) {
                              setValue('exam', examDates?.[0]?.value);
                            }

                            // (["003", "21"]?.includes(applicant.school.school_id) ? ["01", "99"] : ["01"])?.includes(districts?.find(e => e.uuid === watch('district'))?.code)

                            field.onChange(e.value);
                          }}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name='exam'
                      rules={{ required: true }}
                      render={({ field }) => (
                        <>
                          <Select
                            isDisabled={isRegion}
                            label={'Выберите дату'}
                            required
                            errorMsg={errors?.district?.type ? 'Ошибка' : ''}
                            className='col-span-6'
                            value={field.value}
                            options={examDates}
                            onChange={(e) => {
                              field.onChange(e.value);
                              setExamTime(e.value);
                            }}
                          />
                        </>
                      )}
                    />
                    <Controller
                      control={control}
                      name='classroom_id'
                      rules={{ required: true }}
                      render={({ field }) => (
                        <div className='col-span-6 flex flex-col'>
                          <Select
                            loading={classroomsLoading}
                            isDisabled={
                              !(examCenter && examTime) || classroomsLoading
                            }
                            label={'Аудитория'}
                            required
                            errorMsg={errors?.district?.type ? 'Ошибка' : ''}
                            className=''
                            value={field.value}
                            options={classrooms.map((s) => ({
                              label: s.classroom_id,
                              value: s.uuid,
                            }))}
                            onChange={(e) => {
                              setClassroom(e.value);
                              field.onChange(e.value);
                            }}
                          />
                          <span className='text-error'>
                            {!classroomsLoading &&
                              examCenter &&
                              examTime &&
                              !classrooms.length &&
                              'Нет свободных аудитории в это время'}
                          </span>
                        </div>
                      )}
                    />
                    <Controller
                      control={control}
                      name='seat_id'
                      rules={{ required: true }}
                      render={({ field }) => (
                        <>
                          <Select
                            isDisabled={!seats?.length || seatsLoading}
                            loading={seatsLoading}
                            label={'Место'}
                            required
                            errorMsg={errors?.district?.type ? 'Ошибка' : ''}
                            className='col-span-6'
                            value={field.value}
                            options={seats.map((s) => ({
                              label: s.seat_id,
                              value: s.uuid,
                            }))}
                            onChange={(e) => {
                              field.onChange(e.value);
                            }}
                          />
                        </>
                      )}
                    />

                    {/* <Controller
                      control={control}
                      name='examTime'
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select
                          label={'Выберите время'}
                          required
                          errorMsg={errors?.district?.type ? 'Ошибка' : ''}
                          className='col-span-6 text-xs sm:col-span-3'
                          value={field.value}
                          options={[
                            {
                              label:
                                '10:00 Астана уақыты / Время Астаны \n 09:00 Батыс Қазақстан уақыты / По времени западного Казахстана',
                              value: '0',
                            },
                            {
                              label:
                                '14:00 Астана уақыты / Время Астаны \n 13:00 Батыс Қазақстан уақыты / По времени западного Казахстана',
                              value: '1',
                            },
                          ]}
                          // placeholder={t('announcement.select_positions')}
                          onChange={(e) => {
                            // setSelectedDistrict(e);
                            field.onChange(e.value);
                          }}
                        />
                      )}
                    /> */}
                  </div>
                </div>
                <div className='bg-gray-50 px-4 py-2 text-right sm:px-6'>
                  {applicant?.social_doc_type?.uuid &&
                    vulnerableDocsLength === 0 && (
                      <div className='py-1 text-sm'>
                        Загрузите ӘОТ документы
                      </div>
                    )}
                  <Button
                    loading={loading}
                    disabled={
                      applicant?.social_doc_type?.uuid &&
                      vulnerableDocsLength === 0
                    }
                    type='submit'
                    text='Принять ученика'
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
