import React from 'react';
import withLoading from '../../../../utils/hocs/withLoading';
import BarChart from './CHARTS/BarChart';
import PieChart from './CHARTS/PieChart';
import AnswerList from './AnswerList';

function groupByRange(data, range) {
  let grouped = {};

  for (let key in data) {
    let numKey = parseInt(key, 10);
    let group = `${Math.floor((numKey - 1) / range) * range + 1}-${
      Math.floor((numKey - 1) / range) * range + range
    }`;

    if (!grouped[group]) {
      grouped[group] = 0;
    }
    grouped[group] += data[key];
  }

  return grouped;
}

export default withLoading(function ContentList({ data }) {
  return (
    <>
      {data.contents?.map((content, i) => {
        let stats = data.stats[content.uuid];
        if (
          content.stats_type === 'VBARCHART' &&
          content.test_type === 'INTEGER'
        ) {
          let data = {};
          stats
            ?.filter((stat) => stat.value <= 70 && stat.value >= 26)
            ?.forEach((e) => (data[e.value] = e.count));
          data = groupByRange(data, 5);
          stats = Object.keys(data).map((key) => ({
            value: key,
            count: data[key],
          }));
        }

        // if (content.stats_type === 'HBARCHART') {
        //   stats = stats?.sort((a, b) => (a.count < b.count ? 1 : -1));
        // }

        const labels = stats?.map((s) => s.value);
        const series = stats?.map((s) => s.count);

        return (
          <div
            className={`relative flex flex-col gap-2 rounded-lg bg-white p-6 focus:border`}
            key={content?.uuid}
          >
            <div
              className='pb-2'
              dangerouslySetInnerHTML={{
                __html: content.name,
              }}
            />
            {content.stats_type === 'HBARCHART' && (
              <BarChart
                labels={labels}
                series={series}
                isHorizontal
                max={data.stats.count}
              />
            )}
            {content.stats_type === 'VBARCHART' && (
              <BarChart labels={labels} series={series} />
            )}
            {content.stats_type === 'PIECHART' && (
              <PieChart labels={labels} series={series} />
            )}
            {(content.stats_type === 'LIST' || content.allow_other) && (
              <AnswerList
                data={{ content, school: data.school, examYear: data.examYear }}
              />
            )}
          </div>
        );
      })}
    </>
  );
});
