import React, { useState } from 'react';
import { Button } from '../../../components/forms/Buttons/Button';
import { PlusIcon } from '@heroicons/react/24/outline';
import CustomDialog from '../../../components/modals/CustomDialog/CustomDialog';
import { Controller, useForm } from 'react-hook-form';
import Select from '../../../components/forms/Select/Select';
import {
  getSchoolGoalDetails,
  postSchoolGoal,
} from '../../../services/core/schoolGoalsService';
import Input from '../../../components/forms/Input/Input';
import { toast } from 'react-toastify';

export default function Create({ schools, getData, examYear, prevYear }) {
  const [open, setOpen] = useState(false);
  const [prevYearGoal, setPrevYearGoal] = useState();

  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: { errors },
  } = useForm();

  const onClose = () => {
    reset();
    setPrevYearGoal();
    setOpen(false);
  };
  const onSubmit = (body) => {
    postSchoolGoal({ ...body, exam_year: examYear?.uuid })
      .then(() => {
        toast.success('Created');
        onClose();
        getData();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error);
      })
      ?.finally(() => {});
  };

  return (
    <>
      <div className='flex flex-row items-center gap-4'>
        <Button
          text={'Создать'}
          false
          Icon={PlusIcon}
          onClick={() => setOpen(true)}
        />
      </div>
      <CustomDialog
        isOpen={open}
        headerText={`Создать цель ${examYear?.year}`}
        closeModal={() => {
          onClose();
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='flex min-h-[12rem] flex-col justify-between gap-2'
        >
          <div className='flex flex-col gap-2'>
            <Controller
              control={control}
              name={`school`}
              rules={{
                required: 'School is required',
              }}
              render={({ field }) => (
                <Select
                  required
                  label='Школы'
                  className='w-full'
                  errorMsg={errors.school?.message}
                  value={field.value}
                  options={schools.map((school) => ({
                    value: school.uuid,
                    label: school.name,
                  }))}
                  onChange={(e) => {
                    field.onChange(e.value);
                    getSchoolGoalDetails({
                      school: e.value,
                      exam_year: prevYear?.uuid,
                    }).then((res) => setPrevYearGoal(res));
                  }}
                />
              )}
            />
          </div>
          {prevYearGoal && (
            <div className='flex flex-col'>
              <span>Данные прошлого года({prevYear.year})</span>
              <span>Подали заявок: {prevYearGoal.application_count}</span>
              <span>Цель: {prevYearGoal.goal}</span>
            </div>
          )}
          <Input
            label={`Цели (${examYear?.year})`}
            required
            errorMsg={errors.goal?.message}
            type='number'
            {...register('goal', {
              required: 'Goal is required',
              min: { value: 0, message: 'Min. 0' },
              value: 0,
            })}
          />
          <div className='flex flex-row justify-end'>
            <Button text='Создать' type='submit' color='success' />
          </div>
        </form>
      </CustomDialog>
    </>
  );
}
