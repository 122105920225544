import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useForm from '../useForm';
import { getFormStats } from '../../../../services/forms/formStatsService';
import { ExamYearContext } from '../../../../utils/contexts/ExamYearContext';
import ContentList from './ContentList';
import Select from '../../../../components/forms/Select/Select';
import { getSchools } from '../../../../services/catalog/catalog';
import { UserContext } from '../../../../utils/contexts/UserContext';

export default function FormStats({ UUID }) {
  const { user } = useContext(UserContext);
  const { uuid } = useParams();
  const { examYear } = useContext(ExamYearContext);
  const { form, contents } = useForm(UUID ?? uuid);
  const [schools, setSchools] = useState([]);
  const [school, setSchool] = useState(null);
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(false);
  const [schoolLoading, setSchoolLoading] = useState(false);

  useEffect(() => {
    setSchoolLoading(true);
    if (user?.roles?.some((r) => r.code === 'VP')) {
      setSchools([user.school]);
      setSchool(user.school?.uuid);
      setSchoolLoading(false);
    } else {
      getSchools({ exam_year: examYear?.uuid })
        ?.then((res) => {
          setSchools(res.results);
        })
        .finally(() => setSchoolLoading(false));
    }
  }, [examYear?.uuid, user?.roles, user.school]);

  useEffect(() => {
    const controller = new AbortController();

    setLoading(true);
    getFormStats({
      params: {
        form: UUID ?? uuid,
        exam_year: examYear?.uuid,
        school,
      },
      signal: controller.signal,
    })
      .then((res) => {
        setStats(res);
      })
      .finally(() => setLoading(false));

    return () => {
      controller.abort();
    };
  }, [UUID, examYear?.uuid, school, uuid]);

  return (
    <div className='relative mx-auto flex h-full flex-col items-center overflow-y-auto border-t bg-slate-200 p-8 sm:p-12 md:p-16 lg:p-24'>
      <div className='relative flex min-w-full max-w-2xl flex-col gap-4 lg:min-w-[42rem] '>
        <div
          className={`flex flex-col gap-2 rounded-lg border-t-[0.6rem]
                  border-t-violet-700 bg-white p-6`}
        >
          <div
            className='text-3xl'
            dangerouslySetInnerHTML={{
              __html: form?.name,
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: form?.descritption,
            }}
          />
          <Select
            loading={schoolLoading}
            className='w-64'
            label={'Школа'}
            options={schools.map((sc) => ({ value: sc.uuid, label: sc.name }))}
            value={school}
            isClearable
            onChange={(v) => setSchool(v?.value)}
          />
        </div>
        <ContentList
          loading={!form || !stats || loading}
          data={{ contents, stats, school, examYear }}
        />
      </div>
    </div>
  );
}
