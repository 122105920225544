import React, { useCallback, useEffect, useState } from 'react';
// import PlacedholderCard from '../../../components/cards/PlaceholderCard';
import { getForms } from '../../../services/forms/formsService';
import Add from './Add';
import FormCard from './FormCard';

const useForms = () => {
  const [forms, setForms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchForms = useCallback(() => {
    setIsLoading(true);
    getForms({})
      .then((res) => setForms(res?.results))
      .catch((err) => console.error('Ошибка при получении формы:', err))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    fetchForms();
  }, [fetchForms]);

  return {
    forms,
    fetchForms,
    isLoading,
  };
};

const Forms = () => {
  const { forms, fetchForms, isLoading } = useForms();

  return (
    <div className='flex flex-col gap-8 rounded-md border bg-white p-6'>
      <h2 className='text-lg font-semibold'>Forms</h2>
      <div className='flex flex-row flex-wrap gap-4'>
        {isLoading ? (
          1
        ) : (
          //   <PlacedholderCard />
          <>
            <Add />
            {forms.map((form) => (
              <FormCard key={form?.uuid} form={form} refreshData={fetchForms} />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Forms;
