import React, { useCallback, useContext, useEffect, useState } from 'react';
import { getClassrooms } from '../../../services/application/classroomService';
import { ExamYearContext } from '../../../utils/contexts/ExamYearContext';
import { getExamDates } from '../../../services/core/coreService';
import ClassroomList from './ClassroomList';
import { getDistricts } from '../../../services/catalog/catalog';
import Add from './CRUD/Add';
import Select from '../../../components/forms/Select/Select';

const PAGE_SIZE = 30;

export default function Classrooms() {
  const { examYear } = useContext(ExamYearContext);
  const [classrooms, setClassrooms] = useState([]);
  const [examTimes, setExamTimes] = useState([]);
  const [examTime, setExamTime] = useState();
  const [examCenters, setExamCenters] = useState([]);
  const [examCenter, setExamCenter] = useState();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);
  const [ordering, setOrdering] = useState('exam_time,classroom_id');

  const getClassroomsData = useCallback(() => {
    if (!examYear?.uuid) return;
    setLoading(true);
    getClassrooms({
      page,
      page_size: PAGE_SIZE,
      year: examYear?.uuid,
      exam_time: examTime,
      exam_center: examCenter,
      ordering,
    })
      .then((res) => {
        setPageCount(Math.ceil(res.count / PAGE_SIZE));
        setCount(res.count);
        setClassrooms(res.results);
      })
      ?.finally(() => setLoading(false));
  }, [examYear?.uuid, examTime, page, examCenter, ordering]);

  useEffect(() => {
    setPage(1);
    getExamDates(examYear?.uuid).then((res) => {
      setExamTimes(res.results);
      setExamTime();
    });
  }, [examYear?.uuid]);

  useEffect(() => {
    getClassroomsData();
  }, [examTime, getClassroomsData]);

  useEffect(() => {
    getDistricts({ is_exam_center: true })?.then((res) => {
      setExamCenters(res.results);
    });
  }, []);

  return (
    <div className='flex flex-col'>
      На этой странице вы можете заполнить аудитории и указать количество мест в
      экзаменационных центрах. Для начала нажмите кнопку «+», выберите
      экзаменационный центр, время экзамена и укажите номер аудитории. Далее в
      таблице вы можете указать количество мест в этой аудитории на выбранное
      вами время. Учтите, что уменьшать количество мест в аудитории нельзя —
      можно только добавлять новые. <br />
      <br />
      Бұл бетте сіз емтихан орталықтарындағы аудиториялар мен орын санын толтыра
      аласыз. Алдымен «+» батырмасын басып, емтихан орталығын, емтихан уақытын
      таңдап, аудитория нөмірін енгізіңіз. Кейін кестеде осы аудиториядағы орын
      санын таңдаған уақытыңызға сәйкес көрсете аласыз. Назар аударыңыз:
      аудиториядағы орын санын азайтуға болмайды, тек жаңа орындар қосуға
      болады.
      <div className='flex flex-row justify-end'>
        <Add
          examCenters={examCenters}
          examTimes={examTimes}
          onCreate={getClassroomsData}
        />
      </div>
      <div className='flex flex-row gap-2'>
        <Select
          placeholder={'Выбрать время экзамена'}
          className='w-64 cursor-pointer'
          value={examTime}
          isClearable
          onChange={(v) => {
            setExamTime(v?.value);
            setPage(1);
          }}
          options={examTimes.map((s) => ({
            value: s.uuid,
            label: s.name,
          }))}
        />
        <Select
          placeholder={'Выбрать экзам центр'}
          className='w-64 cursor-pointer'
          value={examCenter}
          isClearable
          onChange={(v) => {
            setExamCenter(v?.value);
            setPage(1);
          }}
          options={examCenters.map((s) => ({
            value: s.uuid,
            label: s.name,
          }))}
        />
      </div>
      <ClassroomList
        className='z-0'
        getData={getClassroomsData}
        loading={loading}
        data={classrooms}
        page={page}
        pageSize={PAGE_SIZE}
        count={count}
        nextPage={() => setPage((prev) => prev + 1)}
        previousPage={() => setPage((prev) => prev - 1)}
        gotoPage={(p) => setPage(p)}
        pageCount={pageCount}
        ordering={ordering}
        setOrdering={setOrdering}
      />
    </div>
  );
}
