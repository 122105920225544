import { XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { patchFormContent } from '../../../../../../../../services/forms/formContentService';

export default function OtherOption({ contentUUID, setAllowOther, selected }) {
  const [t] = useTranslation();

  return (
    <>
      <div className='ml-2 flex w-full flex-row items-center py-2'>
        <span className='w-full hover:border-b'>{t('buttons.other')}</span>
        {selected && (
          <XMarkIcon
            title={'Remove'} // to translate
            className='h-5 cursor-pointer'
            onClick={() => {
              patchFormContent(contentUUID, { allow_other: false })?.then(
                (res) => {
                  setAllowOther(res.allow_other);
                },
              );
            }}
          />
        )}
      </div>
    </>
  );
}
