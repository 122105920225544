import React, { useCallback, useRef, useState } from 'react';
import { EllipsisVerticalIcon, TrashIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import { Button } from '../../../../../../components/forms/Buttons/Button';
import {
  deleteFormContent,
  patchFormContent,
} from '../../../../../../services/forms/formContentService';
import SimpleLoader from '../../../../../../components/Loader/SimpleLoader';
import { Switch } from '@headlessui/react';

export default function ContentFooter({
  content,
  selected,
  setContents,
  setPoints,
}) {
  const pointRefTimer = useRef();
  const [point, setPoint] = useState(content.points);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isRequired, setIsRequired] = useState(content.is_required);

  const handleDelete = () => {
    setIsDeleting(true);

    toast.dark(
      ({ closeToast }) => (
        <div>
          <div>You sure want to delete content "{content.name}"?</div>
          {/*to translate*/}
          <div className='mt-3 flex justify-center gap-3'>
            <Button
              text={'Yes'} // to translate
              color='error-solid'
              autoFocus
              onClick={() => {
                deleteFormContent(content.uuid)
                  ?.then(() => {
                    setPoints((prev) => prev - point);
                    setContents((prev) =>
                      prev.filter((e) => e.uuid !== content.uuid),
                    );
                  })
                  ?.catch((err) => {
                    console.log(err);
                    toast.error(
                      `Error when deleting "${content.name}"`, // to translate
                    );
                  })
                  ?.finally(() => {
                    closeToast();
                    setIsDeleting(false);
                  });
              }}
            />
            <Button
              text={'No'} // to translate
              className='text-white '
              onClick={() => {
                closeToast();
                setIsDeleting(false);
              }}
            />
          </div>
        </div>
      ),
      {
        autoClose: false,
        closeButton: true,
      },
    );
  };

  const saveContent = useCallback(
    (value, field, onFail) => {
      patchFormContent(content.uuid, { [field]: value })?.catch(() => {
        if (onFail) onFail();
      });
    },
    [content.uuid],
  );

  const debounceSaveData = useCallback(
    (value, field) => {
      if (pointRefTimer.current) {
        clearTimeout(pointRefTimer.current);
      }

      pointRefTimer.current = setTimeout(() => {
        saveContent(value, field);
        setPoints((prev) => prev - point + +value);
        setPoint(value);
        console.log(value);
      }, 1000);
    },
    [point, saveContent, setPoints],
  );

  return (
    <>
      <div
        className={`${
          selected ? 'flex' : 'hidden'
        } flex-row items-center justify-end gap-1`}
      >
        {/* <DocumentDuplicateIcon
                  className='p-2.5 h-11 cursor-pointer hover:bg-slate-100 rounded-full'
                  title={'Dublicate'} // to translate
                  onClick={() => {}}
                /> */}
        {isDeleting ? (
          <div className='relative '>
            <SimpleLoader className='h-10 p-2.5' />
          </div>
        ) : (
          <TrashIcon
            className='h-10 cursor-pointer rounded-full p-2.5 hover:bg-slate-100'
            title={'Delete'} // to translate
            onClick={handleDelete}
          />
        )}
        <div className='h-8 border-l-2' />
        <div className='mx-2 flex flex-row gap-2'>
          <span className='font-bold'>Required</span>

          <Switch
            checked={isRequired}
            className={`${
              isRequired ? 'bg-blue-600' : 'bg-gray-200'
            } relative inline-flex h-6 w-11 items-center rounded-full`}
            onChange={(value) => {
              setIsRequired(value);
              saveContent(value, 'is_required', () => setIsRequired(!value));
            }}
          >
            <span
              className={`${
                isRequired ? 'translate-x-6' : 'translate-x-1'
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
        </div>
        <EllipsisVerticalIcon className='h-10 cursor-pointer rounded-full p-2.5 hover:bg-slate-100' />
        {content?.test_type !== 'CONTENT' && (
          <>
            <input
              className='w-10 border-b border-transparent border-b-slate-200 text-center 
              focus:border-transparent focus:border-b-slate-200 focus:ring-0'
              type='number'
              min='0'
              defaultValue={content.points ?? 0}
              onChange={(e) => {
                debounceSaveData(e?.target?.value, 'points');
              }}
            />
            points {/*to translate*/}
          </>
        )}
      </div>
    </>
  );
}
