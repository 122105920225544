import React, { useState } from 'react';
import { Button } from '../../../components/forms/Buttons/Button';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import CustomDialog from '../../../components/modals/CustomDialog/CustomDialog';
import { useForm } from 'react-hook-form';
import { patchSchoolGoal } from '../../../services/core/schoolGoalsService';
import Input from '../../../components/forms/Input/Input';
import { toast } from 'react-toastify';

export default function Update({ data, getData }) {
  const [open, setOpen] = useState(false);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  const onClose = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = (body) => {
    patchSchoolGoal(data.uuid, body)
      .then(() => {
        toast.success('Updated');
        onClose();
        getData();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error);
      })
      ?.finally(() => {});
  };

  return (
    <>
      <div className='flex flex-row items-center gap-4'>
        <Button Icon={PencilSquareIcon} onClick={() => setOpen(true)} />
      </div>
      <CustomDialog
        isOpen={open}
        headerText={`${data.school?.name} - ${data.exam_year?.year}`}
        closeModal={() => {
          onClose();
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='flex min-h-[12rem] flex-col justify-between gap-2'
        >
          <div className='flex flex-col gap-2'>
            <Input
              label='Цель'
              required
              errorMsg={errors.goal?.message}
              type='number'
              {...register('goal', {
                value: data?.goal,
                required: 'Goal is required',
                min: {
                  value: 0,
                  message: 'Мин. 0',
                },
              })}
            />
          </div>
          <div className='flex flex-row justify-end'>
            <Button text='Изменить' type='submit' color='success' />
          </div>
        </form>
      </CustomDialog>
    </>
  );
}
