import bilAPI from '../api';

export const getSchoolGoals = ({
  page,
  page_size,
  exam_year,
  //   ordering,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/core/school_goals/', {
          params: {
            page,
            page_size,
            exam_year,
            // ordering,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getSchoolGoalDetails = ({ exam_year, school }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/core/school_goals/get_details/', {
          params: {
            exam_year,
            school,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const postSchoolGoal = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/core/school_goals/', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const patchSchoolGoal = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/core/school_goals/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
