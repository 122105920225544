import React, { useCallback, useRef, useState } from 'react';
import { postAnswerFormQuestion } from '../../../../../services/forms/formContentService';
import { useTranslation } from 'react-i18next';

export default function IntegerAnswer({ content, session, onAnswer }) {
  const [value, setValue] = useState(content?.answer?.answer ?? '');
  const refTimer = useRef();
  const focusIndicatorRef = useRef();
  const [t] = useTranslation();

  const saveForm = useCallback(
    ({ value }) => {
      let body = { answer: value, session };

      postAnswerFormQuestion(content.uuid, body)?.then(() => {
        if (onAnswer) onAnswer(value);
      });
    },
    [content.uuid, session],
  );

  const debounceSaveData = useCallback(
    (value) => {
      // Отправить запрос через 1 секунду после последнего ввода
      if (refTimer.current) {
        clearTimeout(refTimer.current);
      }

      refTimer.current = setTimeout(() => {
        saveForm({ value });
        console.log(value);
      }, 1000);
    },
    [saveForm],
  );

  const handleFocus = () => {
    if (focusIndicatorRef.current) {
      focusIndicatorRef.current.style.width = '100%';
    }
  };

  const handleBlur = () => {
    if (focusIndicatorRef.current) {
      focusIndicatorRef.current.style.width = '0%';
    }
  };

  return (
    <div className='flex flex-col'>
      <input
        className='h-auto overflow-hidden border-transparent border-b-slate-200 
        focus:border-transparent focus:border-b-slate-200 focus:ring-0'
        placeholder={t('results.your_answer')} // to translate
        onChange={(e) => {
          setValue(e.target.value);
          debounceSaveData(e.target.value);
        }}
        type='number'
        value={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <div className='flex flex-row justify-center text-transparent'>
        <div
          ref={focusIndicatorRef}
          className='w-0 border-b border-current text-violet-700 transition-[width] delay-200'
        />
      </div>
    </div>
  );
}
