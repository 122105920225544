import React, { useState } from 'react';
import { Button } from '../../../../components/forms/Buttons/Button';
import CustomDialog from '../../../../components/modals/CustomDialog/CustomDialog';
import { useForm } from 'react-hook-form';
import Input from '../../../../components/forms/Input/Input';
import { setClassroomSeats } from '../../../../services/application/classroomService';
import { toast } from 'react-toastify';
import { PencilSquareIcon } from '@heroicons/react/24/outline';

export default function Edit({ classroom, onEdit }) {
  const [open, setOpen] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setClassroomSeats(classroom?.uuid, data?.seats)
      .then((res) => {
        toast.info(res.data);
        if (onEdit) onEdit();
        onClose();
      })
      .catch((e) => {
        if (e?.response?.data?.error) {
          toast.error(e.response.data.error);
        }
      });
  };

  const onClose = () => {
    setOpen(false);
    reset();
  };

  return (
    <>
      <Button Icon={PencilSquareIcon} onClick={() => setOpen(true)} />
      <CustomDialog
        isOpen={open}
        setIsOpen={setOpen}
        closeModal={() => {
          onClose();
        }}
        headerText={'Добавить аудиторию'}
      >
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-2'>
          {/* <Controller
            control={control}
            name={`exam_center`}
            defaultValue={classroom?.exam_center?.uuid}
            rules={{ required: 'Поля "Экзам центр" обязательна' }}
            render={({ field }) => (
              <Select
                isDisabled
                value={classroom?.exam_center?.uuid}
                label={'Экзам центр'}
                error={errors?.exam_center?.message}
                required
                options={[
                  {
                    label: classroom?.exam_center?.name,
                    value: classroom?.exam_center?.uuid,
                  },
                ]}
                onChange={(v) => field.onChange(v.value)}
                // options={examCenters.map((s) => ({
                //   value: s.uuid,
                //   label: s.name,
                // }))}
              />
            )}
          /> */}

          <Input
            label={'Экзам центр'}
            value={classroom?.exam_center?.name}
            disabled
          />
          <Input
            label={'Время экзамена'}
            value={classroom?.exam_time?.name}
            disabled
          />
          <Input
            label={'Номер аудитории'}
            // {...register('classroom_id', {
            //   required: 'Поля "Номер аудитории" обязательна',
            //   value: classroom?.classroom_id,
            // })}
            // error={errors?.classroom_id?.message}
            value={classroom?.classroom_id}
            disabled
            type='number'
          />
          <Input
            label={'Количество мест'}
            {...register('seats', {
              required: 'Поля "Количество мест" обязательна',
              value: classroom?.seats_count,
            })}
            min={0}
            type='number'
            error={errors?.classroom_id?.message}
          />

          <div className='flex flex-row justify-end'>
            <Button text='Создать' color='success' type='submit' />
          </div>
        </form>
      </CustomDialog>
    </>
  );
}
