import React from 'react';

import withLoading from '../../../utils/hocs/withLoading';
import Edit from './CRUD/Edit';
import TableWithServerOrdering from '../../../components/Table/TableWithServerOrdering';

export default withLoading(function ClassroomList({
  className = '',
  data,
  count,
  page,
  pageSize,
  nextPage,
  previousPage,
  gotoPage,
  pageCount,
  examTimes,
  examCenters,
  getData,
  ordering,
  setOrdering,
}) {
  const columns = React.useMemo(
    () => [
      {
        Header: '#',
        id: 'index',
        Cell: (props) => {
          return (
            <span className='text-center'>
              {/* {props.row.index + 1} */}
              {props.row.index + 1 + (page - 1) * pageSize}
            </span>
          );
        },
      },
      {
        Header: 'Экзам центр',
        accessor: 'exam_center.name',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Unique iins`,
      },
      {
        Header: 'Время экзамена',
        accessor: 'exam_time.name',
        accessor_for_ordering: 'exam_time',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Unique iins`,
      },
      {
        Header: 'Аудитория',
        accessor: 'classroom_id',
        aggregate: 'uniqueCount',
        accessor_for_ordering: 'classroom_id',
        Aggregated: ({ value }) => `${value} Unique iins`,
      },
      {
        Header: 'количество мест',
        accessor: 'seats_count',
        accessor_for_ordering: 'seats_count',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Unique names`,
      },
      {
        Header: 'количество занятых мест',
        accessor: 'occupied_seats_count',
        accessor_for_ordering: 'occupied_seats_count',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Unique surnames`,
      },
      {
        Header: 'количество свободных мест',
        accessor: 'available_seats_count',
        accessor_for_ordering: 'available_seats_count',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Unique surnames`,
      },

      {
        Header: '',
        accessor: 'actions',
        Cell: (props) => (
          <div className='px-2'>
            <Edit
              onEdit={() => {
                if (getData) getData();
              }}
              classroom={props.row.original}
              examCenters={examCenters}
              examTimes={examTimes}
            />
          </div>
        ),
      },
    ],
    [examCenters, examTimes, getData, page, pageSize],
  );

  return (
    <div className={className}>
      <TableWithServerOrdering
        columns={columns}
        data={data}
        count={count}
        pageNumber={page}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
        pageCount={pageCount}
        ordering={ordering}
        handleOrdering={(newOrdering) => {
          setOrdering((prevOrdering) => {
            let orderArray = prevOrdering ? prevOrdering.split(',') : [];

            const existingIndex = orderArray.findIndex(
              (order) => order.replace('-', '') === newOrdering,
            );

            if (existingIndex !== -1) {
              if (orderArray[existingIndex].startsWith('-')) {
                orderArray.splice(existingIndex, 1);
              } else {
                orderArray[existingIndex] = `-${newOrdering}`;
              }
            } else {
              orderArray.push(newOrdering);
            }

            return orderArray.join(',');
          });
        }}
        //    loading={loading}
        isSticky
      />
    </div>
  );
});
