import React from 'react';

const CheckboxIcon = ({ className }) => {
  return (
    <svg
      width='18'
      height='29'
      viewBox='0 0 29 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      // viewBox='0 0 320 320'
      // width='24'
      // height='24'
      strokeWidth={2}
      stroke='currentColor'
      className={`${className ?? ' h-6 w-6 text-primary'}`}
      //   viewBox='0 0 300 300'
      //   fill='none'
      //   xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='1.25'
        y='1.25'
        width='25.5645'
        height='26.5'
        rx='1.75'
        stroke='currentColor'
        strokeLinejoin='round'
      />
      <path
        stroke='currentColor'
        d='M6 13.5L12 20L22 9'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default CheckboxIcon;
