import React, { useContext, useEffect, useState } from 'react';
import { ExamYearContext } from '../../utils/contexts/ExamYearContext';
import { getDistricts, getSchools } from '../../services/catalog/catalog';
import { getExamDates } from '../../services/core/coreService';
import { getSchoolClassroomStats } from '../../services/schoolStatistics/schoolStatistics';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import Table from '../../components/Table/Table';
import Select from '../../components/forms/Select/Select';
import { UserContext } from '../../utils/contexts/UserContext';
import { getClassrooms } from '../../services/application/classroomService';
import TableWithServerOrdering from '../../components/Table/TableWithServerOrdering';

const PAGE_SIZE = 30;

export default function SchoolClassroomStatistics() {
  const { examYear } = useContext(ExamYearContext);
  const { user } = useContext(UserContext);

  const [schoolClassroomStatistics, setSchoolClassroomStatistics] = useState(
    [],
  );
  const [loading, setLoading] = useState(false);

  const [schools, setSchools] = useState([]);
  const [schoolsLoading, setSchoolsLoading] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [examCenters, setExamCenters] = useState([]);
  const [examCentersLoading, setExamCentersLoading] = useState(false);
  const [selectedExamCenter, setSelectedExamCenter] = useState(null);
  const [examDates, setExamDates] = useState([]);
  const [examDatesLoading, setExamDatesLoading] = useState(false);
  const [selectedExamDate, setSelectedExamDate] = useState(null);
  const [ordering, setOrdering] = useState('classroom_id');
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (examYear?.uuid && user?.uuid) {
      // console.log('first');
      setSchoolsLoading(true);

      getSchools({ page_size: 50, exam_year: examYear.uuid }).then((res) => {
        const options = res?.results?.map((item) => ({
          label: item?.name,
          value: item?.uuid,
        }));

        setSchools(options);

        if (user?.school?.uuid) {
          setSelectedSchool(user?.school?.uuid);
        } else {
          setSelectedSchool(options[0].value);
        }

        setExamCentersLoading(true);
        getDistricts({
          school: user?.school?.uuid ? user?.school?.uuid : options[0].value,
          exam_year: examYear.uuid,
        })
          .then((res) => {
            const centers = res.results
              .filter((dist) => dist?.is_exam_center)
              .map((item) => ({
                label: item.name,
                value: item.uuid,
              }));
            setExamCenters(centers);
            setSelectedExamCenter(centers[0]?.value);
          })
          .finally(() => {
            setSchoolsLoading(false);
            setExamCentersLoading(false);
          });
      });

      setExamDatesLoading(true);
      getExamDates(examYear.uuid)
        .then((res) => {
          var options = res?.results?.map((date) => ({
            label: date.name,
            value: date.uuid,
          }));
          setExamDates(options);
          setSelectedExamDate(options[0].value);
        })
        .finally(() => {
          setExamDatesLoading(false);
        });
    }

    return () => {
      setSelectedSchool(null);
      setSelectedExamCenter(null);
      setSelectedExamDate(null);
    };
  }, [examYear, user]);

  useEffect(() => {
    if (selectedSchool && selectedExamCenter && selectedExamDate) {
      setLoading(true);
      // getSchoolClassroomStats
      getClassrooms({
        page,
        page_size: PAGE_SIZE,
        // year: examYear?.uuid,
        exam_center: selectedExamCenter,
        exam_time: selectedExamDate,
        ordering: ordering,
        school: selectedSchool,
      })
        .then((res) => {
          setPageCount(Math.ceil(res.count / PAGE_SIZE));
          setCount(res.count);
          setSchoolClassroomStatistics(res?.results);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    return () => {
      setSchoolClassroomStatistics([]);
    };
  }, [selectedSchool, selectedExamCenter, selectedExamDate, ordering, page]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Номер кабинета',
        accessor: 'classroom_id',
        accessor_for_ordering: 'classroom_id',
      },
      {
        Header: 'Количество людей',
        accessor: 'occupied_seats_count',
        accessor_for_ordering: 'occupied_seats_count',
        // width: 160,
        // Cell: (props) => <span className='w-40'>{props.value}</span>,
      },
    ],
    [],
  );

  if (loading) {
    return <SimpleLoader className='h-16' />;
  }

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-row gap-2'>
        <Select
          isSearchable={false}
          loading={schoolsLoading}
          className={`h-6 w-80`}
          placeholder={'Школа'}
          value={selectedSchool}
          options={schools}
          isDisabled={user?.roles?.some((r) => r?.code === 'VP')}
          onChange={(e) => {
            setSelectedSchool(e.value);
            setSelectedExamCenter(null);
            setExamCentersLoading(true);
            getDistricts({ school: e.value, exam_year: examYear?.uuid })
              .then((res) => {
                const centers = res.results
                  .filter((dist) => dist?.is_exam_center)
                  .map((item) => ({
                    label: item.name,
                    value: item.uuid,
                  }));
                setExamCenters(centers);
                setSelectedExamCenter(centers[0].value);
              })
              .finally(() => {
                setExamCentersLoading(false);
              });
          }}
        />
        <Select
          isSearchable={false}
          loading={examCentersLoading}
          className={`h-6 w-60`}
          placeholder={'Экзам центр'}
          value={selectedExamCenter}
          options={examCenters}
          onChange={(e) => {
            setSelectedExamCenter(e.value);
          }}
        />
        <Select
          isSearchable={false}
          loading={examDatesLoading}
          className={`h-6 w-60`}
          placeholder={'Дата экзам'}
          value={selectedExamDate}
          options={examDates}
          onChange={(e) => {
            setSelectedExamDate(e.value);
          }}
        />
      </div>
      <TableWithServerOrdering
        columns={columns}
        data={schoolClassroomStatistics}
        count={count}
        pageNumber={page}
        pageCount={pageCount}
        nextPage={() => setPage((prev) => prev + 1)}
        previousPage={() => setPage((prev) => prev - 1)}
        gotoPage={(p) => setPage(p)}
        ordering={ordering}
        handleOrdering={(newOrdering) => {
          setOrdering((prevOrdering) => {
            let orderArray = prevOrdering ? prevOrdering.split(',') : [];

            const existingIndex = orderArray.findIndex(
              (order) => order.replace('-', '') === newOrdering,
            );

            if (existingIndex !== -1) {
              if (orderArray[existingIndex].startsWith('-')) {
                orderArray.splice(existingIndex, 1);
              } else {
                orderArray[existingIndex] = `-${newOrdering}`;
              }
            } else {
              orderArray.push(newOrdering);
            }

            return orderArray.join(',');
          });
        }}
      />
    </div>
  );
}
