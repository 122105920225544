import React, { useContext, useEffect, useState } from 'react';
import { Button } from '../../components/forms/Buttons/Button';
import { getSettings } from '../../services/settings/settingsService';
import { UserContext } from '../../utils/contexts/UserContext';
import CustomDialog from '../../components/modals/CustomDialog/CustomDialog';
import {
  CloudArrowUpIcon,
  EyeIcon,
  EyeSlashIcon,
} from '@heroicons/react/24/outline';
import { useForm } from 'react-hook-form';
import Input from '../../components/forms/Input/Input';
import { useTranslation } from 'react-i18next';
import useSnackbar from '../../utils/hooks/useSnackbar';
import { postTransferStudentsToBilOrg } from '../../services/transferStudents/transferStudentsToBilOrgService';

const ExportToBilOrgModal = () => {
  const { user } = useContext(UserContext);
  const [t] = useTranslation();
  const { addSnackbar } = useSnackbar();
  const [isAllowed, setIsAllowed] = useState(null);
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    postTransferStudentsToBilOrg(data)
      .then((res) => {
        addSnackbar('Ученики экспортированы в bil.org.kz', 'success');
        onClose();
      })
      .catch((err) => {
        console.log(1);
        addSnackbar('Ошибка', 'failure');
      });
    return;
  };

  const onClose = () => {
    setOpen(false);
    reset();
    setShowPassword(false);
  };

  useEffect(() => {
    getSettings({
      params: {
        allowed_schools: user.school.uuid,
        code: 'EXPORT_STD_BIL_ORG',
      },
    }).then((res) => {
      setIsAllowed(!!res.length);
    });
  }, [user.school.uuid]);
  return (
    <>
      {isAllowed && (
        <Button
          color='success'
          text='Экспорт в bil.org.kz'
          onClick={() => setOpen(true)}
          Icon={CloudArrowUpIcon}
        />
      )}
      <CustomDialog
        isOpen={open}
        headerText={'Экспорт'}
        closeModal={() => {
          onClose();
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='flex min-h-[12rem] flex-col gap-2'
        >
          <p>
            {
              'Введите данные аутентификации администарции вашей школы в bil.org.kz'
            }
          </p>
          <Input
            className='mr-6 max-w-[20rem]'
            required
            label={t('welcome.username')}
            {...register('username', {
              required: 'Username is required field',
            })}
          />
          {errors?.username && (
            <p className='text-error'>{errors.username.message}</p>
          )}

          <div className='flex flex-row'>
            <Input
              className='w-[20rem]'
              required
              label={t('welcome.password')}
              type={showPassword ? 'text' : 'password'}
              {...register('password', {
                required: 'Password is required field',
              })}
            />
            <div className='-translate-x-8 translate-y-7'>
              {showPassword ? (
                <EyeSlashIcon
                  className='h-6 cursor-pointer'
                  onClick={() => setShowPassword(false)}
                />
              ) : (
                <EyeIcon
                  className='h-6 cursor-pointer'
                  onClick={() => setShowPassword(true)}
                />
              )}
            </div>
          </div>
          {errors?.password && (
            <p className='text-error'>{errors.password.message}</p>
          )}

          <p>{'Все принятые ученики будут экспортированы в класс 7 "A"'}</p>
          <div className='flex flex-row justify-end'>
            <Button
              text='Экспортировать в bil.org.kz'
              type='submit'
              color='success'
            />
          </div>
        </form>
      </CustomDialog>
    </>
  );
};

export default ExportToBilOrgModal;
