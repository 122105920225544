const refreshTokenKey = 'refreshTkn';
const accessTokenKey = 'accessTkn';

const getLocalRefreshToken = () => {
  const refreshToken = localStorage.getItem(refreshTokenKey);
  return refreshToken;
};

const getLocalAccessToken = () => {
  const accessToken = localStorage.getItem(accessTokenKey);
  return accessToken;
};

const updateLocalAccessToken = (token) => {
  localStorage.setItem(accessTokenKey, token);
};

const updateRefreshAccessToken = (token) => {
  localStorage.setItem(refreshTokenKey, token);
};

const removeTokens = () => {
  localStorage.removeItem(refreshTokenKey);
  localStorage.removeItem(accessTokenKey);
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  updateRefreshAccessToken,
  removeTokens,
};

export default TokenService;
