import React, { useEffect, useState } from 'react';
import { Button } from '../../../components/forms/Buttons/Button';
import { CheckIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import CustomDialog from '../../../components/modals/CustomDialog/CustomDialog';
import { Controller, useForm } from 'react-hook-form';
import Select from '../../../components/forms/Select/Select';
import useSnackbar from '../../../utils/hooks/useSnackbar';
import { patchSchool } from '../../../services/catalog/schools';

const SchoolYearsEditModal = ({ school, years, getData }) => {
  const { addSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    patchSchool(school.uuid, data)
      .then(() => {
        addSnackbar('Updated', 'success');
      })
      .catch((error) => {
        addSnackbar(error, 'error');
      })
      ?.finally(() => {
        getData();
      });
  };

  return (
    <>
      <div className='flex flex-row items-center gap-4'>
        {/* <span>{setting.allowed_schools.length}</span> */}
        <Button Icon={PencilSquareIcon} onClick={() => setOpen(true)} />
      </div>
      <CustomDialog
        isOpen={open}
        headerText={school.name}
        closeModal={() => {
          setOpen(false);
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='flex min-h-[12rem] flex-col justify-between gap-2'
        >
          <div className='flex flex-col gap-2'>
            <label>
              <span>Школы</span>
              <span className='text-error'>*</span>
            </label>
            <Controller
              control={control}
              name={`years`}
              defaultValue={school.years}
              render={({ field }) => (
                <div className='flex flex-col items-end justify-center gap-2'>
                  <Select
                    className='w-full'
                    isMulti
                    isSearchable={false}
                    value={field.value}
                    options={years.map((school) => ({
                      value: school.uuid,
                      label: school.year,
                    }))}
                    onChange={(e) => {
                      field.onChange(
                        e?.map((p) => {
                          return p?.value;
                        }),
                      );
                    }}
                  />
                  <Button
                    className='w-60'
                    text={'Выбрать всех'}
                    Icon={CheckIcon}
                    type='button'
                    onClick={() => {
                      field.onChange(
                        years?.map((p) => {
                          return p?.uuid;
                        }),
                      );
                    }}
                  />
                </div>
              )}
            />
          </div>
          <div className='flex flex-row justify-end'>
            <Button text='Изменить' type='submit' color='success' />
          </div>
        </form>
      </CustomDialog>
    </>
  );
};

export default SchoolYearsEditModal;
