import React, { useState } from 'react';
import { postAnswerFormQuestion } from '../../../../../services/forms/formContentService';
import Select from '../../../../../components/forms/Select/Select';

export default function Dropdown({ content, session, onAnswer }) {
  const [fields, setFields] = useState(content?.questionselectcontent);
  const [selected, setSelected] = useState(content?.answer?.choices[0]);

  return (
    <form className='flex flex-col gap-4'>
      <Select
        options={fields?.map((field) => ({
          label: field?.content,
          value: field.uuid,
        }))}
        value={selected}
        onChange={(v) => {
          setSelected(v.value);
          postAnswerFormQuestion(content.uuid, {
            choices: [v.value],
            session,
          })?.then(() => {
            if (onAnswer) onAnswer([v.value]);

            // const newFields = fields.map((f) => {
            //   if (field.uuid !== f.uuid) {
            //     f.is_correct = false;
            //     return f;
            //   } else {
            //     f.is_correct = !field.is_correct;
            //     return f;
            //   }
            // });
            // setFields(newFields);
          });
        }}
      />
    </form>
  );
}
