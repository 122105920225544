// Types of subtexts: list-decimal
export const KAZ_RULES = [
  // 1
  {
    title: '1. Жалпы ережелер',
    list: [
      {
        text: 'Осы «Білім-инновация» лицейлеріне оқуға қабылдау ережелері (бұдан әрі – Ережелер) 2007 жылғы 27 шілдедегі Қазақстан Республикасының «Білім туралы» Заңына, «Мектепке дейінгі, бастауыш, негізгі орта, жалпы орта, техникалық және кәсіптік, орта білімнен кейінгі білім беру, мамандандырылған, арнаулы білім, жетім балалар мен ата-анасының қамқорлығынсыз қалған балаларға арналған білім беру ұйымдарында, балаларға және ересектерге қосымша білім беру ұйымдарының үлгілік қағидаларын бекіту туралы» ҚР Оқу-ағарту министрінің 31.08.2022 ж. №385 бұйрығына, «Бастауыш, негізгі орта және жалпы орта білімнің жалпы білім беретін оқу бағдарламаларын іске асыратын білім беру ұйымдарына оқуға қабылдаудың үлгілік қағидаларын бекіту туралы» ҚР БҒМ 12.10.2018 ж. №564 бұйрығына сәйкес әзірленді.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: '«Білім-инновация» лицейлеріне (бұдан әрі – БИЛ) оқуға қабылдау  конкурс негізінде (бұдан әрі – конкурс) жүзеге асады.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Конкурсқа Қазақстан Республикасының жалпы білім беретін мектептерінің 6-сынып оқушылары (бұдан әрі – үміткерлер) қатыса алады.',
        subtext: {
          type: null,
          content: [],
        },
      },
    ],
  },
  // 2
  {
    title: '2. Құжаттарды қабылдау тәртібі',
    list: [
      {
        text: 'Конкурсты ұйымдастыру және өткізу үшін «БІЛІМ-ИННОВАЦИЯ» Халықаралық қоғамдық қоры (бұдан әрі – Қор) президентінің бұйрығымен құрамы 5 (бес) адамнан тұратын қор басшылығы, әдіскерлері мен мамандарынан конкурстық комиссия құрылады.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Конкурсқа қатысу үшін құжаттарды қабылдау, тестілеуді өткізу үшін облыстарда және Астана, Алматы, Шымкент қалаларында БИЛ директорының/міндетін атқарушының бұйрығымен құрамы 5 (бес) адамнан тұратын БИЛ педагогтары мен қызметкерлерінен қабылдау комиссия құрылады.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'БИЛ директорының/міндетін атқарушының бұйрығымен құжаттарды қабылдау үшін жауапты тұлға тағайындалады.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'БИЛ-ға түсуге үміткердің ата-анасынан немесе өзге де заңды өкілінен конкурсқа қатысу үшін құжаттарды қабылдау ағымдағы күнтізбелік жылдың 1 ақпан мен 12 сәуір аралығында жүргізіледі.',
        subtext: {
          type: null,
          content: [
            '7.1 Конкурсқа қатысу үшін үміткердің ата-анасы немесе өзге де заңды өкілі белгіленген мерзімде www.bilemtihan.kz сілтемесі арқылы тіркеуден өтеді.',
          ],
        },
      },
      {
        text: 'Конкурсқа қатысу үшін төмендегі құжаттардың электрондық нұсқалары (жүктеу үшін файл форматы: .jpg, .jpeg, .pdf) қажет:',
        subtext: {
          type: null,
          content: [
            '1) ЖСН көрсетілген үміткердің туу туралы куәлігінің көшірмесі;',
            '2) үміткердің 3х4 көлеміндегі фотосуреті;',
            '3) халықтың әлеуметтік осал топтарына жататындығын растайтын құжаттары (болған жағдайда).',
            '8.1 Құжаттардың түпнұсқаларын тапсыру талап етілмейді (8-тармақ, 3-тармақшадан басқасы, бұл тармақшада көрсетілген құжаттың түпнұсқасы www.bilemtihan.kz сайтында тіркелгеннен кейін 5 жұмыс күні ішінде БИЛ-дің қабылдау комиссиясына тапсырылады).',
            '8.2 Құжаттарды қабылдау аяқталғаннан кейін қабылдау комиссиясы ұсынылған құжаттардың іріктеу өлшемшарттарға сәйкестігін тексереді және растайтын құжаттардың түпнұсқаларын қосымша сұратады (қажет болса).',
            '8.3 Құжаттарды тексеру аяқталған соң үміткерлердің ата-анасының немесе өзге де заңды өкілдерінің тіркеуде көрсетілген телефон нөміріне немесе электрондық поштаға рұқсатнама жіберіледі.',
            '8.4 Үміткер тестілеуге келген кезде жеке басын сәйкестендіру үшін өзімен бірге туу туралы куәлігін (түпнұсқа) және рұқсатнаманы қағаз түрінде ұсынады.',
          ],
        },
      },
      {
        text: 'Үміткер тұрғылықты жерінен конкурсқа қатысуға тіркелу үшін, облыс бойынша бірнеше тестілеу орталығы болған жағдайда, өзіне ыңғайлы емтихан тапсыру орнын таңдайды, рұқсат қағаз берілген кезде таңдалған тест орталығының  мекенжайы көрсетіледі.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Конкурсқа қатысу туралы өтінішті белгіленген мерзімнен кеш беру немесе құжаттар топтамасын толық ұсынбау құжаттарды қабылдаудан бас тарту үшін негіз болады.',
        subtext: {
          type: null,
          content: [],
        },
      },
    ],
  },
  // 3
  {
    title: '3. Конкурсты өткізу тәртібі',
    list: [
      {
        text: 'БИЛ-ға іріктеу жүргізу үшін конкурстық материалдарды Қордың оқу-әдістемелік кеңесі әзірлейді және бекітеді.',
        subtext: {
          type: null,
          content: [
            '11.1 Барлық қағаз және электрондық емтихан материалдары авторлық құқықпен қорғалып, тек конкурс кезіндегі емтиханда ғана пайдаланылады.',
            '11.2 Конкурсты өткізу үшін тест материалдарын Қор қызметкерлері БИЛ-ға конкурс басталғанға дейін қағаз түрінде (пломбаланған) жеткізеді.',
          ],
        },
      },
      {
        text: 'Конкурс әрбір өңірде алдын ала айқындалған білім беру ұйымының базасында өтеді. Үміткерлер арасында конкурс және қорытынды шығару әрбір БИЛ бөлінісінде жүргізіледі.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Конкурс ағымдағы жылғы мамыр айының екінші жексенбісінде өтеді',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Конкурстық іріктеу бір турда өткізіледі. Конкурсқа қатысушылар саны екі ағымға бөлінеді. Тест тапсыру уақыты, ағым нөмірі рұқсат қағазында көрсетіледі.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Конкурс күндізгі форматта (тестілеу түрінде) өтеді. Тест тапсырмалары қағаз түрінде пайдаланылады.',
        subtext: {
          type: null,
          content: [
            '15.1 Оқуға түсушілерге арналған тестілеу мынадай пәндер бойынша жалпы 60 сұрақтан тұрады: математика және логика – 50 сұрақ; оқу сауаттылығы  –  10 сұрақ.',
            '15.2 Тестілеуге бөлінген уақыт - 110 минут;',
            '15.3 Тест материалдарын тарату, жауап парағының бөлімдерін толтыру, сондай-ақ, түсіндіру жұмыстарының уақыты көрсетілген тестілеу уақытына кірмейді;',
            '15.4 Үміткерлер жауап парақтарының қызметтік секторларын және кітапшаның мұқабасын толтырған соң тақтада тестілеудің басталу және аяқталу уақыты жазылады. Тестілеу уақытына тест сұрақтарына жауап беру, жауап дөңгелекшелерін бояу кіреді.',
            '15.5 Үміткерге сұрақ кітапшасы мен жауап парағы беріледі. Үміткер берілген тапсырмаларды сұрақ кітапшасында орындайды, жауаптарды жауап парағына белгілейді. Есептерді шығару үшін қосымша парақ берілмейді;',
            '15.6 Жауап парағы көк немесе қара түсті қаламмен толтырылады.',
          ],
        },
      },
      {
        text: 'Тестілеудің қорытынды нәтижелерін есептеу кезінде дұрыс жауаптар саны «4» (төрт) коэффициентіне көбейтіледі, ал бір дұрыс емес жауап «-1» (минус бір) коэффициентіне көбейтіледі. Осылайша, жалпы қорытынды есептеледі (4 х дұрыс жауап + (-1) х дұрыс емес жауап = жалпы қорытынды балл).',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Тестілеу аяқталған соң облыстық және Астана, Алматы, Шымкент қалаларының қабылдау комиссиялары жауап парақтарын нәтижелерді өңдеу үшін Қорға жібереді.',
        subtext: {
          type: null,
          content: [
            '17.1 Нәтижелерді өңдеуді конкурстық комиссия жауап парақтарын тексеру арқылы жүзеге асырады, сұрақ-кітапшасы қарастырылмайды.',
            '17.2 Конкурстық іріктеу қорытындылары тестілеу аяқталған соң 7 (жеті) күнтізбелік күннен кейін www.bilemtihan.kz сайтында жарияланады.',
          ],
        },
      },
    ],
  },
  // 4
  {
    title: '4. Конкурс шарттары',
    list: [
      {
        text: 'Үміткерлер мен қызметкерлердің өмірі мен денсаулығын қорғау, қауіпсіздігіне жағдай жасау жауапкершілігі БИЛ директорына немесе директордың міндетін атқарушыға жүктеледі.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Қабылдау емтиханын өткізу кезінде тестілеу аймағында бөгде адамдардың болуына жол берілмейді.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Үміткер тестілеуге өзімен бірге көк немесе қара түсті қалам әкеледі.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Үміткер тестілеу басталған уақыттан бастап 30 (отыз) минутқа кешіккен жағдайда емтиханға кіргізілмейді.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Тестілеу барысында үміткерге төмендегілер рұқсат етілмейді:',
        subtext: {
          type: 'list-disc',
          content: [
            'аудиторияға шпаргалка, жазбалар, рефераттар, конспектілерді немесе кез келген басқа материалдарды әкелуге және пайдалануға;',
            'ұялы телефон, смарт-сағат, планшет немесе басқа да ақпараттарды тасымалдау функциясымен жабдықталған кез келген байланыс құралдарын пайдалануға;',
            'емтихан кезінде басқа үміткерлермен сөйлесуге;',
            'әдепсіз сөздер айтуға, этикалық нормаларды бұзуға, басқа үміткерлерге немесе оқытушыларға құрметсіздік танытуға;',
            'тест материалдарымен алмасу, көшіру сияқты алдау немесе алаяқтыққа бағытталған кез-келген әрекеттер жасауға;',
            'аудитория бақылаушысының рұқсатынсыз орын ауыстыруға;',
            'аудитория бақылаушысының рұқсатынсыз сыртқа шығуға;',
            'тестілеу материалдарын (жауап парағы мен кітапшалар) умаждауға, беттерін жыртуға, аудиториядан шығаруға, корректор сұйықтығын қолдануға.',
          ],
        },
      },
    ],
  },
  // 5
  {
    title: '5. Конкурс нәтижелерін қайта қарау тәртібі',
    list: [
      {
        text: 'Қабылдау емтихандар кезінде бірыңғай талаптардың сақталуын қамтамасыз ету және даулы мәселелерді шешу мақсатында мектепте төрағадан және кем дегенде екі мүшесінен тұратын Апелляциялық комиссия құрылады.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Апелляциялық комиссия құрамы БИЛ директорының бұйрығымен бекітіледі.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Апелляцияға өтініш үміткердің ата-анасы немесе өзге де заңды өкілдері конкурстық іріктеудің қорытындылары жарияланған соң келесі күні сағат 10.00-ден 17.00–ге дейін онлайн түрде бере алады.',
        subtext: {
          type: null,
          content: [
            'Онлайн өтініш сілтемесі лицейдің қабылдау комиссиясының телефон нөміріне хабарласу арқылы алынады.',
          ],
        },
      },
      {
        text: 'Апелляциялық комиссия өтінішті апелляцияға берген күннен кейін үш жұмыс күні ішінде қарастырып, шешімін шығарады.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Апелляциялық комиссияға төмендегі сұрақтар бойынша өтініштер қабылданбайды:',
        subtext: {
          type: null,
          content: [
            '1) тест тапсырмаларының мазмұны мен құрылымы бойынша;',
            '2) тест тапсырмаларын орындау кезінде үміткерден талап етілген жалпыға бірдей міндетті тәртіп ережелерін бұзған жағдайда;',
            '3) үміткер жауап парағын дұрыс толтырмаған жағдайда;',
            '4) әрбір тапсырма бойынша дәлелді негіздеме көрсетілмеген барлық тест тапсырмаларын қайта қарау мақсатында.',
          ],
        },
      },
      {
        text: 'Апелляциялық комиссия конкурс нәтижесін өзгеріссіз қалдыру немесе өзгерту туралы шешім қабылдай алады.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Аппеляциялық комиссия үміткерге не себепті ұпай қосылғандығы немесе қосылмағандығы туралы конкурстық комиссияға ақпарат береді.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Апелляция қорытындысы туралы үміткердің ата-анасына немесе басқа да заңды өкіліне хабарланады.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Емтиханды қайта тапсыруға рұқсат етілмейді.',
        subtext: {
          type: null,
          content: [],
        },
      },
    ],
  },
  // 6
  {
    title: '6. Оқушыны БИЛ-ге қабылдау тәртібі',
    list: [
      {
        text: 'БИЛ-ға қабылдау үшін, Қазақстан Республикасы Білім және ғылым министрінің 12.10.2018ж. № 564 бұйрығымен бекітілген «Білім беру ұйымдарына оқуға қабылдаудың үлгілік қағидаларының» 25 тармағына сәйкес,  төмендегі квоталар бекітіледі:',
        subtext: {
          type: null,
          content: [
            '1) «Мың бала» ауыл мектептерінің ұлттық зияткерлік олимпиада жеңімпаздары үшін 7-сыныпқа қабылданатын білім алушылардың жалпы санынан 10% мөлшерінде;',
            '2) халықтың әлеуметтік осал топтары үшін 7-сыныпқа қабылданатын білім алушылардың жалпы санының 15% мөлшерінде.',
            'Халықтың әлеуметтік осал топтарына, Қазақстан Республикасы Білім және ғылым министрінің 12.10.2018ж. № 564 бұйрығымен бекітілген «Білім беру ұйымдарына оқуға қабылдаудың үлгілік қағидаларының» 18-1 тармағына сәйкес:',
            '· мемлекеттік атаулы әлеуметтік көмек алатын ауылдық жерден шыққан отбасының балалары;',
            '· ата-аналарының біреуі бірінші топтағы мүгедектігі бар отбасының балалары;',
            '· мүгедек баласы бар немесе оны тәрбиелеп отырған отбасылар;',
            '· жетім балалар мен ата-анасының қамқорлығынсыз қалған балалар;',
            '· отбасыларда тұратын жетiм балалар, ата-анасының қамқорлығынсыз қалған балалар;',
            '· төтенше жағдайлар салдарынан шұғыл көмекке мұқтаж отбасылардан шыққан балалар;',
            '· аналары «Алтын алқа», «Күміс алқа» алқаларымен наградталған немесе бұрын «Батыр ана» атағын алған, сондай-ақ І және ІІ дәрежелі «Ана даңқы» ордендерімен наградталған көп балалы отбасылардан шыққан балалар;',
            '· экологиялық зілзалалар, табиғи және техногендік сипаттағы төтенше жағдай салдарынан тұрғын үйінен айырылған отбасыларының;',
            '· мемлекеттік немесе қоғамдық міндеттерді, әскери қызметті атқарған кезінде, ғарыш кеңістігіне ұшуға дайындалу немесе жүзеге асыру кезінде қаза тапқан (қайтыс болған) адамдардың отбасыларының балалары жатады.',
          ],
        },
      },
      {
        text: 'БИЛ-ға оқуға қабылдау конкурс нәтижесі бойынша жүзеге асырылады.',
        subtext: {
          type: null,
          content: [
            '33.1. Үміткерлерді, оның ішінде осы Ережелердің 32-тармағымының 2) тармақшасында көрсетілгендерді оқуға қабылдау, әрбір лицейдің бөлінісінде бос орындарға сәйкес балдардың ең көп санына қарай жүзеге асырылады.',
            '33.2 Аралас (қазақ және орыс) тілінде оқытатын БИЛ-ге үміткерлерді оқыту тілі бойынша оқуға қабылдау әрбір тілдік топ бөлінісінде жүзеге асырылады.',
            '33.3 Әр оқу жылының қазақ және орыс тілінде оқытатын сыныптарының қатынасы әр БИЛ-дің сыйымдылығына және мүмкіндігіне байланысты уәкілетті білім басқармасымен келісіп белгелегеді.',
          ],
        },
      },
      {
        text: 'Алынған балл сандары бірдей болған жағдайда, басымдық деңгейі бойынша мынадай көрсеткіштер бағаланатын болады: ',
        subtext: {
          type: null,
          content: [
            '1) математика және логика бойынша ең көп ұпай жинағандар;',
            '2) тестілеу кезінде ең аз қате жібергендер;',
            '3) ауыл мектептерінің білім алушылары.',
          ],
        },
      },
      {
        text: 'Үміткерлерді конкурстық іріктеу қорытындылары конкурстық комиссиясының хаттамасымен ресімделеді және www.bilemtihan.kz. сайтында орналастырылады.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Конкурстық іріктеу қорытындылары www.bilemtihan.kz сайтында үміткердің дербес деректерін, олардың таралуын болдырмау мақсатында,  шифрлау арқылы орналастырыларды.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'www.bilemtihan.kz сайтында орналасқан конкурстық іріктеу қорытындыларын тек конкурстық іріктеуге тіркелген үміткерлер үшін жеке кабинетте көруге болады.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Конкурс қорытындылары бойынша БИЛ-ға оқуға қабылданған үміткер, ағымдағы жылдың 12 маусымына дейін облыстық және Астана, Алматы, Шымкент қалаларының қабылдау комиссиясына оқуға құжаттарды ұсынылған тізім бойынша тапсырады.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Резервтік тізімге конкурс іріктеуінің нәтижесі бойынша негізгі бос орындарға кірмеген үміткерлер арасынан жинаған баллдарының қосындысы бойынша кему тәртібінде www.bilemtihan.kz сайтында орналастырылған барлық оқушылар кіреді.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Егер, БИЛ-ға оқуға қабылданған үміткер осы Ережелердің 38- тармағында көрсетілген мерзімде құжаттарын тапсырмаса, оның орнына резерв тізімінен үміткер шақырылады.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'БИЛ-ға қабылданған үміткер оқудан бас тартқан жағдайда, оның орнына резерв тізімінен үміткер шақырылады. ',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Резервтік тізімге енгізілген үміткерлер бос орын болған жағдайда оқу жылының ішінде қабылдана алады.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Лицейлер арасында 7-сынып оқушыларын ауыстыру қарастырылмайды.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: '8-11 сыныптарда орын босаған жағдайда «Білім-инновация» лицейлері  мен Павлодар облысы әкімдігі, Павлодар облысы білім беру басқармасының «Облыстық көп салалы дарынды балаларға арналған көптілді лицей» коммуналдық мемлекеттік мекемесі арасында өзара оқушы қабылдана алады.',
        subtext: {
          type: null,
          content: [],
        },
      },
    ],
  },
  // 7
  {
    title: '7. Қорытынды ереже',
    list: [
      {
        text: 'Осы Ережелермен реттелмеген мәселелер Қазақстан Республикасының қолданыстағы заңнамасына, ішкі құқықтық актілеріне және БИЛ Жарғысына сәйкес шешіледі.  ',
        subtext: {
          type: null,
          content: [],
        },
      },
    ],
  },
];

export const RUS_RULES = [
  // 1
  {
    title: '1. Общие положения',
    list: [
      {
        text: 'Настоящие правила по приему на обучение в лицеи  «Білім-инновация» (далее – Правила)  разработаны в соответствии с  Законом Республики Казахстан «Об образовании» от 27 июля 2007 года, «Типовыми правилами деятельности организаций дошкольного, среднего, технического и профессионального, послесреднего образования, дополнительного образования соответствующих типов и видов», утвержденными приказом Министра просвещения Республики Казахстан от 31 августа 2022 года № 385, «Типовыми правилами приема на обучение в организации образования, реализующие общеобразовательные учебные программы начального, основного среднего, общего среднего образования», утвержденными приказом Министра образования и науки Республики Казахстан от 12 октября 2018 года №564.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Прием на обучение в лицеи «Білім-инновация» (далее – БИЛ) производится на конкурсной основе (далее – конкурс).',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'В конкурсе принимают участие обучающиеся (далее – претенденты) 6 классов общеобразовательных школ Республики Казахстан.',
        subtext: {
          type: null,
          content: [],
        },
      },
    ],
  },
  // 2
  {
    title: '2. Организация приема документов',
    list: [
      {
        text: 'Для организации и проведения конкурса по приказу президента Международного общественного фонда «БІЛІМ-ИННОВАЦИЯ» (далее – Фонд) создается конкурсная комиссия, состоящая из 5 (пяти) человек – руководства, методистов и специалистов Фонда.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Для приема документов на участие в конкурсе, проведения тестирования в областях и городах Астана, Алматы и Шымкент создается приемная комиссия в составе 5 (пяти) человек из числа  педагогов и специалистов БИЛ и утверждается приказом директора БИЛ или лицом, исполняющим обязанности директора.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Приказом директора БИЛ или лицом, исполняющим обязанности директора, назначается ответственное лицо для приема документов.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Прием документов для участия в конкурсе от родителей или иных законных представителей ребенка, поступающего в БИЛ, производится с 1 февраля по 12 апреля текущего календарного года.',
        subtext: {
          type: null,
          content: [
            '7.1 Для участия в конкурсе родитель или иной законный представитель претендента в установленные сроки проходит онлайн регистрацию на сайте www.bilemtihan.kz.',
          ],
        },
      },
      {
        text: 'При регистрации необходимы электронные версии (формат файла для загрузки: jpg, jpeg, pdf) следующих документов:',
        subtext: {
          type: null,
          content: [
            '1) свидетельство о рождении претендента с указанием ИИН;',
            '2) фотография претендента размером 3х4;',
            '3) документы, подтверждающие принадлежность к социально уязвимым категориям населения (при наличии).',
            '8.1 Оригиналы документов не требуются (за исключением документа, указанного в подпункте 3 пункта 8, который подается в оригинале в приемную комиссию БИЛ в течение 5 рабочих дней после регистрации на сайте www.bilemtihan.kz).',
            '8.2 После завершения приема документов приемная комиссия проверяет предоставленные документы на соответствие критериям отбора и запрашивает оригиналы подтверждающих документов (при необходимости).',
            '8.3 При завершении проверки документов родителям/законным представителям претендента на указанный номер телефона или адрес электронной почты отправляется пропуск.',
            '8.4 На тестирование претендент приносит с собой свидетельство о рождении (оригинал) и пропуск в бумажном варианте для идентификации личности.',
          ],
        },
      },
      {
        text: 'Для регистрации на участие в конкурсе по месту жительства, при наличии нескольких центров тестирования в области, претендент указывает наиболее удобное для него место сдачи экзамена, при выдаче пропуска будет указан адрес выбранного центра тестирования.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Основаниями для отказа в приеме документов являются подача заявления об участии в Конкурсе позже установленных сроков или неполный пакет документов.',
        subtext: {
          type: null,
          content: [],
        },
      },
    ],
  },
  // 3
  {
    title: '3. Порядок проведения конкурса',
    list: [
      {
        text: 'Конкурсные материалы для проведения отбора в БИЛ разрабатываются и утверждаются учебно-методическим советом Фонда.',
        subtext: {
          type: null,
          content: [
            '11.1 Все бумажные и электронные экзаменационные материалы защищены авторским правом и используются только на экзамене.',
            '11.2 Тестовые материалы для проведения конкурса доставляются в БИЛ в бумажном варианте, (запломбированные) сотрудниками Фонда до начала проведения конкурса.',
          ],
        },
      },
      {
        text: 'Конкурс проходит в каждом регионе на базе заранее определенной  организации образования, конкурс и подведение итогов проводится среди претендентов на зачисление в лицей в разрезе каждого БИЛ.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Конкурс проводится во второе воскресенье мая текущего года.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Конкурсный отбор проводится в один тур. Количество участников конкурса делится на два потока. Время сдачи, номер потока указываются в пропуске.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Конкурс проходит в очном формате (в форме тестирования).',
        subtext: {
          type: null,
          content: [
            '15.1 Тестирование для поступающих включает всего 60 вопросов по предметам:\n математика и логика – 50 вопросов;\nграмотность чтения  – 10 вопросов.',
            '15.2 Время, отведенное на тестирование, составляет 110 минут;',
            '15.3 Время на раздачу тестовых материалов, заполнение служебных секторов листа ответов, а также разъяснение правил поведения претендента в аудитории в указанное время тестирования не включается;',
            '15.4 После заполнения претендентами служебных секторов листов ответов и титульного листа книжки, на доске записывается время начала и окончания тестирования. Время тестирования включает ответы на тестовые вопросы, закрашивание кружков ответа.',
            '15.5 Претенденту выдаются книжка-вопросник и лист ответов. Все необходимые вычисления претендент выполняет в книжке-вопроснике, выбранный вариант ответа заносит в лист ответов. Дополнительные листы для вычислений не выдаются;',
            '15.6 Лист ответов заполняется ручкой с синей или черной пастой.',
          ],
        },
      },
      {
        text: 'При вычислении итоговых результатов теста количество правильных ответов умножается на коэффициент «4» (четыре), тогда как один неправильный ответ умножается на коэффициент «-1» (минус один). Таким образом, высчитывается общий итог (4 x правильные ответы + (-1) x неправильные ответы = общий итоговый балл).',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'По окончании тестирования приемные комиссии БИЛ областей и городов Астаны, Алматы и Шымкент направляют листы ответов в Фонд для проверки результатов тестирования.',
        subtext: {
          type: null,
          content: [
            '17.1 Проверка результатов тестирования проводится конкурсной комиссией, результаты считываются с листа ответов претендента, книжка-вопросник не рассматривается.',
            '17.2 Итоги конкурсного отбора претендентов размещаются на сайте www.bilemtihan.kz по истечении 7 (семи) календарных дней.',
          ],
        },
      },
    ],
  },
  // 4
  {
    title: '4. Условия конкурса',
    list: [
      {
        text: 'Ответственность за охрану жизни и здоровья, создание безопасных условий претендентов и сотрудников возлагается на директора БИЛ или лицо, исполняющее обязанности директора.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Во время проведения тестирования не допускается нахождение посторонних лиц в зоне проведения экзаменов.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Для прохождения тестирования претенденту необходимо принести с собой ручку с синей или черной пастой.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'При опоздании на тестирование на 30 (тридцать) и более минут претендент к экзамену не допускается.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Во время тестирования запрещается:',
        subtext: {
          type: 'list-disc',
          content: [
            'вносить в аудиторию и использовать шпаргалки, записи, конспекты или любые другие материалы;',
            'использовать мобильные телефоны, смарт-часы, планшеты или любые другие электронные устройства связи, оснащенные функциями переноса информации;',
            'переговариваться с другими претендентами во время экзамена;',
            'использовать нецензурные выражения, нарушать этические нормы, проявлять неуважение к другим претендентам или преподавателям;',
            'совершать любые действия, направленные на обман или мошенничество, такие как обмен материалами тестирования, списывание и т.д.;',
            'пересаживаться с места на место без предварительного разрешения дежурного по аудитории;',
            'выходить из аудитории без разрешения дежурного по аудитории;',
            'пачкать, мять, рвать, выносить из аудитории экзаменационные материалы (лист ответов, книжка-вопросник) и пользоваться корректирующими жидкостями.',
          ],
        },
      },
    ],
  },
  // 5
  {
    title: '5. Порядок обжалования результатов конкурса',
    list: [
      {
        text: 'Для обеспечения соблюдения общих требований и разрешения спорных вопросов во время тестирования создается Апелляционная комиссия при школе, состоящая из председателя и не менее двух членов комиссии.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Состав Апелляционной комиссии утверждается приказом директора БИЛ.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Заявление на апелляцию подается родителями или иными законными представителями претендента онлайн на следующий день после объявления результатов тестирования с 10.00 до 17.00. часов.',
        subtext: {
          type: null,
          content: [
            '25.1 Доступ к ссылке на онлайн-заявление запрашивается по номеру телефона приемной комиссии БИЛ.',
          ],
        },
      },
      {
        text: 'Апелляционная комиссия рассматривает заявление и объявляет результаты решения в течение трех рабочих дней после подачи заявления.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Апелляционной комиссией не принимаются заявления по вопросам:',
        subtext: {
          type: null,
          content: [
            '1) содержания и структуры тестовых заданий;',
            '2) связанным с нарушением претендентом установленных правил поведения во время выполнения тестовых заданий;',
            '3) неправильного заполнения претендентом листа ответов;',
            '4) пересмотра всех тестовых заданий без указания мотивированного обоснования по каждому заданию.',
          ],
        },
      },
      {
        text: 'Апелляционная комиссия может принимать решение об оставлении результатов оценок конкурсных заданий без изменений, либо об их изменении.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Апелляционная комиссия информирует конкурсную комиссию в связи с чем были добавлены, либо не добавлены баллы претенденту.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Об итогах апелляции сообщается родителям или иным законным представителям претендента.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Пересдача экзамена не допускается.',
        subtext: {
          type: null,
          content: [],
        },
      },
    ],
  },
  // 6
  {
    title: '6. Порядок зачисления обучающихся в БИЛ',
    list: [
      {
        text: 'Для зачисления в БИЛ утверждаются квоты, соответствующие условиям пункта 25 «Типовых правил приема на обучение в организации образования, утвержденных приказом Министра образования и науки Республики Казахстан» от 12.10.2018 года № 564:',
        subtext: {
          type: null,
          content: [
            '1) в размере 10% от общего количества обучающихся, принимаемых в 7 классы, для победителей национальной интеллектуальной олимпиады из сельских школ «Mың бала»;',
            '2) в размере 15% от общего количества обучающихся, принимаемых в 7 классы, для социально уязвимых категорий населения.',
            'К социально уязвимым категориям населения, из числа которых будут отобраны 15 % обучающихся от общего количества принимаемых, согласно пункту 18-1 «Типовых правил приема на обучение в организации образования, утвержденных приказом Министра образования и науки Республики Казахстан» от 12.10.2018 года № 564 относятся:',
            '· дети из семей из сельской местности, получающих государственную адресную социальную помощь;',
            '· дети, где один из родителей имеет инвалидность первой группы;',
            '· семьям, имеющим или воспитывающим ребенка c инвалидностью;',
            '· дети-сироты и дети, оставшиеся без попечения родителей;',
            '· дети-сироты, дети, оставшиеся без попечения родителей, проживающие в семьях;',
            '· дети из семей, требующих экстренной помощи в результате чрезвычайных ситуаций;',
            '· дети из многодетных семей, матери которых награждены подвесками «Алтын алқа», «Күміс алқа» или получившие ранее звание «Мать-героиня», а также награжденные орденами «Материнская слава» I и II степени;',
            '· семьям, лишившимся жилища в результате экологических бедствий, чрезвычайных ситуаций природного и техногенного характера;',
            '· семьям лиц, погибших (умерших) при исполнении государственных или общественных обязанностей, воинской службы, при подготовке или осуществлении полета в космическое пространство.',
          ],
        },
      },
      {
        text: 'Зачисление на обучение в БИЛ осуществляется по результатам участия в конкурсе.',
        subtext: {
          type: null,
          content: [
            '33.1. Зачисление претендентов, в том числе указанных в подпункте 2) пункта 32 настоящих Правил, осуществляется от  максимального количества баллов в соответствии с вакантными местами  в разрезе каждого БИЛ.',
            '33.2. Зачисление претендентов на обучение в БИЛ со смешанными (казахским и русским) языками обучения проводится отдельно по каждой языковой группе.',
            '33.3. Соотношение классов с казахским и русским языками обучения в каждом учебном году определяется по согласованию с уполномоченным отделом образования в зависимости от вместимости и возможностей каждого БИЛ.',
          ],
        },
      },
      {
        text: 'При одинаковом количестве набранных баллов будут оцениваться следующие показатели по уровню приоритетности:',
        subtext: {
          type: null,
          content: [
            '1) набравшие наибольшее количество баллов по математике и логике;',
            '2) допустившие наименьшее количество ошибок при тестировании;',
            '3) обучающиеся из сельских школ.',
          ],
        },
      },
      {
        text: 'Итоги конкурсного отбора претендентов оформляются протоколом конкурсной комиссии и размещаются на сайте www.bilemtihan.kz.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Размещение результатов отбора кандидатов осуществляется на сайте www.bilemtihan.kz с шифрованием персональных данных претендента в целях предотвращения их распространения.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Итоги конкурсного отбора претендентов, размещенные на сайте www.bilemtihan.kz, доступны для просмотра в личном кабинете только для претендентов, прошедших регистрацию на конкурсный отбор.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Претенденты, по итогам конкурса зачисленные на обучение в БИЛ, до 12 июня текущего года предоставляют документы в соответствии с перечнем в приемную комиссию БИЛ областей и городов Астаны, Алматы и Шымкент.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'В резервный список входят все ученики из числа претендентов конкурса, не вошедших в основные вакансии, по сумме набранных баллов в порядке убывания по итогам конкурсного отбора, размещенным на сайте www.bilemtihan.kz.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'В случае, если претендент, зачисленный на обучение в БИЛ, не предоставил документы в срок, установленный в п.38 настоящих  Правил, на его место приглашается претендент  из резервного списка.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Если претендент, зачисленный в БИЛ, отказывается от обучения, на его место приглашается следующий претендент из резервного списка.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'Претенденты, зачисленные в резервный список, могут быть приняты в БИЛ в течение учебного года при наличии вакантных мест.',
        subtext: { type: null, content: [] },
      },
      {
        text: 'Перевод обучающихся 7 классов между лицеями  не предусмотрен.',
        subtext: {
          type: null,
          content: [],
        },
      },
      {
        text: 'При освобождении мест в 8-11 классах возможен перевод обучающихся между «Білім-инновация лицеями и КГУ «Областной многопрофильный полиязычный лицей для одаренных детей» управления образования Павлодарской области, акимата Павлодарской области.',
        subtext: {
          type: null,
          content: [],
        },
      },
    ],
  },
  // 7
  {
    title: '7. Заключительные положения',
    list: [
      {
        text: 'Вопросы, не урегулированные настоящими Правилами, решаются в соответствии с действующим законодательством Республики Казахстан, утвержденными внутренними правовыми актами и Уставом БИЛ.',
        subtext: {
          type: null,
          content: [],
        },
      },
    ],
  },
];

export function PassRules() {
  return (
    <div className='flex flex-row justify-evenly px-4 pb-4'>
      <div>
        <ol className='pr-4'>
          <li>
            <span className=' font-semibold'>
              1. Емтиханға өзіңізбен бірге не әкелу керек?
            </span>
            <ul className='list-inside indent-4'>
              <li>i. Туу туралы куәлік (түпнұсқа)</li>
              <li>ii. Рұқсатнама (қағаз түрінде)</li>
              <li>iii. Қара/көк сиялы қалам</li>
            </ul>
          </li>
          <li>
            <span className=' font-semibold'>
              2. Емтиханға уақытында келу керек. Кешіккен жағдайда, емтиханға
              жіберілмеуіңіз мүмкін.
            </span>
          </li>
          <li>
            <span className=' font-semibold'>
              3. Емтихан кезінде бақылаушылардың нұсқауларын орындау қажет.
            </span>
          </li>
          <li>
            <span className=' font-semibold'>
              4. Емтихан кезінде төмендегілерге тыйым салынады:
            </span>

            <ul className='list-inside indent-4'>
              <li>&bull; алдауға немесе көшіруге;</li>
              <li>
                &bull; шпаргалка, дайындық материалдарын, гаджет немесе ұялы
                телефонды қолдануға.
              </li>
            </ul>
          </li>
        </ol>
      </div>
      <div className='border-l-2 pl-4'>
        <ol className=''>
          <li>
            <span className=' font-semibold'>
              1. Что принести с собой на экзамен?
            </span>
            <ul className='list-inside indent-4'>
              <li>i. Свидетельство о рождении (оригинал)</li>
              <li>ii. Пропуск (бумажный вариант)</li>
              <li>iii. Ручка с черной/синей пастой</li>
            </ul>
          </li>
          <li>
            <span className=' font-semibold'>
              2. Вы должны приходить на экзамен вовремя. Если вы опоздаете, вас
              могут не допустить к экзамену.
            </span>
          </li>
          <li>
            <span className=' font-semibold'>
              3. Во время экзамена нужно следовать указаниям наблюдателей.
            </span>
          </li>
          <li>
            <span className=' font-semibold'>4. Во время экзамена нельзя:</span>

            <ul className='list-inside indent-4'>
              <li>&bull; обманывать или списывать;</li>
              <li>
                &bull; использовать шпаргалки, вспомогательные материалы,
                гаджеты или мобильные телефоны.
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </div>
  );
}
