import React, { useCallback, useRef, useState } from 'react';
import { patchFormContentQuestion } from '../../../../../../../services/forms/formContentQuestionService';
import DragableOptions from './OptionLayouts/DragableOptions';
import OptionBlank from './OptionLayouts/OptionBlank';
import { handleAddOption } from './OptionUtils';
import AddOption from './OptionLayouts/AddOption';

export default function DropdownOption({ content, selected, update }) {
  const [fields, setFields] = useState(content?.questionselectcontent);
  const inputRefs = useRef({});
  const focusRefs = useRef({});

  const saveForm = useCallback(
    ({ value, field, uuid }) => {
      let body = {};
      body[field] = value;
      patchFormContentQuestion(uuid, body)?.then(() => {
        setFields(
          fields?.map((f) => {
            if (f.uuid === uuid) {
              f[field] = value;
            }
            return f;
          }),
        );
      });
    },
    [fields],
  );

  const debounceSaveData = useCallback(
    (value, ref, field, uuid) => {
      // Отправить запрос через 1 секунду после последнего ввода
      if (ref.current) {
        clearTimeout(ref.current);
      }

      ref.current = setTimeout(() => {
        if (!value) return;
        saveForm({ uuid, value, field });
        console.log(value);
      }, 1000);
    },
    [saveForm],
  );

  const handleFocus = (uuid) => {
    if (focusRefs.current) {
      focusRefs.current[uuid].style.width = '100%';
    }
  };

  const handleBlur = (uuid) => {
    if (focusRefs.current) {
      focusRefs.current[uuid].style.width = '0%';
    }
  };

  return (
    <div className='flex flex-col'>
      <div className='flex flex-col gap-4'>
        <DragableOptions
          fields={fields}
          setFields={setFields}
          selected={selected}
        >
          {fields?.map((field) => {
            return (
              <OptionBlank
                key={field?.uuid}
                field={field}
                fields={fields}
                setFields={setFields}
                update={update}
                selected={selected}
              >
                {field.order}.
                <div className='w-full'>
                  <input
                    ref={(el) => (inputRefs.current[field.uuid] = el)}
                    defaultValue={field?.content}
                    onBlur={(e) => {
                      handleBlur(field.uuid);
                      if (e.target.value === '') {
                        e.target.value = field?.content;
                      }
                    }}
                    onFocus={() => handleFocus(field.uuid)}
                    onChange={(e) => {
                      debounceSaveData(
                        e?.target?.value,
                        inputRefs.current[field.uuid],
                        'content',
                        field.uuid,
                      );
                    }}
                    className={`${
                      selected && 'hover:border-b'
                    } w-full focus:outline-none`}
                  />{' '}
                  <div
                    ref={(el) => (focusRefs.current[field.uuid] = el)}
                    className='w-0 place-self-center border-b border-violet-700 transition-[width] delay-200'
                  />
                </div>
              </OptionBlank>
            );
          })}
        </DragableOptions>
      </div>
      <div
        className={`ml-6 mt-1 flex flex-row items-center gap-2 ${
          !selected && 'hidden'
        }`}
      >
        {fields?.length + 1}.
        <AddOption
          contentUUID={content.uuid}
          handleAddOption={() =>
            handleAddOption({
              uuid: content.uuid,
              fields,
              setFields,
              update,
              ref: inputRefs,
            })
          }
        />
      </div>
    </div>
  );
}
