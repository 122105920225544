import React from 'react';
import { useLocation } from 'react-router-dom';

export default function SuccessMessage() {
  const location = useLocation();

  return location?.state?.name ? (
    <div className='flex w-full flex-col sm:flex-row'>
      <div className='p-8 sm:w-1/2 sm:px-16 sm:py-36'>
        {location?.state?.isVulnerable ? (
          <React.Fragment>
            <h1 className='mb-4 text-2xl font-semibold'>
              Құрметті,
              {' ' + location?.state?.name}
            </h1>
            <p>
              Тіркеуді аяқтау үшін Сізге{' '}
              <span className='bg-red-600 text-white'>5 (бес) жұмыс күні</span>{' '}
              ішінде лицейдің қабылдау комиссиясына төменде көрсетілген
              құжаттардың түпнұсқаларын ұсыну қажет:
            </p>
            <ol className='mt-2 mb-8 list-inside list-decimal'>
              {location?.state?.isVulnerable?.files?.map((file_name, i) => (
                <li key={i}>{file_name}</li>
              ))}
            </ol>
            Уважаемый (-ая),
            <p>
              Аталмыш құжаттар 5 (бес) жұмыс күні ішінде тапсырылмаған жағдайда
              Сіздің балаңыз әлеуметтік осал топтарына{' '}
              <span className='font-semibold underline'>жатпайтын</span> үміткер
              ретінде тіркеледі.
            </p>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h1 className='mb-4 text-2xl font-semibold'>
              Құрметті,
              {' ' + location?.state?.name}!
            </h1>
            <p>
              Сіздің өтініміңіз қабылданды. <br />
              Өтініміңіз өңделгеннен кейін 3 жұмыс күн ішінде Сіз көрсеткен
              WhatsApp нөміріңізге/электронды поштаңызға “Емтиханға кіру
              рұқсатнамасы” жіберіледі. Егер, көрсетілген уақыт ішінде хабарлама
              келмесе, өтінім берген лицейге хабарласуыңызды сұраймыз.
            </p>
            {/* <p>
              Егер осы уақыт ішінде рұқсатнама жіберілмесе, онда сіз нөміріңізді
              немесе электрондық поштаңызды дұрыс толтырмаған болуыңыз мүмкін.
              Бұл жағдайда өтінім берген лицейге хабарласуыңызды сұраймыз.
            </p> */}
          </React.Fragment>
        )}
      </div>
      <div className='bg-cyan-600 p-8 text-white sm:w-1/2 sm:px-16  sm:py-36'>
        {location?.state?.isVulnerable ? (
          <React.Fragment>
            <h1 className='mb-4 text-2xl font-semibold'>
              Уважаемый (-ая),
              {' ' + location?.state?.name}
            </h1>
            <p>
              Чтобы завершить регистрацию Вам необходимо в течение{' '}
              <span className='bg-red-600'>5 (пяти) рабочих дней</span>{' '}
              предоставить в приемную комиссию лицея оригиналы нижеуказанных
              документов:
            </p>
            <ol className='mt-2 mb-8 list-inside list-decimal'>
              {location?.state?.isVulnerable?.files_ru?.map((file_name, i) => (
                <li key={i}>{file_name}</li>
              ))}
            </ol>
            <p>
              В случае непредоставления данных документов в течение 5 (пяти)
              рабочих дней, Ваш ребенок будет зарегистрирован в качестве
              претендента,
              <span className='font-semibold underline'>не относящегося</span>к
              социально уязвимым слоям населения.
            </p>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h1 className='mb-4 text-2xl font-semibold'>
              Уважаемый (-ая),
              {' ' + location?.state?.name}!
            </h1>
            <p>
              Ваша заявка принята. <br />
              После обработки заявки в течение 3-х рабочих дней на указанный
              Вами номер watsapp/электронную почту придет "Допуск к экзамену". В
              случае, если уведомление не поступит в указанный срок, необходимо
              связаться с лицеем, в который подавали заявление.
            </p>
            {/* <p>
              Если же в течении этого времени вы не получите допуск, то возможно
              вы ошибочно указали ваш номер или электронную почту. В таком
              случае просим вас связаться с лицеем, в который вы подавали
              заявление.
            </p> */}
          </React.Fragment>
        )}
      </div>
    </div>
  ) : (
    <div>Ошибка</div>
  );
}
