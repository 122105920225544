import React, { useState } from 'react';
import CustomDialog from '../../components/modals/CustomDialog/CustomDialog';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '../../components/forms/Buttons/Button';
import Select from '../../components/forms/Select/Select';
import { exportClassroomPassesPDF } from '../../services/application/passesService';
import useSnackbar from '../../utils/hooks/useSnackbar';
import { DocumentArrowDownIcon } from '@heroicons/react/24/solid';
import { getClassrooms } from '../../services/application/classroomService';

export default function ExportPasses({
  modalOpen,
  setModalOpen,
  examCenters,
  examDates,
}) {
  const [loading, setLoading] = useState(false);
  const { handleSubmit, watch, control } = useForm();
  const { addSnackbar } = useSnackbar();

  const [classrooms, setClassrooms] = useState([]);
  const [classroomsLoading, setClassroomsLoading] = useState(false);

  const getClassroomsData = ({ exam_time, exam_center }) => {
    setClassroomsLoading(true);
    getClassrooms({
      page_size: 100,
      exam_time,
      exam_center,
      ordering: 'classroom_id',
    })
      .then((res) => {
        setClassrooms(
          res.results.map((cls) => ({
            label: cls.classroom_id,
            value: cls.uuid,
          })),
        );
      })
      .finally(() => {
        setClassroomsLoading(false);
      });
  };

  const onSubmit = async (data) => {
    setLoading(true);

    await exportClassroomPassesPDF(
      data.examDate,
      data.examCenter,
      data.classroom,
    )
      .then((res) => {
        window.open(res.file, '_blank');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <CustomDialog
      isOpen={modalOpen}
      setIsOpen={setModalOpen}
      closeModal={() => {
        setModalOpen(false);
      }}
      headerText={'Комментарий'}
    >
      <form className='flex flex-col gap-4' onSubmit={handleSubmit(onSubmit)}>
        <div className='flex w-full flex-col gap-4'>
          <Controller
            control={control}
            name='examDate'
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                label={'Время экзмена'}
                required
                className='col-span-6'
                value={field.value}
                options={examDates}
                onChange={(e) => {
                  field.onChange(e.value);

                  if (watch('examCenter')) {
                    getClassroomsData({
                      exam_time: e.value,
                      exam_center: watch('examCenter'),
                    });
                  }
                }}
              />
            )}
          />
          {/* {examCenters?.length > 0 && ( */}
          <Controller
            control={control}
            name='examCenter'
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                label={'Экзаменационный центр'}
                required
                className=''
                value={field.value}
                options={examCenters}
                // placeholder={t('announcement.select_positions')}
                onChange={(e) => {
                  // setSelectedDistrict(e);
                  field.onChange(e.value);
                  if (watch('examDate')) {
                    getClassroomsData({
                      exam_center: e.value,
                      exam_time: watch('examDate'),
                    });
                  } else {
                    addSnackbar('Пожалуйста выберите время экзамена', 'info');
                  }
                }}
              />
            )}
          />
          {/* )} */}
          <Controller
            control={control}
            name='classroom'
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                label={'Кабинет'}
                required
                loading={classroomsLoading}
                className=''
                value={field.value}
                options={classrooms}
                isDisabled={
                  classroomsLoading ||
                  !(watch('examDate') && watch('examCenter'))
                }
                // placeholder={t('announcement.select_positions')}
                onChange={(e) => {
                  // setSelectedDistrict(e);
                  field.onChange(e.value);
                }}
              />
            )}
          />
        </div>
        <div className='flex justify-end'>
          <Button
            loading={loading}
            type='submit'
            Icon={DocumentArrowDownIcon}
            color='success'
            text='Загрузить'
            onClick={() => {}}
          />
        </div>
      </form>
    </CustomDialog>
  );
}
