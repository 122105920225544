import React from 'react';
import CustomDialog from '../../../../components/modals/CustomDialog/CustomDialog';
import { Tab } from '@headlessui/react';
import { classNames } from '../../../../utils/helpers/helpers';
import MainInfoTab from './MainInfoTab';
import ASPTab from './ASPTab';

export default function UpdateExamInfoModal({
  student,
  modalOpen,
  setModalOpen,
  refreshData,
}) {
  return (
    <CustomDialog
      isOpen={modalOpen}
      setIsOpen={setModalOpen}
      closeModal={() => {
        setModalOpen(false);
      }}
      headerText={'Обновить данные'}
    >
      <Tab.Group>
        <Tab.List className='flex space-x-1 rounded-md bg-gray-900 p-1'>
          <Tab
            key={'main'}
            className={({ selected }) =>
              classNames(
                'w-full rounded-md py-2.5 text-sm font-medium leading-5',
                'font-semibold ring-offset-2 ring-offset-blue-400',
                selected
                  ? 'bg-white text-gray-800 shadow'
                  : 'text-white hover:bg-gray-500 hover:text-white',
              )
            }
          >
            Негізгі ақпарат
          </Tab>
          <Tab
            key={'asp'}
            className={({ selected }) =>
              classNames(
                'w-full rounded-md py-2.5 text-sm font-medium leading-5',
                'font-semibold ring-offset-2 ring-offset-blue-400',
                selected
                  ? 'bg-white text-gray-800 shadow'
                  : 'text-white hover:bg-gray-500 hover:text-white',
              )
            }
          >
            ӘОТ құжаттары
          </Tab>
        </Tab.List>
        <Tab.Panels className='mt-2 min-h-[40rem] rounded-md border'>
          <Tab.Panel
            key={1}
            className={classNames(
              'rounded-md bg-white p-2',
              'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
            )}
          >
            <MainInfoTab
              student={student}
              setModalOpen={setModalOpen}
              refreshData={refreshData}
            />
          </Tab.Panel>
          <Tab.Panel
            key={2}
            className={classNames(
              'rounded-md bg-white p-2',
              'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
            )}
          >
            <ASPTab student={student} refreshData={refreshData} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </CustomDialog>
  );
}
