import { LinkIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { Button } from '../../../components/forms/Buttons/Button';
import { getApplicantsLite } from '../../../services/application/application';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UpdateStudentPass from './UpdateStudentPass';
import ExportApplication from '../StudentInfo/ExportApplication';

export default function StudentPass() {
  const [t] = useTranslation();
  //   const { state } = useLocation();
  const { uuid } = useParams();
  // const { addSnackbar } = useSnackbar();

  const [modalOpen, setModalOpen] = useState(false);
  //   const [aspModalOpen, setAspModalOpen] = useState(false);
  const [studentInfo, setStudentInfo] = useState(null);
  // const [avatar, setAvatar] = useState(null);
  const [loadingDownload, setLoadingDownload] = useState(false);

  useEffect(() => {
    if (uuid) {
      refreshData();
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  const refreshData = () => {
    getApplicantsLite(uuid).then((res) => {
      setStudentInfo(res);
    });
  };

  return (
    <div className='md:flex md:flex-row md:justify-center'>
      <div className='max-w-7xl overflow-x-scroll'>
        <UpdateStudentPass
          student={studentInfo}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          refreshData={refreshData}
        />
        {/* <AspStatusModal
          student={studentInfo}
          aspModalOpen={aspModalOpen}
          setAspModalOpen={setAspModalOpen}
          socialDocType={state?.socialDocType}
        /> */}
        <div className='mb-4 flex flex-row justify-between gap-2'>
          <div className='flex flex-row gap-2'>
            {/* <Button
              color='primary'
              type='button'
              text='Обновить'
              onClick={() => {
                setModalOpen(true);
              }}
            /> */}
            {/* <Button
              type='button'
              text='ӘОТ статус'
              onClick={() => {
                setAspModalOpen(true);
              }}
            /> */}
          </div>
          <div className='flex flex-row gap-2'>
            {studentInfo?.asp_wa_me_link && false && (
              <Button
                color='success'
                text='ӘОТ сообщение'
                Icon={PaperAirplaneIcon}
                onClick={() => {
                  window.open(
                    studentInfo?.asp_wa_me_link,
                    '_blank',
                    'noopener,noreferrer',
                  );
                }}
              />
            )}
            {studentInfo?.t2_wa_me_link && (
              <Button
                color='success'
                text='Whatsapp'
                Icon={LinkIcon}
                onClick={() => {
                  window.open(
                    studentInfo?.t2_wa_me_link,
                    '_blank',
                    'noopener,noreferrer',
                  );
                  //   patchApplication(studentInfo?.uuid, { sent: true })
                  //     .then(() => {
                  //       addSnackbar(t('Успешно'), 'success');
                  //     })
                  //     .catch(() => {
                  //       addSnackbar(t('Ошибка'), 'error');
                  //     });
                }}
              />
            )}
          </div>
        </div>
        <div></div>
        <ExportApplication studentInfo={studentInfo} />
      </div>
    </div>
  );
}
