import { Tab } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CustomDialog from '../../components/modals/CustomDialog/CustomDialog';
import { postApplication } from '../../services/application/application';
import useSnackbar from '../../utils/hooks/useSnackbar';
import Statement from '../Landing/Statement';
import Application from './Application';
import Confirm from './Confirm';
import ContactInfo from './ContactInfo';
import DocumentUpload from './DocumentUpload';
import MainInfo from './MainInfo';
import VulnerableGroup from './VulnerableGroup';
import { getSchools } from '../../services/catalog/catalog';
import { UserContext } from '../../utils/contexts/UserContext';
// import { Button } from '../../components/forms/Buttons/Button';
import ViewForm from '../Forms/Form/View/ViewForm';
import { Button } from '../../components/forms/Buttons/Button';
import { SURVEY_UUID } from '../../utils/constants/constants';

const TAB_STYLE =
  'w-full border-b-2 p-2 ui-selected:border-b-blue-600 ui-selected:bg-blue-50 ui-selected:text-blue-600';
const COMPLETED_TAB_STYLE =
  'w-full border-b-2 border-r border-b-green-600 bg-green-50 text-green-600 p-2';

export default function Registration() {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();

  const { user } = useContext(UserContext);
  const { addSnackbar } = useSnackbar();

  const [bilSchools, setBilSchools] = useState([]);
  const [schoolsError, setSchoolsError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const [selectedBilSchool, setSelectedBilSchool] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedExamCenter, setSelectedExamCenter] = useState(null);
  const [vulnerableGroup, setVulnerableGroup] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [mainPartCompleted, setMainPartCompleted] = useState(false);
  const [contactsPartCompleted, setContactsPartCompleted] = useState(false);
  const [docsPartCompleted, setDocsPartCompleted] = useState(false);
  const [statementPartCompleted, setStatementPartCompleted] = useState(false);
  const [vulnerableGroupPartCompleted, setVulnerableGroupPartCompleted] =
    useState(false);
  const [surveyComplete, setSurveyComplete] = useState(false);
  const [body, setBody] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    gender: null,
    avatar_photo: null,
    iin: '',
    birth_date: null,
    school: null,
    district: null,
    exam_center: null,
    address: '',
    parent_first_name: '',
    parent_middle_name: '',
    parent_last_name: '',
    parent_phone_number: '',
    parent_phone_number_2: '',
    language: null,
    current_school_name: '',
    document: null,
    social_doc_type: null,
  });

  const nextPage = (step) => {
    setSelectedIndex(step);
  };

  const apply = async () => {
    setLoading(true);

    const formBody = new FormData();

    formBody.append(
      'session',
      localStorage.getItem(`form-session--${SURVEY_UUID}`),
    );

    for (var key in body) {
      if (key === 'avatar_photo') {
        formBody.append('avatar_photo', body['avatar_photo'], 'avatar_photo');
      } else if (key === 'document') {
        formBody.append('document', body['document'], 'document');
      } else {
        if (body[key]) {
          formBody.append(key, body[key]);
        }
      }
    }
    // console.log('formBody', body);
    await postApplication(formBody)
      .then(() => {
        setLoading(false);
        localStorage.removeItem(`form-session--${SURVEY_UUID}`);
        addSnackbar(t('Успешно'), 'success');

        navigate('/registration/message', {
          // navigate('/qwerty/message', {
          state: {
            name: body?.first_name + ' ' + body?.last_name,
            isVulnerable: vulnerableGroup,
          },
        });
      })
      .catch((err) => {
        console.log('err', err);
        setLoading(false);
        setSelectedIndex(0);
        addSnackbar(
          err?.response?.data?.code === 1
            ? t('signup.iin_duplicate')
            : 'Ошибка',
          'error',
        );
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (user?.uuid) {
      if (
        user?.roles?.some(
          (role) =>
            role.code === 'SA' ||
            role?.code === 'SSA' ||
            role?.code?.includes('RC'),
        )
      ) {
        navigate({
          pathname: `/school-registration-statistics`,
          state: { user: user },
        });
      }
      if (user?.roles?.some((role) => role.code === 'VP')) {
        navigate({
          pathname: `/applicants`,
          state: { user: user },
        });
      }
      if (user?.roles?.some((role) => role.code === 'STD')) {
        navigate({
          pathname: `/student/profile`,
          state: { user: user },
        });
      }
      addSnackbar('Пожалуйста, выйдите из системы', 'info');
    }

    return () => {};
  }, [user, navigate, addSnackbar]);

  useEffect(() => {
    getSchools({ page_size: 50 })
      .then((res) => {
        const options = res.results
          .filter(
            (sc) =>
              !['043', '057', '038', '151', '115'].includes(sc?.school_id),
          )
          .map((item) => ({
            label: item?.name,
            value: item?.uuid,
            school_id: item?.school_id,
            has_social: item?.has_social,
          }));
        setBilSchools(options);
      })
      .catch((err) => {
        console.log('err', err);
        setSchoolsError(err);
      });

    return () => {
      localStorage.removeItem(`form-session--${SURVEY_UUID}`);
    };
  }, []);

  return (
    <div className='mt-12 flex  justify-center bg-sheet'>
      <CustomDialog
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        closeModal={() => {
          // setModalOpen(false);
        }}
        headerText={
          <span className='font-bold'>
            {i18n?.language === 'kk'
              ? 'Үміткерді тіркеуге және қабылдау емтиханына қатыстыру жөніндегі ата-анасының немесе заңды өкілдерінің келісімі'
              : 'Согласие родителей или законных представителей на регистрацию и участие во вступительном экзамене'}
          </span>
        }
      >
        <Statement setModalOpen={setModalOpen} />
      </CustomDialog>
      <div className='mb-12 flex w-[72rem] flex-col rounded-md border'>
        {/* <div className='border-b'>
          <p className='mx-4 my-4 text-xl font-bold'>Student registration</p>
        </div> */}
        <div className='flex h-full flex-col'>
          <Tab.Group
            selectedIndex={selectedIndex}
            onChange={setSelectedIndex}
            manual
          >
            <Tab.List className='flex w-full flex-col font-semibold sm:h-20 sm:flex-row'>
              <Tab
                className={mainPartCompleted ? COMPLETED_TAB_STYLE : TAB_STYLE}
              >
                <span className='flex flex-row items-center justify-center'>
                  {t('signup.main_info')}
                  {mainPartCompleted && (
                    <CheckCircleIcon className='ml-1 h-5 w-5 min-w-fit max-w-fit' />
                  )}
                </span>
              </Tab>
              <Tab
                disabled={!mainPartCompleted}
                className={
                  contactsPartCompleted ? COMPLETED_TAB_STYLE : TAB_STYLE
                }
              >
                <span className='flex flex-row items-center justify-center'>
                  {t('signup.contact_info')}
                  {contactsPartCompleted && (
                    <CheckCircleIcon className='ml-1 h-5 w-5 min-w-fit max-w-fit' />
                  )}
                </span>
              </Tab>
              <Tab
                disabled={!contactsPartCompleted}
                className={docsPartCompleted ? COMPLETED_TAB_STYLE : TAB_STYLE}
              >
                <span className='flex flex-row items-center justify-center'>
                  {t('signup.upload_docs')}
                  {docsPartCompleted && (
                    <CheckCircleIcon className='ml-1 h-5 w-5 min-w-fit max-w-fit' />
                  )}
                </span>
              </Tab>
              <Tab
                disabled={!docsPartCompleted}
                className={
                  statementPartCompleted ? COMPLETED_TAB_STYLE : TAB_STYLE
                }
              >
                <span className='flex flex-row items-center justify-center'>
                  {t('signup.statement')}
                  {statementPartCompleted && (
                    <CheckCircleIcon className='ml-1 h-5 w-5 min-w-fit max-w-fit' />
                  )}
                </span>
              </Tab>
              <Tab
                disabled={
                  !statementPartCompleted || !selectedBilSchool?.has_social
                }
                className={
                  vulnerableGroupPartCompleted && selectedBilSchool?.has_social
                    ? COMPLETED_TAB_STYLE
                    : TAB_STYLE
                }
              >
                <span className='flex flex-row items-center justify-center'>
                  {t('signup.asp_title')}
                  {vulnerableGroupPartCompleted && (
                    <CheckCircleIcon className='ml-1 h-5 w-5 min-w-fit max-w-fit' />
                  )}
                </span>
              </Tab>
              <Tab
                disabled={!vulnerableGroupPartCompleted}
                className={surveyComplete ? COMPLETED_TAB_STYLE : TAB_STYLE}
              >
                <span className='flex flex-row items-center justify-center'>
                  {t('signup.survey')}
                  {surveyComplete && (
                    <CheckCircleIcon className='ml-1 h-5 w-5 min-w-fit max-w-fit' />
                  )}
                </span>
              </Tab>
              <Tab
                disabled={!surveyComplete}
                className='w-full border-b-2 p-2 ui-selected:border-b-blue-600 ui-selected:bg-blue-50 ui-selected:text-blue-600'
              >
                {t('signup.signup_completion')}
              </Tab>
            </Tab.List>

            <Tab.Panels className='h-auto p-6'>
              <Tab.Panel className='h-full'>
                <MainInfo
                  applicant={body}
                  setBody={setBody}
                  nextPage={nextPage}
                  setMainPartCompleted={setMainPartCompleted}
                />
              </Tab.Panel>
              <Tab.Panel className='h-full'>
                <ContactInfo
                  applicant={body}
                  setBody={setBody}
                  nextPage={nextPage}
                  setContactsPartCompleted={setContactsPartCompleted}
                  setSelectedBilSchool={setSelectedBilSchool}
                  setSelectedDistrict={setSelectedDistrict}
                  setSelectedExamCenter={setSelectedExamCenter}
                  bilSchools={bilSchools}
                  schoolsError={schoolsError}
                />
              </Tab.Panel>
              <Tab.Panel className='h-full'>
                <DocumentUpload
                  applicant={body}
                  setBody={setBody}
                  nextPage={nextPage}
                  setDocsPartCompleted={setDocsPartCompleted}
                />
              </Tab.Panel>
              <Tab.Panel className='h-full'>
                <Application
                  applicant={body}
                  nextPage={nextPage}
                  setStatementPartCompleted={setStatementPartCompleted}
                  selectedBilSchool={selectedBilSchool}
                  setVulnerableGroupPartCompleted={
                    setVulnerableGroupPartCompleted
                  }
                />
              </Tab.Panel>
              <Tab.Panel className='h-full'>
                <VulnerableGroup
                  applicant={body}
                  setBody={setBody}
                  nextPage={nextPage}
                  setVulnerableGroupPartCompleted={
                    setVulnerableGroupPartCompleted
                  }
                  setVulnerableGroup={setVulnerableGroup}
                />
              </Tab.Panel>
              <Tab.Panel className='h-full '>
                {/* <div className='flex flex-row justify-end'>
                  <Button
                    text={t('buttons.next')}
                    onClick={() => {
                      nextPage(6);
                      setSurveyComplete(true);
                    }}
                    className='mb-4'
                  />
                </div> */}
                <ViewForm
                  UUID={SURVEY_UUID}
                  onSubmit={() => {
                    nextPage(6);
                    setSurveyComplete(true);
                  }}
                  SubmitMessegeComponent={() => (
                    <Button
                      text={t('buttons.next')}
                      onClick={() => {
                        nextPage(6);
                        setSurveyComplete(true);
                      }}
                    />
                  )}
                />
                {/* <iframe
                  className='h-screen w-full'
                  title='survey'
                  src={`/forms/${SURVEY_UUID}`}
                  frameborder='0'
                /> */}
              </Tab.Panel>
              <Tab.Panel className='h-full'>
                <Confirm
                  applicant={body}
                  selectedBilSchool={selectedBilSchool}
                  selectedDistrict={selectedDistrict}
                  selectedExamCenter={selectedExamCenter}
                  vulnerableGroup={vulnerableGroup}
                  apply={apply}
                  loading={loading}
                />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </div>
  );
}
