import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '../../../../components/forms/Buttons/Button';
import CustomDialog from '../../../../components/modals/CustomDialog/CustomDialog';
import { Controller, useForm } from 'react-hook-form';
import { postExtraAllowedStudents } from '../../../../services/ExtraAllowedStudents/ExtraAllowedStudentsService';
import { getSchools } from '../../../../services/catalog/catalog';
import Input from '../../../../components/forms/Input/Input';
import { PlusIcon } from '@heroicons/react/24/solid';
import Select from '../../../../components/forms/Select/Select';
import { postClassroom } from '../../../../services/application/classroomService';
import { toast } from 'react-toastify';

export default function Add({ examTimes, examCenters, onCreate }) {
  const [open, setOpen] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    postClassroom(data)
      .then(() => {
        toast.info('Аудитория создана');
        if (onCreate) onCreate();
        onClose();
      })
      .catch((e) => {
        if (e?.response?.data?.non_field_errors) {
          toast.error(e.response.data.non_field_errors[0]);
        }
      });
  };

  const onClose = () => {
    setOpen(false);
    reset();
  };

  return (
    <>
      <Button Icon={PlusIcon} onClick={() => setOpen(true)} />
      <CustomDialog
        isOpen={open}
        setIsOpen={setOpen}
        closeModal={() => {
          onClose();
        }}
        headerText={'Добавить аудиторию'}
      >
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-2'>
          <Controller
            control={control}
            name={`exam_center`}
            rules={{ required: 'Поля "Экзам центр" обязательна' }}
            render={({ field }) => (
              <Select
                value={field.value}
                label={'Экзам центр'}
                error={errors?.exam_center?.message}
                required
                onChange={(v) => field.onChange(v.value)}
                options={examCenters.map((s) => ({
                  value: s.uuid,
                  label: s.name,
                }))}
              />
            )}
          />
          <Controller
            control={control}
            name={`exam_time`}
            rules={{ required: 'Поля "Время экзамена" обязательна' }}
            render={({ field }) => (
              <Select
                value={field.value}
                label={'Время экзамена'}
                error={errors?.exam_time?.message}
                required
                onChange={(v) => field.onChange(v.value)}
                options={examTimes.map((s) => ({
                  value: s.uuid,
                  label: s.name,
                }))}
              />
            )}
          />
          <Input
            label={'Номер аудитории'}
            {...register('classroom_id', {
              required: 'Поля "Номер аудитории" обязательна',
            })}
            type='number'
            error={errors?.classroom_id?.message}
          />

          <div className='flex flex-row justify-end'>
            <Button text='Создать' color='success' type='submit' />
          </div>
        </form>
      </CustomDialog>
    </>
  );
}
