import ReactApexChart from 'react-apexcharts';

export default function PieChart({ series, labels }) {
  return (
    <ReactApexChart
      className='h-full w-full'
      type='pie'
      series={series}
      options={{
        labels,
      }}
    />
  );
}
