import React from 'react';
import SimpleLoader from '../../components/Loader/SimpleLoader';

export default function withLoading(Component) {
  return (props) => {
    const { loading } = props;

    if (loading)
      return (
        <div className='flex flex-col items-center justify-center'>
          <SimpleLoader className='h-10' />
        </div>
      );

    return <Component {...props} />;
  };
}
