import { createContext, useCallback, useEffect, useState } from 'react';
import { getYears } from '../../services/core/coreService';

export const ExamYearContext = createContext(null);

export const ExamYearProvider = ({ children }) => {
  const [examYear, setExamYear] = useState(null);
  const [yearList, setYearList] = useState([]);
  const [currentYear, setCurrentYear] = useState();

  useEffect(() => {
    getYears().then((res) => {
      setYearList(res.results);
      var currentYear = res?.results?.filter((y) => {
        return y?.current === true;
      })[0];
      setCurrentYear(currentYear);

      setExamYear(currentYear);
    });

    return () => {};
  }, []);

  const updateExamYear = useCallback((newYear) => {
    setExamYear(newYear);
  }, []);

  return (
    <ExamYearContext.Provider
      value={{ yearList, examYear, updateExamYear, currentYear }}
    >
      {children}
    </ExamYearContext.Provider>
  );
};
