import { useEffect } from 'react';
import { setDefaultLocale } from 'react-datepicker';
import { I18nextProvider } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import NavBar from './components/navigation/NavBar/NavBar';
import PublicNavBar from './components/navigation/PublicNavBar/PublicNavBar';
import StudentNavBar from './components/navigation/StudentNavBar/StudentNavBar';
import ProtectedRoute from './components/routing/ProtectedRoute/ProtectedRoute';
import i18n from './lang/i18n';
import Applicants from './pages/Applicants/Applicants';
import ApplicantInfo from './pages/Applicants/ApplicantsInfo./ApplicantInfo';
import CreateApplicant from './pages/Applicants/CreateApplicant/CreateApplicant';
import Districts from './pages/Districts/Districts';
import ExportStudent from './pages/ExportStudent/ExportStudent';
import Landing from './pages/Landing/Landing';
import Login from './pages/Login/Login';
import MySchool from './pages/MySchool/MySchool';
import NotFound from './pages/NotFound/NotFound';
import SchoolRegistrationStatistics from './pages/SchoolStatistics/SchoolRegistrationStatistics';
import Rules from './pages/StudentPage/Rules';
import StudentProfile from './pages/StudentPage/StudentProfile';
import StudentInfo from './pages/Students/StudentInfo/StudentInfo';
import Students from './pages/Students/Students';
import ExaminationsAdmin from './pages/ExaminationsAdmin/Examinations';
import QuestionsAdmin from './pages/QuestionsAdmin/Questions';
import SnackbarProvider from './utils/contexts/SnackbarProvider';
import { UserContext } from './utils/contexts/UserContext';
import useFindUser from './utils/hooks/useFindUser';
import ExaminationsView from './pages/ExaminationsStudent/ExaminationsView';
import ExaminationStudentForm from './pages/ExaminationStudent/Examination';
import ExaminationStudentResult from './pages/ExaminationResultStudent/ExaminationResult';
import ExaminationStatistics from './pages/ExaminationStatistics/ExaminationStatistics';
import DistrictRegistration from './pages/DistrictRegistration/DistrictRegistration';
import UploadDistrict from './pages/DistrictRegistration/UploadDistrict/UploadDistrict';
import Results from './pages/Results/Results';
import UploadResults from './pages/Results/UploadResults/UploadResults';
import Setting from './pages/Settings/Settings';
import ResultsAdmin from './pages/ResultsAdmin/ResultsAdmin';
import UploadResultsAdmin from './pages/ResultsAdmin/UploadResultsAdmin/UploadResultsAdmin';
import StudentPass from './pages/Students/StudentPass/StudentPass';
import ExportStudentPass from './pages/ExportStudent/ExportStudentPass';
import StudentApplication from './pages/Students/StudentApplication/StudentApplication';
import AcceptedStudents from './pages/AcceptedStudents/AcceptedStudents';
import SchoolExamStatistics from './pages/SchoolStatistics/SchoolExamStatistics';
import SchoolPassScoreStatistics from './pages/SchoolStatistics/SchoolPassScoreStatistics';
import ExtraStudentsSetting from './pages/ExtraStudentsSetting/ExtraStudentsSetting';
import { ExamYearProvider } from './utils/contexts/ExamYearContext';
import StatisticsRegistrationComparison from './pages/StatisticsRegistrationComparison/StatisticsRegistrationComparison';
import Transfer from './pages/Transfer/Transfer';
import MyResults from './pages/ResultsStudents/MyResults/MyResults';
import SchoolResults from './pages/ResultsStudents/SchoolResults/SchoolResults';
import SchoolExamTimeStats from './pages/SchoolStatistics/SchoolExamTimeStats';
import SchoolClassroomStatistics from './pages/SchoolStatistics/SchoolClassroomStatistics';
import StudentAnswers from './pages/Results/StudentAnswers';
import SecretMyResults from './pages/SecretResults/Student/MyResults/SecretMyResults';
import SecretSchoolResults from './pages/SecretResults/Student/SchoolResults/SecretSchoolResults';
import SecretResults from './pages/SecretResults/School/SecretResults';
import AcceptedStudentsStats from './pages/AcceptedStudents/Admin/AcceptedStudentsStats';
import Registration from './pages/Registration/Registration';
import SuccessMessage from './pages/Registration/SuccessMessage';
import Forms from './pages/Forms/Forms/Forms';
import EditForm from './pages/Forms/Form/Edit/EditForm';
import { ToastContainer } from 'react-toastify';
import ViewForm from './pages/Forms/Form/View/ViewForm';
import SchoolGoals from './pages/SchoolGoals/SchoolGoals';
import FormStats from './pages/Forms/Form/Stats/Stats';
import { SURVEY_UUID } from './utils/constants/constants';

function App() {
  const { user, setUser, isLoading } = useFindUser();

  useEffect(() => {
    setDefaultLocale(localStorage.getItem('i18nextLng'));

    return () => {};
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <UserContext.Provider value={{ user, setUser, isLoading }}>
        <ExamYearProvider value={{}}>
          <SnackbarProvider>
            <ToastContainer position='bottom-right' />

            <Routes>
              {/* Public part */}
              <Route index element={<Landing />} />
              <Route path='/forms/:uuid' element={<ViewForm />} />

              <Route element={<PublicNavBar />}>
                <Route path='/login' element={<Login />} />
                <Route path='/registration' element={<Registration />} />
                {/* <Route path='/fake-registration' element={<Registration />} /> */}
                <Route
                  path='/7315555e-50a6-48b5-8b79-b0063687c3157315555e-50a6-48b5-8b79-b0063687c3157315555e-50a6-48b5-8b79-b0063687c3157315555e-50a6-48b5-8b79-b0063687c3157315555e-50a6-48b5-8b79-b0063687c315'
                  element={<Registration />}
                />

                <Route
                  path='/registration/message'
                  element={<SuccessMessage />}
                />
                {/* <Route path='/qwerty/message' element={<SuccessMessage />} /> */}
                <Route
                  path='/export-student/:uuid'
                  element={<ExportStudent />}
                />
                <Route
                  path='/export-student-pass/:uuid'
                  element={<ExportStudentPass />}
                />
              </Route>

              {/* Private part */}
              {/* Admin side */}
              <Route
                element={
                  <ProtectedRoute
                    isAllowed={
                      !!user?.uuid &&
                      user?.roles?.some(
                        (role) =>
                          role?.code === 'TRADMIN' ||
                          role?.code === 'VP' ||
                          role?.code === 'SA' ||
                          role?.code === 'SSA' ||
                          role?.code.includes('RC'),
                      )
                    }
                    isLoading={isLoading}
                  />
                }
              >
                <Route
                  path='/'
                  element={<NavBar user={user} setUser={setUser} />}
                >
                  {/* <Route index element={<Landing />} /> */}
                  <Route path='applicants' element={<Applicants />} />
                  <Route
                    path='applicants/create'
                    element={<CreateApplicant />}
                  />
                  <Route path='applicants/:uuid' element={<ApplicantInfo />} />
                  <Route path='students' element={<Students />} />
                  <Route path='students/:uuid' element={<StudentInfo />} />

                  <Route
                    path='7315555e-50a6-48b5-8b79-b0063687c315/results'
                    element={<SecretResults />}
                  />
                  <Route path='results' element={<Results />} />
                  <Route path='results/:uuid/pass' element={<StudentPass />} />
                  <Route
                    path='results/:uuid/answers'
                    element={<StudentAnswers />}
                  />
                  <Route
                    path='results/:uuid/application'
                    element={<StudentApplication />}
                  />
                  <Route path='results/upload' element={<UploadResults />} />
                  <Route path='accepted' element={<AcceptedStudents />} />
                  <Route
                    path='district-registration'
                    element={<DistrictRegistration />}
                  />
                  <Route
                    path='district-registration/upload'
                    element={<UploadDistrict />}
                  />
                  <Route path='districts' element={<Districts />} />
                  <Route
                    path='forms'
                    element={
                      <ProtectedRoute
                        redirectPath='/applicants'
                        isAllowed={
                          !!user &&
                          user?.roles?.some((role) => role?.code === 'SSA')
                        }
                      >
                        <Forms />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='forms/:uuid/edit'
                    element={
                      <ProtectedRoute
                        redirectPath='/applicants'
                        isAllowed={
                          !!user &&
                          user?.roles?.some((role) => role?.code === 'SSA')
                        }
                      >
                        <EditForm />
                      </ProtectedRoute>
                    }
                  />
                  <Route path='my-school' element={<MySchool />} />
                  <Route
                    path='examinations-stats'
                    element={<ExaminationStatistics />}
                  />
                  <Route
                    path='school-classroom-statistics'
                    element={<SchoolClassroomStatistics />}
                  />

                  <Route
                    path='school-registration-statistics'
                    element={
                      <ProtectedRoute
                        redirectPath='/applicants'
                        isAllowed={
                          !!user &&
                          user?.roles?.some(
                            (role) =>
                              role?.code === 'TRADMIN' ||
                              role?.code === 'SA' ||
                              role?.code.includes('RC') ||
                              role?.code === 'SSA',
                          )
                        }
                      >
                        <SchoolRegistrationStatistics />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='registration-comparison-statistics'
                    element={
                      <ProtectedRoute
                        redirectPath='/applicants'
                        isAllowed={
                          !!user &&
                          user?.roles?.some(
                            (role) =>
                              role?.code === 'TRADMIN' ||
                              role?.code === 'SA' ||
                              role?.code.includes('RC') ||
                              role?.code === 'SSA',
                          )
                        }
                      >
                        <StatisticsRegistrationComparison />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='school-pass-score-statistics'
                    element={
                      <ProtectedRoute
                        redirectPath='/applicants'
                        isAllowed={
                          !!user &&
                          user?.roles?.some(
                            (role) =>
                              role?.code === 'TRADMIN' ||
                              role?.code === 'SA' ||
                              role?.code.includes('RC') ||
                              role?.code === 'SSA',
                          )
                        }
                      >
                        <SchoolPassScoreStatistics />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='school-acceptance-stats'
                    element={
                      <ProtectedRoute
                        redirectPath='/applicants'
                        isAllowed={
                          !!user &&
                          user?.roles?.some(
                            (role) =>
                              role?.code === 'TRADMIN' ||
                              role?.code === 'SA' ||
                              role?.code.includes('RC') ||
                              role?.code === 'SSA',
                          )
                        }
                      >
                        <AcceptedStudentsStats />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='school-acceptance-stats/:uuid'
                    element={
                      <ProtectedRoute
                        redirectPath='/applicants'
                        isAllowed={
                          !!user &&
                          user?.roles?.some(
                            (role) =>
                              role?.code === 'TRADMIN' ||
                              role?.code === 'SA' ||
                              role?.code.includes('RC') ||
                              role?.code === 'SSA',
                          )
                        }
                      >
                        <AcceptedStudents />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='form-stats'
                    element={<FormStats UUID={SURVEY_UUID} />}
                  />
                  <Route
                    path='school-exam-time-statistics'
                    element={
                      <ProtectedRoute
                        redirectPath='/applicants'
                        isAllowed={
                          !!user &&
                          user?.roles?.some(
                            (role) =>
                              role?.code === 'TRADMIN' ||
                              role?.code === 'SA' ||
                              role?.code.includes('RC') ||
                              role?.code === 'SSA',
                          )
                        }
                      >
                        <SchoolExamTimeStats />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='school-exam-statistics'
                    element={
                      <ProtectedRoute
                        redirectPath='/applicants'
                        isAllowed={
                          !!user &&
                          user?.roles?.some(
                            (role) =>
                              role?.code === 'TRADMIN' ||
                              role?.code === 'SA' ||
                              role?.code.includes('RC') ||
                              role?.code === 'SSA',
                          )
                        }
                      >
                        <SchoolExamStatistics />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='transfer'
                    element={
                      <ProtectedRoute
                        redirectPath='/school-registration-statistics'
                        isAllowed={
                          !!user &&
                          user?.roles?.some(
                            (role) =>
                              role?.code === 'TRADMIN' ||
                              role?.code === 'VP' ||
                              role?.code.includes('RC') ||
                              role?.code === 'SSA',
                          )
                        }
                      >
                        <Transfer />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='admin/exams/:examUUID/questions'
                    element={
                      <ProtectedRoute
                        redirectPath='/applicants'
                        isAllowed={
                          !!user &&
                          user?.roles?.some(
                            (role) =>
                              role?.code === 'TRADMIN' ||
                              role?.code === 'SSA' ||
                              role?.code === 'SA',
                          )
                        }
                      >
                        <QuestionsAdmin />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='admin/exams'
                    element={
                      <ProtectedRoute
                        redirectPath='/applicants'
                        isAllowed={
                          !!user &&
                          user?.roles?.some(
                            (role) =>
                              role?.code === 'TRADMIN' ||
                              role?.code === 'SSA' ||
                              role?.code === 'SA',
                          )
                        }
                      >
                        <ExaminationsAdmin />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='admin/settings'
                    element={
                      <ProtectedRoute
                        redirectPath='/applicants'
                        isAllowed={
                          !!user &&
                          user?.roles?.some((role) => role?.code === 'SSA')
                        }
                      >
                        <Setting />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='admin/school_goals'
                    element={
                      <ProtectedRoute
                        redirectPath='/applicants'
                        isAllowed={
                          !!user &&
                          (user?.roles?.some((role) => role?.code === 'SSA') ||
                            (user?.roles?.some(
                              (role) => role?.code === 'TRADMIN',
                            ) &&
                              user?.roles?.some((role) => role?.code === 'SA')))
                        }
                      >
                        <SchoolGoals />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='admin/results'
                    element={
                      <ProtectedRoute
                        redirectPath='/applicants'
                        isAllowed={
                          !!user &&
                          user?.roles?.some(
                            (role) =>
                              role?.code === 'TRADMIN' ||
                              role?.code === 'SSA' ||
                              role?.code === 'SA',
                          )
                        }
                      >
                        <ResultsAdmin />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='admin/results/upload'
                    element={
                      <ProtectedRoute
                        redirectPath='/applicants'
                        isAllowed={
                          !!user &&
                          user?.roles?.some((role) => role?.code === 'SSA')
                        }
                      >
                        <UploadResultsAdmin />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='admin/extra-allowed-students-settings'
                    element={
                      <ProtectedRoute
                        redirectPath='/applicants'
                        isAllowed={
                          !!user &&
                          user?.roles?.some((role) => role?.code === 'SSA')
                        }
                      >
                        <ExtraStudentsSetting />
                      </ProtectedRoute>
                    }
                  />
                </Route>
              </Route>

              {/* Student side */}
              <Route
                element={
                  <ProtectedRoute
                    isAllowed={
                      !!user?.uuid &&
                      user?.roles?.some((role) => role?.code === 'STD')
                    }
                    isLoading={isLoading}
                  />
                }
              >
                <Route
                  path='/student'
                  element={<StudentNavBar user={user} setUser={setUser} />}
                >
                  <Route path='profile' element={<StudentProfile />} />
                  <Route
                    path='7315555e-50a6-48b5-8b79-b0063687c315/my_results'
                    element={<SecretMyResults />}
                  />
                  <Route
                    path='7315555e-50a6-48b5-8b79-b0063687c315/results'
                    element={<SecretSchoolResults />}
                  />
                  <Route path='my_results' element={<MyResults />} />
                  <Route path='results' element={<SchoolResults />} />
                  <Route path='rules' element={<Rules />} />
                  <Route
                    path='exam/:examUUID/result'
                    element={<ExaminationStudentResult />}
                  />
                  <Route
                    path='exam/:examUUID'
                    element={<ExaminationStudentForm />}
                  />
                  <Route path='exams' element={<ExaminationsView />} />
                </Route>
              </Route>
              <Route path='*' element={<NotFound />} />
            </Routes>
          </SnackbarProvider>
        </ExamYearProvider>
      </UserContext.Provider>
    </I18nextProvider>
  );
}

export default App;
