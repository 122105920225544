import { LinkIcon, ListBulletIcon } from '@heroicons/react/24/outline';
import React, { useState, useEffect, useCallback, useRef } from 'react';

const MiniRichTextEditor = React.forwardRef(
  (
    {
      defaultValue,
      className,
      onChange,
      list,
      placeholder,
      borderColor,
      ...rest
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const isSettingDefaultValue = useRef(false);
    const inputRef = useRef();

    const [formats, setFormats] = useState({
      bold: false,
      italic: false,
      underline: false,
      strikeThrough: false,
      link: false,
      orderedList: false,
      unorderedList: false,
    });

    const formatText = (command, value = null) => {
      document.execCommand(command, false, value);
      updateFormats();
    };

    const updateFormats = () => {
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        const anchorNode = selection.anchorNode;
        const parentNode = anchorNode.parentNode;

        setFormats({
          bold: document.queryCommandState('bold'),
          italic: document.queryCommandState('italic'),
          underline: document.queryCommandState('underline'),
          strikeThrough: document.queryCommandState('strikeThrough'),
          link: parentNode && parentNode.tagName === 'A',
          orderedList: document.queryCommandState('insertOrderedList'),
          unorderedList: document.queryCommandState('insertUnorderedList'),
        });
      }
    };

    const createLinkElement = (url, text) => {
      const anchor = document.createElement('a');
      //   anchor.href = "javascript:alert('XSS');"
      anchor.href = url?.startsWith('http') ? url : `https://${url}`;
      anchor.textContent = text || url;
      anchor.title = url;
      anchor.className = 'text-primary hover:underline';
      anchor.target = '_blank';

      return anchor;
    };

    const createLink = () => {
      const url = prompt('Enter the URL');
      if (url) {
        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          if (range.collapsed) {
            const linkElement = createLinkElement(url, url);
            range.insertNode(linkElement);
          } else {
            const textContent = range.toString();
            range.deleteContents();
            range.insertNode(createLinkElement(url, textContent));
          }
        }
        updateFormats();

        handleInput();
      }
    };

    const handleSelectionChange = useCallback(() => {
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        updateFormats();
      }
    }, []);

    const handleInput = useCallback(() => {
      if (!isSettingDefaultValue.current && onChange) {
        if (
          (ref ?? inputRef).current.innerHTML?.replace(/(<([^>]+)>)/gi, '') ===
          ''
        ) {
          onChange('');
        } else {
          onChange((ref ?? inputRef).current.innerHTML);
        }
      }
    }, [onChange, ref]);

    useEffect(() => {
      const inputElem = (ref ?? inputRef).current;
      inputElem.addEventListener('input', handleInput);

      document.addEventListener('selectionchange', handleSelectionChange);

      return () => {
        document.removeEventListener('selectionchange', handleSelectionChange);
        inputElem.removeEventListener('input', handleInput);
      };
    }, [onChange, handleSelectionChange, defaultValue, handleInput, ref]);

    useEffect(() => {
      if (defaultValue && (ref ?? inputRef).current) {
        isSettingDefaultValue.current = true;
        (ref ?? inputRef).current.innerHTML = defaultValue;
        isSettingDefaultValue.current = false;
      }
    }, [defaultValue, ref]);

    const insertListWithClass = (listType, className) => {
      const selection = window.getSelection();
      if (!selection.rangeCount) return;

      const range = selection.getRangeAt(0);
      const fragment = range.extractContents();
      const list = document.createElement(listType);
      list.className = className;

      if (!fragment.firstChild) {
        const listItem = document.createElement('li');
        listItem.appendChild(document.createTextNode(''));
        list.appendChild(listItem);
      } else {
        while (fragment.firstChild) {
          const listItem = document.createElement('li');
          listItem.appendChild(fragment.firstChild);
          list.appendChild(listItem);
        }
      }

      range.insertNode(list);

      const newRange = document.createRange();
      newRange.setStart(list, 0);
      newRange.collapse(true);
      selection.removeAllRanges();
      selection.addRange(newRange);
      handleInput();
      updateFormats();
    };

    return (
      <div className='w-full overflow-hidden rounded-lg'>
        <div>
          <div
            ref={ref ?? inputRef}
            contentEditable
            className={`${className} p-2`}
            data-placeholder={placeholder}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
            {...rest}
          />
          <div className='flex flex-row justify-center text-transparent'>
            <div
              className={`${
                isFocused ? 'w-[100%] transition-[width] delay-200' : 'w-[0%]'
              } ${borderColor} border-b border-current`}
            />
          </div>
        </div>
        <style>{`
          [contenteditable]:empty:before {
            content: attr(data-placeholder);
            color: #a0a0a0;
            pointer-events: none;
          }
        `}</style>
        {true && (
          <div
            className={`${
              isFocused ? 'h-12' : 'h-0'
            } mt-2 flex w-full flex-row justify-start gap-0.5 px-2 font-serif text-xl font-semibold transition-[height]`}
            onMouseDown={(e) => {
              e.preventDefault();
            }}
          >
            <button
              title='Bold'
              className={`hover:text-black h-8 w-8 rounded text-center text-gray-500 hover:bg-slate-100
                ${formats.bold ? 'text-black bg-slate-200' : ''}`}
              onClick={() => formatText('bold')}
            >
              <b>B</b>
            </button>
            <button
              title='Italic'
              className={`hover:text-black h-8 w-8 rounded text-center text-gray-500 hover:bg-slate-100
                ${formats.italic ? 'text-black bg-slate-200' : ''}`}
              onClick={() => formatText('italic')}
            >
              <i>I</i>
            </button>
            <button
              title='Underline'
              className={`hover:text-black h-8 w-8 rounded text-center text-gray-500 hover:bg-slate-100
                ${formats.underline ? 'text-black bg-slate-200' : ''}`}
              onClick={() => formatText('underline')}
            >
              <u>U</u>
            </button>
            <button
              title='Insert Link'
              className={`hover:text-black h-8 w-8 rounded text-gray-500 hover:bg-slate-100
                ${formats.link ? 'text-black bg-slate-200' : ''}`}
              onClick={createLink}
            >
              <center>
                <LinkIcon className='h-5 stroke-[3]' />
              </center>
            </button>
            <button
              title='Strike Through'
              className={`hover:text-black h-8 w-8 rounded text-center text-gray-500 hover:bg-slate-100
                ${formats.strikeThrough ? 'text-black bg-slate-200' : ''}`}
              onClick={() => formatText('strikeThrough')}
            >
              <s>S</s>
            </button>
            {list && (
              <>
                <button
                  title='Numbered List'
                  className={`hover:text-black h-8 w-8 rounded text-center text-gray-500 hover:bg-slate-100
                    ${formats.orderedList ? 'text-black bg-slate-200' : ''}`}
                  onClick={() =>
                    insertListWithClass('insertOrderedList', 'list-decimal')
                  }
                >
                  <center>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='h-5 stroke-[3]'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M8.242 5.992h12m-12 6.003H20.24m-12 5.999h12M4.117 7.495v-3.75H2.99m1.125 3.75H2.99m1.125 0H5.24m-1.92 2.577a1.125 1.125 0 1 1 1.591 1.59l-1.83 1.83h2.16M2.99 15.745h1.125a1.125 1.125 0 0 1 0 2.25H3.74m0-.002h.375a1.125 1.125 0 0 1 0 2.25H2.99'
                      />
                    </svg>
                  </center>
                </button>
                <button
                  title='Bullet List'
                  className={`hover:text-black h-8 w-8 rounded text-center text-gray-500 hover:bg-slate-100
                    ${formats.unorderedList ? 'text-black bg-slate-200' : ''}`}
                  onClick={() =>
                    insertListWithClass('insertUnorderedList', 'list-disc')
                  }
                >
                  <center>
                    <ListBulletIcon className='h-5 stroke-[3]' />
                  </center>
                </button>
              </>
            )}
            <button
              title='Remove Format'
              className='hover:text-black h-8 w-8 rounded text-center text-gray-500 hover:bg-slate-100'
              onClick={() => formatText('removeFormat')}
            >
              X
            </button>
          </div>
        )}
      </div>
    );
  },
);

export default MiniRichTextEditor;
