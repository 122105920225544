import React, { useState } from 'react';
import { Tab } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import Classrooms from './Classrooms/Classrooms';
import SchoolInfo from './SchoolInfo';

const TAB_STYLE =
  'w-full border-b-2 p-2 ui-selected:border-b-blue-600 ui-selected:bg-blue-50 ui-selected:text-blue-600';

export default function MySchool() {
  const [t] = useTranslation();
  const [tab, setTab] = useState(0);

  return (
    <div className='flex flex-col'>
      <Tab.Group selectedIndex={tab} onChange={setTab} manual>
        <Tab.List className='flex w-full flex-col font-semibold sm:h-20 sm:flex-row'>
          <Tab className={TAB_STYLE}>
            <span className='flex flex-row items-center justify-center'>
              Моя школа
            </span>
          </Tab>
          <Tab className={TAB_STYLE}>
            <span className='flex flex-row items-center justify-center'>
              <p className='relative w-fit'>
                <span className='z-1 absolute -top-3 -right-6 animate-pulse rounded-md border  border-red-600 bg-red-600 px-1  text-xs font-bold text-white'>
                  new
                </span>
                Аудитории
              </p>
            </span>
          </Tab>
        </Tab.List>

        <Tab.Panels className='h-auto p-6'>
          <Tab.Panel className='h-full'>
            <SchoolInfo />
          </Tab.Panel>
          <Tab.Panel className='h-full'>
            <Classrooms />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
