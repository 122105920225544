import React, { useCallback, useEffect, useState } from 'react';
import SimpleLoader from '../../../components/Loader/SimpleLoader';
import {
  clearCacheBackend,
  getSettings,
} from '../../../services/settings/settingsService';
import SettingEditModal from './SettingEditModal';
import { Button } from '../../../components/forms/Buttons/Button';
import useSnackbar from '../../../utils/hooks/useSnackbar';
import { getSchools } from '../../../services/catalog/catalog';

export default function Main() {
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState([]);
  const { addSnackbar } = useSnackbar();
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    getSchools({ page_size: 50 }).then((res) => {
      setSchools(res.results);
    });
  }, []);

  const getSettingsData = useCallback((signal) => {
    setIsLoading(true);
    getSettings({ params: {}, signal })
      ?.then((res) => {
        setSettings(res);
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    getSettingsData(controller.signal);
    return () => {
      controller.abort();
    };
  }, [getSettingsData]);

  return (
    <>
      {isLoading ? (
        <SimpleLoader className='h-10' />
      ) : (
        <>
          <div className='mb-8 mr-8 flex flex-row justify-end'>
            <Button
              text='Clear cache'
              onClick={() => {
                clearCacheBackend().then((res) => {
                  addSnackbar('Cache cleared');
                });
              }}
            />
          </div>
          {settings?.map((setting) => {
            return (
              <div
                key={setting.uuid}
                className='flex flex-row items-center justify-between border-b py-2 px-20'
              >
                <span>{setting.name}</span>
                <SettingEditModal
                  setting={setting}
                  schools={schools}
                  getData={() => getSettingsData()}
                />
              </div>
            );
          })}
        </>
      )}
    </>
  );
}
