import React, { useEffect, useState } from 'react';
import ExportApplication from './ExportApplication';
import { useParams } from 'react-router-dom';
import {
  getApplicantsLite,
  patchApplication,
} from '../../../services/application/application';
import { useTranslation } from 'react-i18next';
import useSnackbar from '../../../utils/hooks/useSnackbar';
import { Button } from '../../../components/forms/Buttons/Button';
import { PaperAirplaneIcon } from '@heroicons/react/24/solid';
import { DocumentIcon, LinkIcon } from '@heroicons/react/24/outline';
import UpdateExamInfoModal from './UpdateExamInfoModal/UpdateExamInfoModal';
import DocumentsModal from './DocumentsModal';

export default function StudentInfo() {
  // const { state } = useLocation();
  const { uuid } = useParams();
  const [t] = useTranslation();
  const { addSnackbar } = useSnackbar();
  const [modalOpen, setModalOpen] = useState(false);
  const [docsModalOpen, setDocsModalOpen] = useState(false);
  const [studentInfo, setStudentInfo] = useState(null);

  useEffect(() => {
    if (uuid) {
      refreshData();
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  const refreshData = () => {
    getApplicantsLite(uuid).then((res) => {
      setStudentInfo(res);
    });
  };
  return (
    <div className='flex flex-col items-center gap-2'>
      <UpdateExamInfoModal
        student={studentInfo}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        refreshData={refreshData}
      />
      <DocumentsModal
        student={studentInfo}
        docsModalOpen={docsModalOpen}
        setDocsModalOpen={setDocsModalOpen}
      />
      <div className='flex w-full max-w-7xl flex-row justify-between gap-2'>
        <div className='flex flex-row gap-2'>
          <Button
            color='primary'
            type='button'
            text='Обновить'
            onClick={() => {
              setModalOpen(true);
            }}
          />
          <Button
            type='button'
            text='Документы'
            onClick={() => {
              setDocsModalOpen(true);
            }}
          />
        </div>
        <div className='flex flex-row gap-2'>
          {studentInfo?.asp_wa_me_link && false && (
            <Button
              color='success'
              text='ӘОТ сообщение'
              Icon={PaperAirplaneIcon}
              onClick={() => {
                window.open(
                  studentInfo?.asp_wa_me_link,
                  '_blank',
                  'noopener,noreferrer',
                );
              }}
            />
          )}
          <Button
            color='success'
            text='Whatsapp'
            Icon={LinkIcon}
            onClick={() => {
              window.open(
                studentInfo?.wa_me_link,
                '_blank',
                'noopener,noreferrer',
              );
              patchApplication(studentInfo?.uuid, { sent: true })
                .then(() => {
                  addSnackbar(t('Успешно'), 'success');
                })
                .catch(() => {
                  addSnackbar(t('Ошибка'), 'error');
                });
            }}
          />
        </div>
      </div>

      <ExportApplication
        studentInfo={studentInfo}
        onSave={() =>
          patchApplication(uuid, { sent: true })
            .then(() => {
              addSnackbar(t('Успешно'), 'success');
            })
            .catch(() => {
              addSnackbar(t('Ошибка'), 'error');
            })
        }
      />
    </div>
  );
}
