import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Questions from './Questions/Questions';
//   import Responses from './Responses/Responses';
//   import useResponses from './Responses/useResponses';

const EditForm = () => {
  //   const { uuid } = useParams();
  const [tab, setTab] = useState('questions');
  // const { count, isLoading, setPage, users } = useResponses(uuid);
  const tabRefs = useRef({});
  //   const { state } = useLocation();

  const tabs = useMemo(
    () => [
      {
        key: 'questions',
        text: 'Questions',
      },
      // {
      //   key: 'responses',
      //   text: (
      //     <span className='flex flex-row gap-3'>
      //       Responses{' '}
      //       {!!count && (
      //         <div
      //           className={`flex flex-col justify-center text-white rounded-full  text-xs px-1 ${
      //             tab === 'responses' ? 'bg-violet-700' : 'bg-slate-700'
      //           }`}
      //         >
      //           {count}
      //         </div>
      //       )}
      //     </span>
      //   ),
      // },
      // { key: 'settings', text: 'Settings' },
    ],
    [],
    //   [count, tab],
  );

  // useEffect(() => {
  //   if (state?.tab) {
  //     setTab(tabs[state?.tab]?.key);
  //     state.tab = null;
  //   }
  //   if (state?.user) {
  //     console.log(
  //       users?.indexOf(users?.find((u) => u.user.uuid === state.user)),
  //     );
  //     setPage(users?.indexOf(users?.find((u) => u.user.uuid === state.user)));
  //     state.user = null;
  //   }
  // }, [state, tabs, users, setPage]);

  // const updateTabBorder = useCallback(() => {
  //   const activeTab = tabRefs.current[tab];
  //   const tabBorder = document.querySelector('.tab-border');

  //   if (activeTab && tabBorder) {
  //     const tabWidth = activeTab.offsetWidth;
  //     const tabLeft = activeTab.offsetLeft;

  //     tabBorder.style.width = `${tabWidth}px`;
  //     tabBorder.style.left = `${tabLeft}px`;
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tab, count]);

  // useEffect(() => {
  //   updateTabBorder();
  //   window.addEventListener('resize', updateTabBorder);
  //   return () => {
  //     window.removeEventListener('resize', updateTabBorder);
  //   };
  // }, [updateTabBorder]);

  return (
    <div className=' relative flex max-h-[93vh] min-h-[93vh] flex-col items-center bg-slate-200'>
      <div className='relative flex h-16 min-h-[4rem] w-full flex-row items-end justify-center gap-2 border-y bg-white pb-1 font-semibold text-slate-500'>
        {tabs.map((t, i) => {
          return (
            <div
              key={t.key}
              ref={(el) => (tabRefs.current[t.key] = el)}
              className={`${tab === t.key && 'text-violet-700'} px-2 py-1`}
              onClick={() => {
                setTab(t.key);
              }}
            >
              {t.text}
            </div>
          );
        })}
        <div
          className={`tab-border absolute bottom-0  h-0.5 bg-violet-700 transition-all duration-300`}
        />
      </div>
      <div className='flex h-fit w-full flex-row justify-center overflow-y-scroll p-12'>
        <div className='h-fit'>
          {tab === 'questions' && <Questions />}
          {/* {tab === 'responses' && <Responses />} */}
        </div>
      </div>
    </div>
  );
};

export default EditForm;
