import {
  ArrowDownRightIcon,
  ArrowUpRightIcon,
  Bars2Icon,
} from '@heroicons/react/24/solid';
import React, { useCallback, useEffect, useState } from 'react';
import StatsTable from './StatsTable';
import Select from '../../components/forms/Select/Select';
import { getSchoolsRegistrationComparisonStats } from '../../services/schoolStatistics/schoolStatistics';
import moment from 'moment';
import DatePicker from '../../components/forms/DatePicker/DatePicker';

export default function SchoolListCompareTable({
  prevYear,
  curYear,
  regions,
  compareStats,
}) {
  const [statsBySchools, setStatsBySchools] = useState([]);
  const [schoolsLoading, setSchoolsLoading] = useState(false);
  const [region, setRegion] = useState(null);
  const [endDate, setEndDate] = useState(new Date());

  const columns = React.useMemo(
    () => [
      {
        Header: 'Школа',
        accessor: 'name',
      },
      {
        Header: `Сравнение ${prevYear?.label}/${curYear?.label}`,
        accessor: 'isIncreased',
        Cell: (props) => (
          <div className=' font-bold'>
            {props.value === 0 && (
              <ArrowDownRightIcon className='h-6 text-red-600' />
            )}
            {props.value === 1 && (
              <ArrowUpRightIcon className='h-6 text-green-600' />
            )}
            {props.value === 2 && <Bars2Icon className='h-6' />}
          </div>
        ),
      },
      ...Array.from(
        { length: curYear?.label - prevYear?.label + 1 },
        (_, i) => i + prevYear?.label,
      )?.reduce(
        (prev, year) => [
          ...prev,
          {
            Header: `${year}`,
            accessor: `y_${year}`,
          },
          {
            Header: `Цель ${year}`,
            accessor: `${year}_year_goal`,
            Cell: (props) => (
              <div
                className={`${
                  props.value > props.row.original?.[`y_${year}`]
                    ? 'bg-red-300'
                    : props.value === props.row.original?.[`y_${year}`]
                    ? 'bg-blue-300'
                    : 'bg-green-300'
                } text-center font-bold`}
              >
                {props.value}
              </div>
            ),
          },
        ],
        [],
      ),
    ],
    [prevYear?.label, curYear?.label],
  );

  const getSchoolsStats = useCallback(
    (signal) => {
      setSchoolsLoading(true);
      getSchoolsRegistrationComparisonStats({
        params: {
          region,
          end_date: moment(endDate).format('DD.MM'),
          prev_year: prevYear?.label,
          current_year: curYear?.label,
        },
        signal,
      })
        .then((res) => {
          const newStats = compareStats(res?.results);
          const key = `y_${curYear?.label}`;
          setStatsBySchools(
            newStats?.sort((a, b) => (a?.[key] < b?.[key] ? 1 : -1)),
          );
        })
        .finally(() => {
          setSchoolsLoading(false);
        });
    },
    [compareStats, region, endDate, curYear?.label, prevYear?.label],
  );

  useEffect(() => {
    const controller = new AbortController();
    getSchoolsStats(controller.signal);
    return () => {
      controller.abort();
    };
  }, [getSchoolsStats]);

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-row gap-4 pb-4'>
        <div className='flex flex-row gap-2 border-r-2 border-gray-800 pr-2'>
          <Select
            label='Регион:'
            value={region}
            isSearchable={false}
            options={[{ label: 'Все регионы', value: null }, ...regions]}
            onChange={(e) => {
              setRegion(e.value);
            }}
          />
        </div>
        <div>
          <DatePicker
            className='w-64'
            label={'Сравнение до:'}
            selected={endDate}
            onChange={(date) => {
              setEndDate(date);
            }}
            dateFormat='dd / MM / yyyy'
          />
        </div>
      </div>
      <StatsTable
        columns={columns}
        data={statsBySchools}
        loading={schoolsLoading}
      />
    </div>
  );
}
