import { useCallback, useEffect, useState } from 'react';
import { create } from 'zustand';
import { getFormContents } from '../../../services/forms/formContentService';
import { getForm } from '../../../services/forms/formsService';

const initialState = {
  formUUID: null,
  isLoadingForm: true,
  isLoadingContent: true,
  form: null,
  contents: [],
  points: 0,
  page: 1,
};

const useFormStore = create((set, get) => ({
  ...initialState,
  customSet: (key, value) => {
    let v = value;
    if (typeof value === 'function') {
      v = value(get()[key]);
    }
    set({ [key]: v });
  },
  setFormUUID: (formUUID) => set({ formUUID }),
  setIsLoadingForm: (isLoadingForm) => set({ isLoadingForm }),
  setIsLoadingContent: (isLoadingContent) => set({ isLoadingContent }),
  setPage: (v) => get().customSet('page', v),
  setForm: (form) => set({ form }),
  setContents: (v) => get().customSet('contents', v),
  setPoints: (v) => get().customSet('points', v),
  clear: () => set(initialState),
}));

export default function useForm(uuid, session) {
  const { formUUID, setFormUUID } = useFormStore();
  const { isLoadingForm, setIsLoadingForm } = useFormStore();
  const { isLoadingContent, setIsLoadingContent } = useFormStore();
  const { form, setForm } = useFormStore();
  const { contents, setContents } = useFormStore();
  const { points, setPoints } = useFormStore();
  const { page, setPage } = useFormStore();

  const fetchForm = useCallback(() => {
    if (!uuid) return;
    setIsLoadingForm(true);
    getForm(uuid, session)
      .then((res) => {
        setForm(res);
        setContents(res.content);
      })
      .catch((err) => console.error('Ошибка при получении формы:', err))
      ?.finally(() => setIsLoadingForm(false));
  }, [session, setForm, setContents, setIsLoadingForm, uuid]);

  const fetchContents = useCallback(() => {
    setIsLoadingContent(true);
    return getFormContents({ form: uuid, page_size: 100 })
      .then((res) => {
        setContents(res?.results);
        setPoints(res?.results?.reduce((prev, curr) => prev + curr.points, 0));
        return res;
      })
      .catch((err) => {
        console.error('Ошибка при получении содержимого:', err);
        throw err;
      })
      .finally(() => setIsLoadingContent(false));
  }, [setContents, setIsLoadingContent, setPoints, uuid]);

  const updateContent = (uuid) => {};

  useEffect(() => {
    fetchForm();
    // fetchContents();
  }, [fetchForm, fetchContents]);

  useEffect(() => {
    if (formUUID !== uuid) {
      setPage(1);
      setFormUUID(uuid);
    }
  }, [setPage, formUUID, setFormUUID, uuid]);

  return {
    form,
    fetchForm,
    contents,
    setContents,
    fetchContents,
    points,
    setPoints,
    isLoadingForm,
    isLoadingContent,
    isLoading: isLoadingForm || isLoadingContent,
  };
}
