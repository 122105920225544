import React, { useEffect, useState } from 'react';
import { postAnswerFormQuestion } from '../../../../../services/forms/formContentService';
import { useTranslation } from 'react-i18next';
import TextAnswer from './TextAnswer';

export default function Checkboxes({ content, session, onAnswer }) {
  const [t] = useTranslation();
  const [fields, setFields] = useState(content?.questionselectcontent);
  const [answered, setAnswered] = useState(content?.answer?.choices);
  const [isOther, setIsOther] = useState(content?.answer?.answer?.length > 0);
  const [other, setOther] = useState(content?.answer?.answer);

  return (
    <div className='flex flex-col gap-4'>
      {fields?.map((field) => {
        return (
          <div key={field?.uuid} className='flex flex-row items-center gap-2'>
            <input
              id={`${field?.uuid}`}
              className='float-left mt-1 mr-2 
            h-5 min-h-[1.25rem] w-5
            cursor-pointer rounded-md bg-white align-top transition duration-200 focus:outline-none'
              type='checkbox'
              defaultChecked={answered?.includes(field?.uuid)}
              // checked={field.is_correct}
              onChange={(e) => {
                const isTrue = (prev) => [...prev, field?.uuid];
                const isFalse = (prev) =>
                  prev?.filter((e) => e !== field?.uuid);

                postAnswerFormQuestion(content.uuid, {
                  choices: e.target.checked
                    ? isTrue(answered)
                    : isFalse(answered),
                  session,
                })?.then(() => {
                  if (onAnswer) {
                    onAnswer(
                      e.target.checked ? isTrue(answered) : isFalse(answered),
                    );
                  }

                  setAnswered((prev) =>
                    e.target.checked ? isTrue(prev) : isFalse(prev),
                  );
                });
              }}
            />
            <label className='cursor-pointer' htmlFor={`${field?.uuid}`}>
              {field?.content}
            </label>
          </div>
        );
      })}
      {content?.allow_other && (
        <>
          <div className='flex flex-row items-center gap-2'>
            <input
              id={`other-${content.uuid}`}
              className='float-left mt-1 mr-2 
            h-5 min-h-[1.25rem] w-5
            cursor-pointer rounded-md bg-white align-top transition duration-200 focus:outline-none'
              type='checkbox'
              defaultChecked={content?.answer?.answer?.length > 0}
              checked={isOther}
              onChange={(e) => {
                if (!e.target.checked) {
                  onAnswer('', 'answer');
                  setOther('');
                  setTimeout(
                    () =>
                      postAnswerFormQuestion(content.uuid, {
                        answer: null,
                        session,
                      }),
                    1100,
                  );
                }

                setIsOther(e.target.checked);
              }}
            />
            <label className='cursor-pointer' htmlFor={`other-${content.uuid}`}>
              {t('buttons.other')}
            </label>
          </div>
          {isOther && (
            <TextAnswer
              content={{
                ...content,
                answer: { ...content.answer, answer: other },
              }}
              session={session}
              onAnswer={(v) => {
                setOther(v);
                onAnswer(v, 'answer');
              }}
            />
          )}
        </>
      )}
    </div>
  );
}
