import React, { useCallback, useContext, useEffect, useState } from 'react';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import { Button } from '../../components/forms/Buttons/Button';
import { getSchoolGoals } from '../../services/core/schoolGoalsService';
import { getSchools } from '../../services/catalog/catalog';
import { ExamYearContext } from '../../utils/contexts/ExamYearContext';
import Read from './CRUD/Read';
import Create from './CRUD/Create';

const SchoolGoals = () => {
  const year = new Date().getFullYear();
  const { examYear, yearList } = useContext(ExamYearContext);
  const [isLoading, setIsLoading] = useState(false);
  const [goals, setGoals] = useState([]);
  const [schools, setSchools] = useState([]);

  const getSchoolGoalsData = useCallback(() => {
    setIsLoading(true);
    getSchoolGoals({ page_size: 100, exam_year: examYear?.uuid })
      ?.then((res) => {
        setGoals(res.results);
      })
      .finally(() => setIsLoading(false));
  }, [examYear?.uuid]);

  useEffect(() => {
    if (!schools.length) {
      getSchools({ page_size: 1000, exam_year: examYear?.uuid }).then((res) => {
        setSchools(res.results);
      });
    }
    getSchoolGoalsData();
  }, [getSchoolGoalsData, schools.length, examYear?.uuid]);

  return (
    <>
      {year === examYear?.year && (
        <div className='mb-8 mr-8 flex flex-row justify-end'>
          <Create
            schools={schools.filter((school) =>
              goals?.find((goal) => goal.school?.uuid === school.uuid)
                ? false
                : true,
            )}
            getData={getSchoolGoalsData}
            examYear={examYear}
            prevYear={yearList?.find(
              (year) => year.year + 1 === examYear?.year,
            )}
          />
        </div>
      )}
      <Read
        data={goals}
        loading={isLoading}
        examYear={examYear}
        getData={getSchoolGoalsData}
      />
    </>
  );
};

export default SchoolGoals;
