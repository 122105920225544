import React from 'react';
import withLoading from '../../../utils/hocs/withLoading';
import Table from '../../../components/Table/Table';
import Update from './Update';

export default withLoading(function Read({ data, getData }) {
  const year = new Date().getFullYear();
  const columns = React.useMemo(
    () => [
      //   {
      //     Header: '#',
      //     id: 'index',
      //     Cell: (props) => {
      //       return (
      //         <span className='text-center'>
      //           {props.row.index + 1}
      //           {/* {props.row.index + 1 + (page - 1) * pageSize} */}
      //         </span>
      //       );
      //     },
      //   },
      {
        Header: '#',
        accessor: 'school.school_id',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Unique iins`,
      },
      {
        Header: 'Школа',
        accessor: 'school.name',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Unique iins`,
      },

      {
        Header: 'Цель',
        accessor: 'goal',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Unique iins`,
      },

      {
        Header: '',
        accessor: 'actions',
        Cell: (props) => (
          <div className='text-center'>
            {year === props.row.original?.exam_year?.year && (
              <Update data={props.row.original} getData={getData} />
            )}
          </div>
        ),
      },
    ],
    [getData, year],
  );
  return (
    <>
      <Table data={data} columns={columns} isSticky />
    </>
  );
});
