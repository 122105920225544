import { Tab } from '@headlessui/react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { classNames } from '../../utils/helpers/helpers';
import DistrictCompareTable from './DistrictCompareTable';
import { getRegions, getSchools } from '../../services/catalog/catalog';
import SchoolListCompareTable from './SchoolListCompareTable';
import { ExamYearContext } from '../../utils/contexts/ExamYearContext';
import Select from '../../components/forms/Select/Select';

export default function StatisticsRegistrationComparison() {
  const [regions, setRegions] = useState([]);
  const [schools, setSchools] = useState([]);

  const [prevYear, setPrevYear] = useState();
  const [curYear, setCurYear] = useState();

  const { yearList, currentYear } = useContext(ExamYearContext);

  useEffect(() => {
    if (yearList?.length) {
      setCurYear({ label: currentYear?.year, value: currentYear?.uuid });
      const prevYear = yearList?.find(
        (year) => year.year === currentYear?.year - 1,
      );
      setPrevYear({ label: prevYear?.year, value: prevYear?.uuid });
    }
  }, [yearList, currentYear]);

  useEffect(() => {
    getRegions().then((res) => {
      const options = res.results.map((region) => ({
        label: region.name,
        value: region.uuid,
      }));
      setRegions(options);
    });

    getSchools({ page_size: 50 }).then((res) => {
      const options = res.results
        .filter(
          (sc) =>
            !(
              sc?.school_id === '043' ||
              sc?.school_id === '057' ||
              sc?.school_id === '038'
            ),
        )
        ?.map((school) => ({
          label: school.name,
          value: school.uuid,
          region: school.region,
        }));
      setSchools(options);
    });

    return () => {};
  }, []);

  const compareStats = useCallback(
    (dataArr) => {
      let custom = dataArr;
      const totals = dataArr?.reduce(
        (acc, curr) => {
          Array.from(
            { length: curYear?.label - prevYear?.label + 1 },
            (_, i) => i + prevYear?.label,
          )?.forEach((year) => {
            acc[`y_${year}`] += curr?.[`y_${year}`];
            acc[`${year}_year_goal`] += curr?.[`${year}_year_goal`];
          });
          return acc;
        },
        {
          ...Array.from(
            { length: curYear?.label - prevYear?.label + 1 },
            (_, i) => i + prevYear?.label,
          )?.reduce((prev, year) => {
            prev[`y_${year}`] = 0;
            prev[`${year}_year_goal`] = 0;
            return prev;
          }, {}),
        },
      );

      let totalsObject = {
        name: 'Total',
        ...Array.from(
          { length: curYear?.label - prevYear?.label + 1 },
          (_, i) => i + prevYear?.label,
        )?.reduce((prev, year) => {
          prev[`y_${year}`] = totals?.[`y_${year}`];
          prev[`${year}_year_goal`] = totals?.[`${year}_year_goal`];
          return prev;
        }, {}),
      };

      custom.unshift(totalsObject);

      custom = custom?.map((d) => {
        let isIncreased;

        switch (true) {
          case d?.[`y_${prevYear?.label}`] > d?.[`y_${curYear?.label}`]:
            isIncreased = 0;
            break;
          case d?.[`y_${prevYear?.label}`] < d?.[`y_${curYear?.label}`]:
            isIncreased = 1;
            break;
          default:
            isIncreased = 2;
        }

        return { ...d, isIncreased };
      });
      return custom;
    },
    [curYear?.label, prevYear?.label],
  );

  if (!yearList?.length)
    return <div className='text-6xl text-red-500'>Please refresh the tab</div>;

  return (
    <div>
      <div className='flex flex-row gap-2 pb-2'>
        <Select
          value={prevYear?.value}
          onChange={setPrevYear}
          options={yearList
            ?.filter((year) => year.year < curYear?.label)
            ?.map((year) => ({
              value: year.uuid,
              label: year.year,
            }))}
        />
        <Select
          value={curYear?.value}
          onChange={setCurYear}
          options={yearList
            ?.filter((year) => year.year > prevYear?.label)
            ?.map((year) => ({
              value: year.uuid,
              label: year.year,
            }))}
        />
      </div>
      <Tab.Group manual>
        <Tab.List className='flex flex-row gap-2'>
          <Tab
            className={({ selected }) =>
              classNames(
                'rounded-md px-4 py-2',
                'bg-blue-100',
                selected
                  ? 'bg-blue-600 text-white'
                  : 'text-blue-600 hover:bg-blue-600 hover:text-white',
              )
            }
          >
            По школам
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                'rounded-md px-4 py-2',
                'bg-blue-100',
                selected
                  ? 'bg-blue-600 text-white'
                  : 'text-blue-600 hover:bg-blue-600 hover:text-white',
              )
            }
          >
            Регионы по школам
          </Tab>
        </Tab.List>
        <Tab.Panels className='mt-4 rounded-md'>
          <Tab.Panel>
            <SchoolListCompareTable
              regions={regions}
              prevYear={prevYear}
              curYear={curYear}
              compareStats={compareStats}
            />
          </Tab.Panel>
          <Tab.Panel>
            <DistrictCompareTable
              schools={schools}
              regions={regions}
              prevYear={prevYear}
              curYear={curYear}
              compareStats={compareStats}
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
