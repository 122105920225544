import React, { useRef } from 'react';
import { patchFormContent } from '../../../../../../../../services/forms/formContentService';

export default function AddOption({
  contentUUID,
  handleAddOption,
  allowOther,
  setAllowOther,
}) {
  const ref = useRef();

  return (
    <>
      <div className='ml-2 w-fit py-2'>
        <input
          onClick={(e) => {
            if (e.detail > 1) return;

            handleAddOption();
          }}
          className='w-fit hover:border-b focus:outline-none'
          placeholder={'Add option'} // to translate
          readOnly
          onBlur={() => {
            ref.current.style.width = '0%';
          }}
          onFocus={() => {
            ref.current.style.width = '100%';
          }}
        />
        <div className='flex flex-row justify-center text-transparent'>
          <div
            ref={ref}
            className='w-0 border-b border-current text-violet-700 transition-[width] delay-100'
          />
        </div>
      </div>
      {!allowOther && setAllowOther && (
        <>
          or{' '}
          <span
            className='cursor-pointer rounded-lg p-2 text-primary hover:bg-blue-50'
            onClick={() =>
              patchFormContent(contentUUID, { allow_other: true })?.then(
                (res) => {
                  setAllowOther(res.allow_other);
                },
              )
            }
          >
            Add "Other"
          </span>
        </>
      )}
    </>
  );
}
