import React, { useCallback, useState } from 'react';
import withLoading from '../../../../utils/hocs/withLoading';
import { getFormStatsAnswers } from '../../../../services/forms/formStatsService';
import { Disclosure } from '@headlessui/react';
import {
  ChevronRightIcon,
  CloudArrowDownIcon,
} from '@heroicons/react/24/outline';

export default withLoading(function AnswerList({ data }) {
  const [answers, setAnswers] = useState([]);
  const [answersLoading, setAnswersLoading] = useState(false);
  const [loadAnswer, setLoadAnswer] = useState(false);

  const getAnswers = useCallback(() => {
    setAnswersLoading(true);
    setLoadAnswer(true);
    getFormStatsAnswers({
      params: {
        content: data?.content?.uuid,
        school: data?.school,
        exam_year: data?.examYear?.uuid,
      },
    })
      ?.then((res) => {
        setAnswers(res.data);
      })
      ?.finally(() => setAnswersLoading(false));
  }, [data?.content?.uuid, data?.examYear?.uuid, data?.school]);

  return (
    <div>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button
              className={`flex w-full flex-row items-center justify-between 
                rounded-lg border ${
                  open && 'rounded-b-none border-b-0'
                } p-2 px-4`}
              onClick={() => {
                if (!loadAnswer && !open) getAnswers();
              }}
            >
              Список ответов
              <ChevronRightIcon
                className={`${open && 'rotate-90 transform'} h-4`}
              />
            </Disclosure.Button>
            <Disclosure.Panel
              className={`${
                answers.length > 20 ? 'h-96 resize-y' : 'max-h-96'
              } overflow-y-scroll border p-2 px-4`}
            >
              {loadAnswer ? (
                <>
                  {answers.map((ans, i) => (
                    <div key={ans.value}>
                      {i + 1}. "{ans.value}" -{' '}
                      <span className='bold'>{ans.count}</span>
                    </div>
                  ))}
                </>
              ) : (
                <div
                  className='flex cursor-pointer flex-row items-center gap-0.5 text-center'
                  onClick={() => {
                    if (answers) getAnswers();
                    setLoadAnswer(true);
                  }}
                >
                  <span> Загрузить ответы</span>
                  <CloudArrowDownIcon className='h-5' />
                </div>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
});
