import React, { useState } from 'react';
import { postAnswerFormQuestion } from '../../../../../services/forms/formContentService';

export default function MultipleChoice({ content, session, onAnswer }) {
  const [fields, setFields] = useState(content?.questionselectcontent);
  const [selected, setSelected] = useState(content?.answer?.choices[0]);

  return (
    <form className='flex flex-col gap-4'>
      {fields?.map((field) => {
        return (
          <div key={field?.uuid} className='flex flex-row items-center gap-2'>
            <input
              className='h-5 min-h-[1.25rem] w-5 cursor-pointer'
              type='radio'
              id={`${field?.uuid}`}
              checked={selected === field?.uuid}
              // defaultChecked={}
              name={`${content?.uuid}`}
              onClick={(e) => {
                e.target.checked = selected !== field?.uuid;
                setSelected((prev) =>
                  prev === field?.uuid ? null : field?.uuid,
                );
                postAnswerFormQuestion(content.uuid, {
                  choices: e.target.checked ? [field?.uuid] : [],
                  session,
                })?.then(() => {
                  if (onAnswer) onAnswer(e.target.checked ? [field?.uuid] : []);
                });
              }}
            />
            <label className='cursor-pointer' htmlFor={`${field?.uuid}`}>
              {field?.content}
            </label>
          </div>
        );
      })}
    </form>
  );
}
