import React, { useCallback, useRef, useState } from 'react';
import { patchFormContentQuestion } from '../../../../../../../services/forms/formContentQuestionService';
import DragableOptions from './OptionLayouts/DragableOptions';
import OptionBlank from './OptionLayouts/OptionBlank';
import { handleAddOption } from './OptionUtils';
import { patchFormContent } from '../../../../../../../services/forms/formContentService';
import { XMarkIcon } from '@heroicons/react/24/outline';
import AddOption from './OptionLayouts/AddOption';
import OtherOption from './OptionLayouts/OtherOption';

export default function MultipleChoice({ content, selected, update }) {
  const [allowOther, setAllowOther] = useState(content.allow_other);
  const [fields, setFields] = useState(content?.questionselectcontent);
  const inputRefs = useRef({});
  const focusRefs = useRef({});

  const saveForm = useCallback(
    ({ value, field, uuid }) => {
      let body = {};
      body[field] = value;
      patchFormContentQuestion(uuid, body)?.then(() => {
        setFields(
          fields?.map((f) => {
            if (f.uuid === uuid) {
              f[field] = value;
            }
            return f;
          }),
        );
      });
    },
    [fields],
  );

  const debounceSaveData = useCallback(
    (value, ref, field, uuid) => {
      // Отправить запрос через 1 секунду после последнего ввода
      if (ref.current) {
        clearTimeout(ref.current);
      }

      ref.current = setTimeout(() => {
        if (!value) return;
        saveForm({ uuid, value, field });
        console.log(value);
      }, 1000);
    },
    [saveForm],
  );

  const handleFocus = (uuid) => {
    if (focusRefs.current) {
      focusRefs.current[uuid].style.width = '100%';
    }
  };

  const handleBlur = (uuid) => {
    if (focusRefs.current) {
      focusRefs.current[uuid].style.width = '0%';
    }
  };

  return (
    <div className='flex flex-col '>
      <div className='flex flex-col gap-4 '>
        <DragableOptions
          fields={fields}
          setFields={setFields}
          selected={selected}
        >
          {fields?.map((field) => {
            return (
              <OptionBlank
                key={field?.uuid}
                field={field}
                fields={fields}
                setFields={setFields}
                update={update}
                selected={selected}
              >
                <input
                  className='h-5 min-h-[1.25rem] w-5 min-w-[1.25rem] cursor-pointer'
                  type='radio'
                  id={`${field?.uuid}`}
                  defaultChecked={field.is_correct}
                  name={`${content?.uuid}`}
                  onClick={(e) => {
                    patchFormContentQuestion(field?.uuid, {
                      is_correct: !field.is_correct,
                    })?.then(() => {
                      e.target.checked = !field.is_correct;

                      fields?.forEach((f) => {
                        if (f.uuid !== field.uuid && f.is_correct) {
                          patchFormContentQuestion(f.uuid, {
                            is_correct: false,
                          });
                        }
                      });

                      const newFields = fields.map((f) => {
                        if (field.uuid !== f.uuid) {
                          f.is_correct = false;
                          return f;
                        } else {
                          f.is_correct = !field.is_correct;
                          return f;
                        }
                      });
                      setFields(newFields);
                      update();
                    });
                  }}
                />
                <div className='w-full'>
                  <input
                    ref={(el) => (inputRefs.current[field.uuid] = el)}
                    defaultValue={field?.content}
                    onBlur={(e) => {
                      handleBlur(field.uuid);
                      if (e.target.value === '') {
                        e.target.value = field?.content;
                      }
                    }}
                    onFocus={() => handleFocus(field.uuid)}
                    onChange={(e) => {
                      debounceSaveData(
                        e?.target?.value,
                        inputRefs.current[field.uuid],
                        'content',
                        field.uuid,
                      );
                    }}
                    className={`${
                      selected && 'hover:border-b'
                    } w-full focus:outline-none`}
                  />{' '}
                  <div
                    ref={(el) => (focusRefs.current[field.uuid] = el)}
                    className='w-0 place-self-center border-b border-violet-700 transition-[width] delay-200'
                  />
                </div>
              </OptionBlank>
            );
          })}
        </DragableOptions>
      </div>
      {allowOther && (
        <div className={`ml-6 mt-1 flex flex-row items-center`}>
          <input
            type='radio'
            name={'other-' + content?.uuid}
            checked={false}
            readOnly
            className='h-5 min-h-[1.25rem] w-5 cursor-pointer'
          />
          <OtherOption
            selected={selected}
            contentUUID={content.uuid}
            setAllowOther={setAllowOther}
          />
        </div>
      )}
      <div
        className={`ml-6 flex flex-row items-center gap-2 ${
          !selected && 'hidden'
        }`}
      >
        <input
          type='radio'
          name={content.uuid}
          checked={false}
          readOnly
          className='h-5 min-h-[1.25rem] w-5 cursor-pointer'
        />
        <AddOption
          contentUUID={content.uuid}
          allowOther={allowOther}
          setAllowOther={setAllowOther}
          handleAddOption={() =>
            handleAddOption({
              uuid: content.uuid,
              fields,
              setFields,
              update,
              ref: inputRefs,
            })
          }
        />
      </div>
    </div>
  );
}
