import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../components/forms/Buttons/Button';
import Input from '../../components/forms/Input/Input';
import { patchSchool } from '../../services/catalog/schools';
import { UserContext } from '../../utils/contexts/UserContext';

export default function SchoolInfo() {
  const { user } = useContext(UserContext);
  const [userSchool, setUserSchool] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    formState: { errors },
    // control,
    register,
    handleSubmit,
    setValue,
    // watch,
  } = useForm();

  useEffect(() => {
    if (user?.school?.uuid) {
      setUserSchool(user?.school);
    }
    return () => {};
  }, [user]);

  useEffect(() => {
    if (userSchool?.uuid) {
      setValue('address', userSchool?.address);
      setValue('name', userSchool?.name);
      setValue('schoolID', userSchool?.school_id);
      setValue('city', 'Астана');
      setValue('phoneNumber', userSchool?.phone_number);
      setValue('email', userSchool?.email);
      setValue('schoolAddText', userSchool?.school_add_text);
      setValue('schoolAddTextBIF', userSchool?.school_add_text_bif);
    }
    return () => {};
  }, [userSchool, setValue]);

  const onSubmit = async (data) => {
    setLoading(true);
    const body = {
      address: data.address,
      name: data.name,
      email: data.email,
      phone_number: data.phoneNumber,
      // school_add_text: data.schoolAddText,
      // school_add_text_bif: data.schoolAddTextBIF,
    };
    await patchSchool(user?.school?.uuid, body)
      .then((res) => {
        setUserSchool(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className='flex flex-col'>
      <div className='border-b-2 px-4 py-4'>
        <h3 className='text-lg font-medium leading-6 text-gray-900'>
          {user?.school?.name}
        </h3>
      </div>
      <div className='mt-10 sm:mt-4'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='mt-5 md:col-span-2 md:mt-0'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='overflow-hidden shadow sm:rounded-md'>
                <div className='bg-white px-4 py-5 sm:p-6'>
                  <div className='grid grid-cols-6 gap-6'>
                    <div className='col-span-6 sm:col-span-6'>
                      <Input
                        type='text'
                        className='w-full'
                        label={'Название школы'}
                        required
                        errorMsg={
                          errors?.name?.type ? 'Поле не может быть пустым' : ''
                        }
                        {...register('name', {
                          required: true,
                        })}
                      />
                    </div>

                    <div className='col-span-6'>
                      <Input
                        type='text'
                        className='w-full'
                        disabled
                        label={'ID школы'}
                        errorMsg={
                          errors?.schoolID?.type
                            ? 'Поле не может быть пустым'
                            : ''
                        }
                        {...register('schoolID', {
                          required: true,
                        })}
                      />
                    </div>

                    {/* <div className='col-span-6 sm:col-span-3'>
                      <Input
                        type='text'
                        className='w-full'
                        disabled
                        label={'Город'}
                        errorMsg={
                          errors?.city?.type ? 'Поле не может быть пустым' : ''
                        }
                        {...register('city', {
                          required: true,
                        })}
                      />
                    </div> */}

                    <div className='col-span-6 sm:col-span-3'>
                      <Input
                        type='text'
                        className='w-full'
                        label={'Школьный WhatsApp номер'}
                        errorMsg={
                          errors?.whatsapp?.type
                            ? 'Поле не может быть пустым'
                            : ''
                        }
                        {...register('phoneNumber', {
                          // required: true,
                        })}
                      />
                    </div>

                    <div className='col-span-6 sm:col-span-3'>
                      <Input
                        type='email'
                        className='w-full'
                        label={'Электронная почта'}
                        errorMsg={
                          errors?.email?.type ? 'Поле не может быть пустым' : ''
                        }
                        {...register('email', {
                          // required: true,
                        })}
                      />
                    </div>

                    <div className='col-span-6 sm:col-span-6'>
                      <Input
                        type='text'
                        className='w-full'
                        label={'Адрес'}
                        required
                        errorMsg={
                          errors?.address?.type
                            ? 'Поле не может быть пустым'
                            : ''
                        }
                        {...register('address', {
                          required: true,
                        })}
                      />
                    </div>

                    {/* <div className='col-span-6 '>
                      <label
                        htmlFor='about'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Дополнительная информация(БИФ)
                      </label>
                      <div className='mt-1'>
                        <textarea
                          disabled
                          id='about'
                          name='about'
                          rows={3}
                          className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 disabled:bg-slate-200 sm:text-sm'
                          defaultValue={''}
                          {...register('schoolAddTextBIF')}
                        />
                      </div>
                    </div>
                    <div className='col-span-6 '>
                      <label
                        htmlFor='about'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Дополнительная информация
                      </label>
                      <div className='mt-1'>
                        <textarea
                          id='about_2'
                          name='about_2'
                          rows={3}
                          className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 disabled:bg-slate-200 sm:text-sm'
                          defaultValue={''}
                          {...register('schoolAddText')}
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className='bg-gray-50 px-4 py-3 text-right sm:px-6'>
                  <Button type='submit' text='Обновить' loading={loading} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
