import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getFormSession,
  postSubmitForm,
} from '../../../../services/forms/formsService';
import useForm from '../useForm';
import Checkboxes from './Options/Checkboxes';
import FileAnswer from './Options/FileAnswer';
import MultipleChoice from './Options/MultipleChoice';
import TextAnswer from './Options/TextAnswer';
import Dropdown from './Options/Dropdown';
import { t } from 'i18next';
import IntegerAnswer from './Options/IntegerAnswer';

const ViewForm = ({ UUID, onSubmit, SubmitMessegeComponent }) => {
  const { uuid } = useParams();
  const [session, setSession] = useState(null);
  const { form, contents, fetchForm } = useForm(UUID ?? uuid, session);
  const contentRefs = useRef({});
  const [requiredsAnswer, setRequiredsAnswer] = useState([]);
  const [notAnswereds, setNotAnswereds] = useState();

  useEffect(() => {
    setRequiredsAnswer([]);
    contents?.forEach((content) => {
      if (content.is_required) {
        setRequiredsAnswer((prev) => [
          ...prev,
          {
            key: content.uuid,
            file: content?.answer?.file ?? '',
            answer: content?.answer?.answer ?? '',
            choices: content?.answer?.choices ?? [],
          },
        ]);
      }
    });
  }, [contents]);

  useEffect(() => {
    if (localStorage.getItem(`form-session--${UUID ?? uuid}`)) {
      setSession(localStorage.getItem(`form-session--${UUID ?? uuid}`));
    } else {
      getFormSession(UUID ?? uuid)?.then((res) => {
        setSession(res.session);
        localStorage.setItem(`form-session--${UUID ?? uuid}`, res.session);
      });
    }
  }, [UUID, uuid]);

  return (
    <div className='relative mx-auto flex max-h-[93vh] min-h-[93vh] flex-col items-center overflow-y-auto border-t bg-slate-200 p-8 sm:p-12 md:p-16 lg:p-24'>
      <div className='relative flex min-w-full max-w-2xl flex-col gap-4 lg:min-w-[42rem] '>
        {form && form?.remaining_attempts < 1 ? (
          <div
            className={`flex flex-col gap-2 rounded-lg border-t-[0.6rem]
              border-t-violet-700 bg-white p-6`}
          >
            <div
              className='text-3xl'
              dangerouslySetInnerHTML={{
                __html: form?.name,
              }}
            />
            {t('signup.survey_recorded')}
            {SubmitMessegeComponent && <SubmitMessegeComponent />}
          </div>
        ) : (
          <>
            <div
              className={`flex flex-col gap-2 rounded-lg border-t-[0.6rem]
                  border-t-violet-700 bg-white p-6`}
            >
              <div
                className='text-3xl'
                dangerouslySetInnerHTML={{
                  __html: form?.name,
                }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: form?.descritption, // fix description
                }}
              />
            </div>
            {contents?.map((content, i) => {
              const onAnswer = (
                value,
                field = content.test_type === 'FILE'
                  ? 'file'
                  : ['TEXT', 'INTEGER'].includes(content.test_type)
                  ? 'answer'
                  : 'choices',
              ) =>
                setRequiredsAnswer((prev) =>
                  prev.map((e) => {
                    if (e.key !== content.uuid) return e;

                    return { ...e, [field]: value };
                  }),
                );
              return (
                <div
                  ref={(e) => (contentRefs[content.uuid] = e)}
                  className={`relative flex flex-col gap-2 rounded-lg bg-white p-6 focus:border focus:border-error ${
                    notAnswereds?.includes(content.uuid) &&
                    'border border-error'
                  }`}
                  key={content?.uuid}
                >
                  <div
                    className='pb-2'
                    dangerouslySetInnerHTML={{
                      __html: content.name, // fix description
                    }}
                  />
                  {content.attachment_img && (
                    <div className='group/image relative my-2 w-fit'>
                      <img
                        alt='attachment'
                        className=': h-fit max-h-64 w-fit'
                        src={content.attachment_img}
                      />
                    </div>
                  )}
                  {content.test_type === 'DROPDOWN' && (
                    <Dropdown
                      content={content}
                      session={session}
                      onAnswer={onAnswer}
                    />
                  )}
                  {content.test_type === 'SELECTONE' && (
                    <MultipleChoice
                      content={content}
                      session={session}
                      onAnswer={onAnswer}
                    />
                  )}
                  {content.test_type === 'SELECT' && (
                    <Checkboxes
                      content={content}
                      session={session}
                      onAnswer={onAnswer}
                    />
                  )}
                  {content.test_type === 'TEXT' && (
                    <TextAnswer
                      content={content}
                      session={session}
                      onAnswer={onAnswer}
                    />
                  )}
                  {content.test_type === 'INTEGER' && (
                    <IntegerAnswer
                      content={content}
                      session={session}
                      onAnswer={onAnswer}
                    />
                  )}
                  {content.test_type === 'FILE' && (
                    <FileAnswer
                      content={content}
                      session={session}
                      onAnswer={onAnswer}
                    />
                  )}
                </div>
              );
            })}
            {!!form && (
              <div className='flex flex-row'>
                <button
                  className='rounded bg-violet-700 p-2 px-6 text-sm font-semibold text-slate-200'
                  onClick={() => {
                    let notAnswered = requiredsAnswer.filter(
                      (e) => e.answer?.length === 0 && e.choices?.length === 0,
                    );
                    console.log(requiredsAnswer);
                    if (!!notAnswered?.length) {
                      setNotAnswereds(notAnswered.map((e) => e.key));

                      contentRefs[notAnswered[0]?.key]?.scrollIntoView();
                      return;
                    }
                    setNotAnswereds([]);
                    postSubmitForm(UUID ?? uuid, session)?.then(() => {
                      fetchForm();
                      if (onSubmit) onSubmit();
                    });
                  }}
                >
                  {t('buttons.finish')}
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ViewForm;
