import React from 'react';

export default function IntegerAnswer() {
  return (
    <div className='ml-6 flex flex-col gap-4'>
      <input
        type='number'
        className='w-full cursor-default border-transparent border-b-slate-200 
        focus:border-transparent focus:border-b-slate-200 focus:ring-0'
        placeholder={'Number answer'} // to translate
        readOnly
      />
    </div>
  );
}
