import bilAPI from '../api';
import classicBilAPI from '../classicApi';

export const getFormContentQuestions = ({ form, page, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      classicBilAPI
        .get(`/forms/questionselectcontent/`, {
          params: { page, page_size, form },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postFormContentQuestion = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI //content
        .post(`/forms/questionselectcontent/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const patchFormContentQuestion = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      classicBilAPI
        .patch(`/forms/questionselectcontent/${uuid}/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const deleteFormContentQuestion = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      classicBilAPI
        .delete(`/forms/questionselectcontent/${uuid}/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};
