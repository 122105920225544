import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button } from '../../../components/forms/Buttons/Button';
import SimpleLoader from '../../../components/Loader/SimpleLoader';
import { getSchools } from '../../../services/catalog/catalog';
import { ExamYearContext } from '../../../utils/contexts/ExamYearContext';
import SchoolYearsEditModal from './SchoolYearsEditModal';
import Select from '../../../components/forms/Select/Select';

export default function SchoolYears() {
  const { examYear, yearList } = useContext(ExamYearContext);
  const [isLoading, setIsLoading] = useState(false);
  const [schools, setSchools] = useState([]);
  const [year, setYear] = useState([]);

  const getSchoolsData = useCallback(() => {
    getSchools({ page_size: 50, exam_year: year }).then((res) => {
      setSchools(res.results);
    });
  }, [year]);

  useEffect(() => {
    getSchoolsData();
  }, [getSchoolsData]);

  return (
    <>
      {isLoading ? (
        <SimpleLoader className='h-10' />
      ) : (
        <>
          <div className='mb-8 mr-8 flex flex-row'>
            <Select
              value={year}
              isClearable
              onChange={(v) => setYear(v?.value)}
              options={yearList.map((school) => ({
                value: school.uuid,
                label: school.year,
              }))}
            />
          </div>
          {schools?.map((school) => {
            return (
              <div
                key={school.uuid}
                className='flex flex-row items-center justify-between border-b py-2 px-20'
              >
                <span>{school.name}</span>
                <SchoolYearsEditModal
                  school={school}
                  years={yearList}
                  getData={getSchoolsData}
                />
              </div>
            );
          })}
        </>
      )}
    </>
  );
}
