import React, { useContext } from 'react';
import { ListBulletIcon } from '@heroicons/react/24/solid';
import { TrashIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
// import bgImage from '../../../assets/images/smallImg.jpg';
import { deleteForm } from '../../../services/forms/formsService';
import { format } from 'date-fns';
import { Button } from '../../../components/forms/Buttons/Button';
import DOMPurify from 'dompurify';

export default function FomrCard({ form, refreshData }) {
  const [t] = useTranslation();

  const handleDeleteClick = (e) => {
    e.preventDefault();
    toast.dark(
      <>
        <div>You sure want to delete content "{form.name}"?</div>
        <div className='mt-3 flex justify-center gap-3'>
          <Button
            text={'Yes'}
            color='error-solid'
            autoFocus
            onClick={() => {
              deleteForm(form.uuid)
                ?.then(() => {
                  refreshData();
                })
                ?.catch((err) => {
                  console.log(err);
                  toast.error(`Error when deleting "${form.name}"`);
                });
            }}
          />
          <Button text={'No'} className='text-white' onClick={toast.dismiss} />
        </div>
      </>,
      {
        autoClose: false,
        closeButton: true,
      },
    );
  };

  return (
    <a target='_blank' rel='noreferrer' href={`/forms/${form.uuid}/edit`}>
      <div className='relative h-48 w-72 overflow-hidden rounded border shadow-md hover:border-violet-500'>
        {/* <img className='w-full' src={bgImage} alt='Placeholder' /> */}
        <div className='bg-black absolute inset-0 opacity-50'></div>
        <div className='absolute top-0 left-0 p-4 text-white'>
          <div className='mb-2 text-xl font-bold'>{form?.title}</div>
          {/* <p className='text-base'>
          Author:{' '}
          {form?.author?.first_name
            ? form?.author?.first_name + ' ' + form?.author?.last_name
            : 'no author'}
        </p> */}
        </div>
        <div className='absolute bottom-0 left-0 flex w-full flex-col bg-white px-4 py-1'>
          <div>
            <div className='mr-4 inline-block w-full'>
              <div className='truncate'>
                {DOMPurify.sanitize(form.name, {
                  USE_PROFILES: {
                    html: false,
                  },
                })}
              </div>
            </div>
          </div>
          <div className='flex flex-row items-center justify-between gap-1'>
            <div className='relative flex flex-row items-center gap-2 text-sm text-slate-500'>
              <div className='flex h-6 w-6 flex-col items-center justify-center rounded bg-violet-500'>
                <ListBulletIcon className='h-5 w-5 text-white' />
              </div>
              created: {format(new Date(form.created), 'dd/MM/yyyy - hh:mm')}
            </div>
            {
              //user &&
              // (form?.author?.uuid === user?.uuid ||
              // user?.roles?.some((r) => r?.name === 'PROJECTADMIN')) ? (
              <TrashIcon
                className='h-9 cursor-pointer rounded-full p-2 text-error hover:bg-red-100'
                title={'Delete'} // to translate
                onClick={handleDeleteClick}
              />
              // ) : undefined
            }
          </div>
        </div>
      </div>
    </a>
  );
}
