import {
  EyeIcon,
  PlusIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/forms/Buttons/Button';
import Table from '../../components/Table/Table';

import {
  deleteApplication,
  getApplications,
} from '../../services/application/application';
import CustomDialog from '../../components/modals/CustomDialog/CustomDialog';
import { UserContext } from '../../utils/contexts/UserContext';
import Input from '../../components/forms/Input/Input';
import { ExamYearContext } from '../../utils/contexts/ExamYearContext';

export default function Applicants() {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const { user } = useContext(UserContext);
  const { examYear } = useContext(ExamYearContext);

  const [applications, setApplications] = useState([]);
  const [applicantToDelete, setApplicantToDelete] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [pageSize, setPageSize] = useState(50);
  const [loading, setLoading] = useState(false);
  const [iinToSearch, setIinToSearch] = useState('');

  const columns = React.useMemo(
    () => [
      {
        Header: '#',
        id: 'index',
        accessor: (_row, i) => i + 1,
        // Cell: (props) => {
        //   return <span>{props.state.pageIndex}</span>;
        // },
      },
      {
        Header: 'ИИН',
        accessor: 'iin',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Unique iins`,
      },
      {
        Header: 'Имя',
        accessor: 'first_name',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Unique names`,
      },
      {
        Header: 'Фамилия',
        accessor: 'last_name',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Unique surnames`,
      },
      // {
      //   Header: 'Район ID',
      //   accessor: '',
      //   Filter: SelectColumnFilter,
      // },
      {
        Header: 'Район',
        accessor: 'district',
        // Filter: SelectColumnFilter,
        Cell: (props) => {
          return <span>{props?.value?.name}</span>;
        },
      },
      {
        Header: 'Дата регистрации',
        accessor: 'created',
        // Filter: SelectColumnFilter,
        Cell: (props) => {
          const custom_date = moment(props?.value).format('DD.MM.yyyy');
          return <span>{custom_date}</span>;
        },
      },

      {
        Header: 'Тел номер',
        accessor: 'parent_phone_number',
      },

      {
        Header: 'ӘОТ',
        accessor: 'social_doc_type',
        Cell: (props) => (
          <>
            {props.value ? (
              <span className=''>Да</span>
            ) : (
              <span className=''>Нет</span>
            )}
          </>
        ),
      },

      {
        Header: '',
        accessor: 'actions',
        fixed: 'right',
        Cell: (props) => (
          <div className='flex gap-2'>
            <Button
              Icon={EyeIcon}
              onClick={() => {
                navigate(`${props.row.original.uuid}`);
              }}
            />
            <Button
              Icon={TrashIcon}
              color='error'
              onClick={() => {
                setModalOpen(true);
                setApplicantToDelete(props.row.original);
              }}
            />
          </div>
        ),
      },
    ],
    [navigate],
  );

  // const data = React.useMemo(() => getApplications(), []);

  useEffect(() => {
    if (examYear?.uuid) {
      setLoading(true);
      getApplications({
        exam_year: examYear?.uuid,
        is_approved: false,
        page: 1,
        page_size: 50,
      })
        .then((res) => {
          setApplications(res.results);
          setCount(res.count);
          setPageCount(Math.ceil(res.count / 50));
        })
        .finally(() => {
          setLoading(false);
        });
    }

    return () => {};
  }, [examYear]);

  const nextPage = () => {
    setLoading(true);

    getApplications({
      exam_year: examYear?.uuid,
      is_approved: false,
      page: page + 1,
      page_size: pageSize,
    })
      .then((res) => {
        setApplications(res.results);
        setCount(res.count);
        setPageCount(Math.ceil(res.count / pageSize));
      })
      .finally(() => {
        setLoading(false);
      });
    setPage((v) => v + 1);
  };

  const previousPage = () => {
    setLoading(true);

    getApplications({
      exam_year: examYear?.uuid,
      is_approved: false,
      page: page - 1,
      page_size: pageSize,
    })
      .then((res) => {
        setApplications(res.results);
        setCount(res.count);
        setPageCount(Math.ceil(res.count / pageSize));
      })
      .finally(() => {
        setLoading(false);
      });
    setPage((v) => v - 1);
  };

  const gotoPage = (pageNum) => {
    setLoading(true);

    getApplications({
      exam_year: examYear?.uuid,
      is_approved: false,
      page: pageNum,
      page_size: pageSize,
    })
      .then((res) => {
        setApplications(res.results);
        setCount(res.count);
        setPageCount(Math.ceil(res.count / pageSize));
        setPage(pageNum);
      })
      .finally(() => {
        setLoading(false);
      });
    setPage((v) => v - 1);
  };

  return (
    <div>
      {/* {['007', '020', '021', '022'].includes(user?.school?.school_id) && ( */}
      {['051'].includes(user?.school?.school_id) && (
        <div className='flex flex-row justify-end'>
          <Button
            text='Добавить заявку'
            Icon={PlusIcon}
            onClick={() => {
              navigate('create');
            }}
          />
        </div>
      )}
      <div className='flex flex-row gap-2'>
        <Input
          type='text'
          className='w-72'
          placeholder='Поиск по ИИН'
          value={iinToSearch}
          onChange={(e, d) => {
            setIinToSearch(e.target.value);
          }}
        />
        <Button
          type='submit'
          text='Поиск'
          color='primary'
          className='h-9'
          onClick={() => {
            setLoading(true);
            getApplications({
              exam_year: examYear?.uuid,
              is_approved: false,
              page_size: pageSize,
              iin: iinToSearch,
            })
              .then((res) => {
                setApplications(res.results);
                setCount(res.count);
                setPageCount(Math.ceil(res.count / pageSize));
                setPage(1);
              })
              .finally(() => {
                setLoading(false);
              });
          }}
        />
        <Button
          type='button'
          Icon={XMarkIcon}
          className='h-9'
          onClick={() => {
            setLoading(true);
            getApplications({
              exam_year: examYear?.uuid,
              is_approved: false,
              page_size: pageSize,
            })
              .then((res) => {
                setApplications(res.results);
                setCount(res.count);
                setPageCount(Math.ceil(res.count / pageSize));
                setPage(1);
              })
              .finally(() => {
                setIinToSearch('');
                setLoading(false);
              });
          }}
        />
      </div>
      <Table
        columns={columns}
        data={applications}
        count={count}
        pageNumber={page}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
        pageCount={pageCount}
        loading={loading}
      />
      <CustomDialog
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        closeModal={() => {
          setModalOpen(false);
        }}
        headerText={'Удалить'}
      >
        <div className='flex flex-col gap-4'>
          <p>
            Вы уверены, что хотите удалить ученика{' '}
            <b>
              {applicantToDelete?.first_name +
                ' ' +
                applicantToDelete?.last_name}
            </b>
            ?
          </p>
          <Button
            type='button'
            color='error'
            text='Удалить'
            onClick={() => {
              deleteApplication(applicantToDelete?.uuid).then(() => {
                getApplications()
                  .then((res) => {
                    setApplications(res.results);
                  })
                  .finally(() => {
                    setModalOpen(false);
                  });
              });
            }}
          />
        </div>
      </CustomDialog>
    </div>
  );
}
