import React, { useEffect, useState } from 'react';
import Input from '../../components/forms/Input/Input';
import Select from '../../components/forms/Select/Select';
import DatePicker from '../../components/forms/DatePicker/DatePicker';
import { Controller, useForm } from 'react-hook-form';
import { UserIcon } from '@heroicons/react/24/outline';
import { Button } from '../../components/forms/Buttons/Button';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import i18n from '../../lang/i18n';
import AvatarModal from './AvatarModal/AvatarModal';

const schema = yup
  .object({
    iin: yup
      .string()
      .length(12, i18n.t('signup.iin_error'))
      .required(i18n.t('signup.iin_error')),
    firstName: yup
      .string()
      .required(i18n.t('signup.enter_name'))
      .matches(
        /^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯәӘіІңҢғҒүҮұҰқҚөӨһҺ -]+$/g,
        i18n.t('signup.in_cyrillic'),
      ),
    lastName: yup
      .string()
      .required(i18n.t('signup.enter_name'))
      .matches(
        /^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯәӘіІңҢғҒүҮұҰқҚөӨһҺ -]+$/g,
        i18n.t('signup.in_cyrillic'),
      ),
    birthDate: yup.date().required(i18n.t('signup.enter_birth_date')),
    gender: yup.string().required(i18n.t('signup.select_gender')),
  })
  .required();

export default function MainInfo({
  applicant,
  setBody,
  nextPage,
  setMainPartCompleted,
}) {
  const [t] = useTranslation();

  const [open, setOpen] = useState(false);
  const [avatar, setAvatar] = useState(null);

  const {
    formState: { errors },
    control,
    register,
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setBody((body) => ({
      ...body,
      iin: data.iin,
      first_name: data.firstName,
      last_name: data.lastName,
      birth_date: format(data.birthDate, 'yyyy-MM-dd'),
      gender: data.gender,
    }));

    if (applicant?.avatar_photo) {
      setMainPartCompleted(true);
      nextPage(1);
    }
  };

  useEffect(() => {
    if (applicant?.gender) {
      setValue('iin', applicant?.iin);
      setValue('firstName', applicant?.first_name);
      setValue('lastName', applicant?.last_name);
      setValue('birthDate', moment(applicant?.birth_date).toDate());
      setValue('gender', applicant?.gender);
    }

    return () => {};
  }, [applicant, setValue]);

  return (
    <form
      className='flex h-full flex-col justify-between'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='flex w-4/5 flex-col gap-4 md:flex-row'>
        <div className='flex w-full flex-col justify-center md:w-2/6'>
          <div className='mb-2 box-border flex h-72 w-full flex-col items-center justify-center rounded-md border bg-white align-middle'>
            {applicant?.avatar_photo ? (
              <img
                src={URL.createObjectURL(applicant?.avatar_photo)}
                alt='userPhoto'
                className='w-72'
              />
            ) : (
              <UserIcon className='h-24 w-24' />
            )}
          </div>
          {!applicant?.avatar_photo && (
            <span className='mb-2 text-xs font-semibold text-error'>
              {t('signup.please_upload_photo')}
            </span>
          )}
          <Button
            type='button'
            text={t('signup.upload_photo')}
            onClick={() => setOpen(true)}
          />
        </div>
        <AvatarModal
          open={open}
          setOpen={setOpen}
          avatar={avatar}
          setAvatar={setAvatar}
          setBody={setBody}
        />
        <div className='grid grid-cols-6 gap-4'>
          <div className='col-span-6'>
            <Input
              type='text'
              className='pr-1'
              label={t('signup.iin')}
              required
              errorMsg={errors?.iin?.message}
              {...register('iin')}
            />
          </div>
          <div className='col-span-6 sm:col-span-3'>
            <Input
              type='text'
              className='w-full'
              label={t('signup.name')}
              required
              errorMsg={errors?.firstName?.message}
              // errorMsg={errors?.firstName?.type ? t('signup.enter_name') : ''}
              {...register('firstName')}
            />
          </div>

          <div className='col-span-6 sm:col-span-3'>
            <Input
              type='text'
              className='w-full'
              label={t('signup.surname')}
              required
              errorMsg={errors?.lastName?.message}
              {...register('lastName')}
            />
          </div>
          <div className='col-span-6 sm:col-span-3'>
            <Controller
              control={control}
              name='birthDate'
              render={({ field }) => (
                <DatePicker
                  className='w-full'
                  label={t('signup.birth_date')}
                  required
                  errorMsg={errors?.birthDate?.message}
                  selected={field.value}
                  onChange={(date) => field.onChange(date)}
                  placeholderText={t('buttons.select')}
                  dateFormat='dd / MM / yyyy'
                  maxDate={new Date()}
                />
              )}
            />
          </div>

          <div className='col-span-6 sm:col-span-3'>
            <Controller
              control={control}
              name='gender'
              render={({ field }) => (
                <Select
                  label={t('signup.gender')}
                  required
                  errorMsg={errors?.gender?.message}
                  isSearchable={false}
                  className='w-full'
                  value={field.value}
                  options={[
                    {
                      label: t('signup.male'),
                      value: 'M',
                    },
                    {
                      label: t('signup.female'),
                      value: 'F',
                    },
                  ]}
                  // placeholder={t('announcement.select_positions')}
                  onChange={(e) => field.onChange(e.value)}
                  // onChange={onChange}
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className='mt-6'>
        <div className='flex flex-row justify-end'>
          {/* <Button type='button' text='Back' color='secondary' /> */}
          <Button type='submit' text={t('buttons.save')} />
        </div>
      </div>
    </form>
  );
}
