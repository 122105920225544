import React, { useCallback, useEffect, useState } from 'react';
import StatsTable from './StatsTable';
import {
  ArrowUpRightIcon,
  ArrowDownRightIcon,
  Bars2Icon,
} from '@heroicons/react/24/solid';
import Select from '../../components/forms/Select/Select';
import DatePicker from '../../components/forms/DatePicker/DatePicker';
import moment from 'moment';
import { getDistrictRegistrationComparisonStats } from '../../services/schoolStatistics/schoolStatistics';

export default function DistrictCompareTable({
  schools,
  regions,
  prevYear,
  curYear,
  compareStats,
}) {
  const [selectedRegion, setSelectedRegion] = useState('all');
  const [schoolList, setSchoolList] = useState(schools);

  const [statsByDistricts, setStatsByDistrict] = useState([]);
  const [districtLoading, setDistrictLoading] = useState(false);
  const [school, setSchool] = useState(null);
  const [endDate, setEndDate] = useState(new Date());

  const minDate = new Date();
  minDate.setDate(1);
  minDate.setMonth(1);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Район',
        accessor: 'name',
      },
      {
        Header: `Сравнение ${prevYear?.label}/${curYear?.label}`,
        accessor: 'isIncreased',
        Cell: (props) => (
          <div className=' font-bold'>
            {props.value === 0 && (
              <ArrowDownRightIcon className='h-6 text-red-600' />
            )}
            {props.value === 1 && (
              <ArrowUpRightIcon className='h-6 text-green-600' />
            )}
            {props.value === 2 && <Bars2Icon className='h-6' />}
          </div>
        ),
      },
      ...Array.from(
        { length: curYear?.label - prevYear?.label + 1 },
        (_, i) => i + prevYear?.label,
      )?.reduce(
        (prev, year) => [
          ...prev,
          {
            Header: `${year}`,
            accessor: `y_${year}`,
          },
        ],
        [],
      ),
    ],
    [curYear?.label, prevYear?.label],
  );

  useEffect(() => {
    if (schools?.length) {
      setSchoolList(schools);
      setSchool(schools[0].value);
    }

    return () => {
      setSchoolList([]);
    };
  }, [schools]);

  const getDistrictStats = useCallback(
    ({ signal }) => {
      if (!school || !prevYear?.label || !curYear?.label) return;
      setDistrictLoading(true);
      getDistrictRegistrationComparisonStats({
        params: {
          school,
          end_date: moment(endDate).format('DD.MM'),
          prev_year: prevYear?.label,
          current_year: curYear?.label,
        },
        signal,
      })
        .then((res) => {
          const newStats = compareStats(res?.results);
          setStatsByDistrict(newStats);
        })
        .finally(() => {
          setDistrictLoading(false);
        });
    },
    [compareStats, school, endDate, curYear?.label, prevYear?.label],
  );

  useEffect(() => {
    const controller = new AbortController();
    getDistrictStats(controller.signal);
    return () => {
      controller.abort();
    };
  }, [getDistrictStats]);

  const filterSchools = (regionUuid) => {
    setSelectedRegion(regionUuid);
    if (regionUuid === 'all') {
      setSchoolList(schools);
    } else if (regionUuid) {
      const filtered = schools.filter((s) => s?.region === regionUuid);
      setSchoolList(filtered);
    }
  };

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-row gap-4'>
        <div className='flex flex-row gap-2 border-r-2 border-gray-800 pr-2'>
          <Select
            label='Школы'
            value={school}
            className='w-80'
            options={schoolList}
            onChange={(e) => {
              setSchool(e.value);
            }}
          />
          <Select
            label='Школы по региону:'
            value={selectedRegion}
            isSearchable={false}
            options={[{ label: 'Все регионы', value: 'all' }, ...regions]}
            onChange={(e) => {
              filterSchools(e.value);
            }}
          />
        </div>
        <div>
          <DatePicker
            className='w-64'
            label={'Сравнение до:'}
            selected={endDate}
            onChange={(date) => {
              setEndDate(date);
            }}
            // placeholderText={t('buttons.select')}
            dateFormat='dd / MM / yyyy'
            minDate={minDate}
          />
        </div>
      </div>
      <StatsTable
        columns={columns}
        data={statsByDistricts}
        loading={districtLoading}
      />
    </div>
  );
}
