import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// import faqRightExtended from '../images/faqRightExtended.png';
import { Disclosure, Transition } from '@headlessui/react';

const FAQSection = () => {
  const [t] = useTranslation();
  const questions = useMemo(
    () => [
      {
        title: t('welcome.faq_question_1'),
        text: t('welcome.faq_answer_1'),
      },
      {
        title: t('welcome.faq_question_2'),
        text: t('welcome.faq_answer_2'),
      },
      {
        title: t('welcome.faq_question_3'),
        text: t('welcome.faq_answer_3'),
      },
      {
        title: t('welcome.faq_question_4'),
        text: t('welcome.faq_answer_4'),
      },
      {
        title: t('welcome.faq_question_5'),
        text: t('welcome.faq_answer_5'),
      },
      {
        title: t('welcome.faq_question_6'),
        text: t('welcome.faq_answer_6'),
      },
      {
        title: t('welcome.faq_question_7'),
        text: (
          <div className='flex flex-col'>
            <span>{t('welcome.faq_answer_7_0_1')}</span>
            <span className=' flex flex-row gap-1 pt-4'>
              <span>{t('welcome.faq_answer_7_0_2')}</span>
              <span className='italic underline'>
                {t('welcome.faq_answer_7_0_3')}
              </span>

              <span className='pl-2'>{t('welcome.faq_answer_7_0_4')}</span>
            </span>
            <ul>
              <li>{t('welcome.faq_answer_7_1')}</li>
              <li>{t('welcome.faq_answer_7_2')}</li>
              <li>{t('welcome.faq_answer_7_3')}</li>
              <li>{t('welcome.faq_answer_7_4')}</li>
            </ul>
          </div>
        ),
      },
      {
        title: t('welcome.faq_question_8'),
        text: t('welcome.faq_answer_8'),
      },
      {
        title: t('welcome.faq_question_10'),
        text: t('welcome.faq_answer_10'),
      },
      {
        title: t('welcome.faq_question_11'),
        text: (
          <div>
            <span>{t('welcome.faq_answer_11')}</span>
            <ul>
              {[...Array(9)]?.map((_, i) => (
                <li key={i}>{` - ${t(`welcome.faq_answer_11_${i + 1}`)}`}</li>
              ))}
            </ul>
          </div>
        ),
      },
    ],
    [t],
  );

  return (
    <div className='flex w-screen flex-row bg-black-landing '>
      <div className='flex flex-col items-center justify-start text-white xl:w-2/3'>
        <span className='px-5 pt-16 text-center text-4xl sm:px-0 sm:text-6xl md:text-left'>
          {t('welcome.faq_1')}
        </span>
        <div className='flex w-full flex-col items-center pb-16 text-white'>
          {questions?.map((q, i) => {
            return (
              <div
                key={i}
                className='mt-6 flex w-11/12 list-none flex-col items-start border-b border-secondary shadow'
              >
                <Disclosure>
                  <Disclosure.Button className='justify-left flex cursor-pointer list-none flex-row items-center text-3xl'>
                    <p className='font-sfproBOld flex flex-1 flex-row items-center justify-start p-4 text-left text-secondary ui-open:text-white'>
                      {q?.title}
                    </p>
                    <div className='flex w-10 flex-row items-center justify-start'>
                      <MinusIcon className='h-7 text-yellow-500 ui-not-open:hidden' />
                      <PlusIcon className='h-7 text-yellow-500 ui-open:hidden' />
                    </div>
                  </Disclosure.Button>
                  <Transition
                    enter='transition duration-100 ease-out'
                    enterFrom='transform scale-95 opacity-0'
                    enterTo='transform scale-100 opacity-100'
                    leave='transition duration-75 ease-out'
                    leaveFrom='transform scale-100 opacity-100'
                    leaveTo='transform scale-95 opacity-0'
                  >
                    <Disclosure.Panel className='px-2 pb-3 text-xl text-secondary'>
                      {q?.text}
                    </Disclosure.Panel>
                  </Transition>
                </Disclosure>
              </div>
            );
          })}
          {/* {questions?.map((q, i) => {
            let open = activeQuestion === i;
            return (
              <details2
                key={i}
                onToggle={(e) => {
                  if (e?.target?.open) {
                    setActiveQuestion(i);
                  } else if (!e?.target?.open && open) {
                    setActiveQuestion(-1);
                  }
                }}
                open={open}
                className='mt-6 flex w-11/12 list-none flex-col items-start border-b border-secondary shadow'
              >
                <summary className='justify-left flex cursor-pointer list-none flex-row text-3xl'>
                  <p
                    className={
                      'font-sfproBOld flex flex-1 flex-row items-center justify-start p-4 text-left ' +
                      (open ? 'text-white' : 'text-secondary')
                    }
                  >
                    {q?.title}
                  </p>
                  <div className='flex w-10 flex-row items-center justify-start'>
                    {open ? (
                      <MinusIcon className='h-7 text-yellow-500' />
                    ) : (
                      <PlusIcon className='h-7 text-yellow-500' />
                    )}
                  </div>
                </summary>
                <div className='px-2 pb-3 text-xl text-secondary'>
                  {q?.text}
                </div>
              </details2>
            );
          })} */}
        </div>
      </div>
      <div className='hidden w-[38rem] min-w-fit flex-row justify-end overflow-hidden bg-[url("pages/Landing/images/faqRightExtended.svg")] bg-right-top bg-no-repeat xl:flex'>
        {/* <img
          className='hidden min-h-fit w-96 xl:flex'
          src={faqRightExtended}
          alt='icons'
        /> */}
        {/* <img
          className='hidden  xl:invisible xl:flex'
          src={faqRight}
          alt='icons'
        /> */}
      </div>
    </div>
  );
};
export default FAQSection;
