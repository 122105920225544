import { ArrowUpTrayIcon, DocumentIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Upload = ({
  className,
  uploadText = 'Upload file',
  file,
  setFile,
  sizeLimit = 4194304,
  accept,
}) => {
  const [dragActive, setDragActive] = useState(false);
  const [t] = useTranslation();
  const inputRef = React.useRef(null);
  const [isOversized, setIsOversized] = useState(false);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };

  const handleFile = (files) => {
    const selectedFile = files[0];
    if (selectedFile.size < sizeLimit) {
      setIsOversized(false);
      setFile(selectedFile);
    } else {
      setIsOversized(true);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };

  return (
    <div
      className={`flex flex-col py-8 ${
        dragActive ? 'border-blue-500' : 'border-gray-300'
      } ${
        file ? '' : 'border-dashed'
      } items-center justify-center gap-2 rounded-xl border-[5px] px-8 ${
        isOversized ? 'border-error' : ''
      } cursor-pointer bg-opacity-10 ${
        isOversized && 'border border-error'
      } ${className}`}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      onClick={() => inputRef.current.click()}
    >
      {file ? (
        <DocumentIcon className='min-h-10 max-h-20 w-1/5 text-gray-300' />
      ) : (
        <ArrowUpTrayIcon className='min-h-10 max-h-20 w-1/5 text-gray-300' />
      )}
      <p className={`max-w-sm truncate ${file?.name ? '' : 'text-gray-400'}`}>
        {isOversized
          ? t('component.file_too_large') // to translate
          : file
          ? file?.name
            ? file?.name
            : file?.split('/')?.length
            ? file?.split('/')[file?.split('/')?.length - 1]
            : file
          : uploadText}
      </p>
      <input
        ref={inputRef}
        type='file'
        accept={accept}
        style={{ display: 'none' }}
        onChange={handleChange}
      />
    </div>
  );
};

export default Upload;
