import React, { useEffect, useState } from 'react';
import { Button } from '../../../components/forms/Buttons/Button';
import { useTranslation } from 'react-i18next';
import {
  DocumentIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { PassRules } from '../../../utils/constants/rules';
import logo from '../../../assets/images/biledu.png';
import { QRCode } from 'react-qrcode-logo';
import moment from 'moment';

const QR_SIZE = 225;

export default function ExportApplication({ onSave, studentInfo }) {
  const [t] = useTranslation();

  const [loadingDownload, setLoadingDownload] = useState(false);

  return (
    <div className='max-w-7xl overflow-x-scroll'>
      <div className='mb-4 flex flex-row justify-end gap-2'>
        <Button
          color='primary'
          type='button'
          text='Скачать PDF'
          Icon={DocumentIcon}
          loading={loadingDownload}
          onClick={async () => {
            setLoadingDownload(true);
            const pdf = new jsPDF('p', 'mm');
            const pdfData = await html2canvas(document.querySelector('#pdf'), {
              // allowTaint: false,
              useCORS: true,
            });
            const img = pdfData.toDataURL('image/png');

            const imgWidth = 210;
            const pageHeight = 295;

            const imgProperties = pdf.getImageProperties(img);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const imgHeight =
              (imgProperties.height * imgWidth) / imgProperties.width;
            let heightLeft = imgHeight;

            let position = 10;
            pdf.addImage(
              img,
              'PNG',
              0,
              position,
              pdfWidth,
              imgHeight,
              undefined,
              'FAST',
            );

            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
              position += heightLeft - imgHeight;
              pdf.addPage();
              pdf.addImage(img, 'PNG', 0, position, pdfWidth, imgHeight);
              heightLeft -= pageHeight;
            }

            if (onSave) onSave();

            pdf.save(
              `${studentInfo?.iin}_${studentInfo?.last_name}_${studentInfo?.first_name}.pdf`,
            );
            setLoadingDownload(false);
          }}
        />
      </div>
      <div />

      <div
        id='pdf'
        className='h-[112rem] w-[80rem] flex-col gap-2 bg-white'
        translate='no'
      >
        <div className='flex w-full justify-center'>
          <img src={logo} alt='logo' width='68' height='68' />
        </div>
        <div className='flex flex-row'>
          <div className='w-1/3' />
          <div className='w-1/3'>
            <h2 className='text-center text-xl'>
              "Білім-инновация" лицейіне қабылдау емтиханына
            </h2>
          </div>
          <div className='w-1/3' />
        </div>
        <div className='flex flex-row items-center'>
          <div className='h-9 w-1/3 bg-slate-900' />
          <div className='inline-block w-1/3 text-center text-4xl font-semibold'>
            Рұқсатнама / Пропуск
          </div>
          <div className='h-9 w-1/3 bg-slate-900' />
        </div>

        <div className='mx-8 mt-12 rounded-md border-2 p-4'>
          <h2 className='mb-4 text-xl font-semibold'>
            Үміткер туралы ақпарат / Информация о кандидате
          </h2>

          <div className='flex flex-row border-b-2 pb-4'>
            <div className='h-fit w-72 overflow-hidden rounded-lg border border-[#000000]'>
              <img
                src={studentInfo?.avatar_photo + `?timestamp=${new Date()}`}
                alt='avatar_photo'
                crossOrigin='anonymous'
              />
            </div>
            <div className='flex w-full flex-col text-left'>
              {studentInfo?.first_name && studentInfo?.last_name ? (
                <h1 className='mb-4 ml-5 text-4xl font-semibold'>
                  {studentInfo.first_name + ' ' + studentInfo.last_name}
                </h1>
              ) : (
                <div>загрузка...</div>
              )}
              <table className='text-lg'>
                <tbody>
                  <tr className='text-4xl font-semibold'>
                    <td className='w-80 px-6 py-3 pr-8'>ЖСН / ИИН :</td>
                    <td>{studentInfo?.iin}</td>
                  </tr>
                  <tr>
                    <td className='px-6 py-3 font-medium'>ID / Login :</td>
                    <td>{studentInfo?.username}</td>
                  </tr>
                  <tr>
                    <td className='px-6 py-3 font-medium'>
                      Құпиясөз / Пароль :
                    </td>
                    <td>{studentInfo?.password}</td>
                  </tr>{' '}
                  <tr>
                    <td className='px-6 py-3 font-medium'>
                      Таңдалған мектеп /<br /> Выбранная школа :
                    </td>
                    <td>{studentInfo?.school?.name}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className='flex flex-row items-center gap-3'>
            <QRCode
              value={studentInfo?.uuid + studentInfo?.uuid}
              logoImage={String(logo)}
              logoPadding
              logoPaddingStyle='circle'
              logoHeight={QR_SIZE * 0.3}
              logoWidth={QR_SIZE * 0.3}
              removeQrCodeBehindLogo
              size={QR_SIZE}
              eyeRadius={4}
              enableCORS={true}
              qrStyle='dots'
            />
            <div>
              <table className='text-lg'>
                <tbody>
                  <tr>
                    <td className='w-[18.5rem] py-3 font-medium'>
                      Туған күні / Дата рождения :
                    </td>
                    <td>
                      {moment(studentInfo?.birth_date).format('DD.MM.yyyy')}
                    </td>
                  </tr>
                  <tr>
                    <td className='py-3 font-medium'>Жынысы / Пол :</td>
                    <td>
                      {studentInfo?.gender === 'M'
                        ? t('signup.male')
                        : t('signup.female')}
                    </td>
                  </tr>
                  <tr>
                    <td className='py-3 font-medium'>Тобы / Группа :</td>
                    <td>
                      {studentInfo?.language === 'K' ? 'Қазақ' : 'Русская'}
                    </td>
                  </tr>
                  <tr>
                    <td className='py-3 font-medium'>Мекен - жайы / Адрес :</td>
                    <td>
                      {studentInfo?.district?.name +
                        ' - ' +
                        studentInfo?.address}
                    </td>
                  </tr>{' '}
                  <tr>
                    <td className='py-3 font-medium'>Телефон :</td>
                    <td>
                      {[
                        studentInfo?.parent_phone_number,
                        studentInfo?.parent_phone_number_2,
                      ]
                        ?.filter((n) => n)
                        ?.map((n, i) => (
                          <React.Fragment key={i}>
                            {!!i && ', '}
                            {n}
                          </React.Fragment>
                        ))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='mx-8 mt-4 rounded-md border-2 p-4'>
          <h2 className='mb-2 text-xl font-semibold'>
            Емтихан туралы ақпарат / Информация об экзамене
          </h2>
          <table className='text-lg'>
            <tbody>
              <tr className='font-black'>
                <td className='w-96 py-3 pr-16 text-lg'>
                  Емтихан тапсыратын орталық /
                  <br /> Центр сдачи экзамена:
                </td>
                <td className='text-xl'>
                  {`${studentInfo?.exam_center?.name} ${
                    studentInfo?.exam_center?.address
                      ? ' ' + studentInfo?.exam_center?.address
                      : ''
                  }`}
                </td>
              </tr>
              <tr className='font-black'>
                <td className='py-3 text-lg'>Күні / Дата :</td>
                <td className='text-xl'>
                  {studentInfo?.exam?.name
                    ? studentInfo?.exam?.name?.substr(8, 10)
                    : '-'}
                </td>
              </tr>
              <tr className='font-black'>
                <td className='py-3 text-lg'>Уақыты / Время:</td>
                <td className='text-xl'>
                  {studentInfo?.exam?.name
                    ? studentInfo?.exam?.name?.substr(0, 5)
                    : '-'}
                </td>
              </tr>
              <tr>
                <td className='py-3 pr-16'>Аудитория :</td>
                <td>{studentInfo?.classroom_id?.classroom_id} аудитория</td>
              </tr>
              <tr>
                <td className='py-3 pr-16'>Орын / Место :</td>
                <td>{studentInfo?.seat_id?.seat_id} орын</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='mx-8 mt-4 rounded-md border-2 border-red-600'>
          <h2 className='mx-4 mb-2 text-xl font-semibold text-red-600'>
            Ескертпе / Примечание
          </h2>
          <PassRules />
        </div>
        <div className='text mx-4 mt-4 flex flex-row justify-center gap-8'>
          <div className='font-semibold'>{studentInfo?.school?.name}</div>
          <div className='flex flex-row items-center'>
            <span>
              <InformationCircleIcon className='mr-2 h-6' />
            </span>
            {`Мектеп телефоны : ${studentInfo?.school?.phone_number} | Мектеп мекен-жайы : ${studentInfo?.school?.address}`}
          </div>
        </div>
      </div>
    </div>
  );
}
