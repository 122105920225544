export const BASE_URL = process.env.REACT_APP_API_URL;

export const EXAMTYPES = ['Пробный 1', 'Пробный 2', 'Онлайн экзамен']; //, '1 тур', '2 тур'];
export const QUESTIONS_TYPES = [
  'Математика',
  'Грамотность чтения 1 (KZ)',
  'Грамотность чтения 2 (KZ)',
  'Грамотность чтения 1 (RU)',
  'Грамотность чтения 2 (RU)',
];
export const ANSWER_OPTIONS = ['A', 'B', 'C', 'D', 'E'];

export const SURVEY_UUID = 'a255c710-7b74-4111-ba31-d988dca269cb'; // prod
