import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';
import { FunnelIcon } from '@heroicons/react/24/solid';
import React from 'react';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from 'react-table';
import { PageButton } from '../forms/Buttons/PageButton';
import { Button } from '../forms/Buttons/Button';

export default function TableWithServerOrdering({
  columns,
  data,
  count,
  pageNumber,
  nextPage,
  previousPage,
  gotoPage,
  pageCount,
  ordering,
  handleOrdering,
  loading,
}) {
  const orderingFields = ordering ? ordering?.split(',') : [];

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data,
        initialState: { pageSize: 50 },
      },
      useFilters,
      useGlobalFilter,
      usePagination,
    );

  return (
    <>
      {headerGroups?.map((headerGroup) =>
        headerGroup.headers?.map((column) =>
          column.Filter ? (
            <div key={column.id}>{column.render('Filter')}</div>
          ) : null,
        ),
      )}
      <div className='flex items-center justify-between py-3'>
        <div className='flex flex-1 justify-between sm:hidden'>
          <Button onClick={() => previousPage()} disabled={!pageNumber === 1}>
            Previous
          </Button>
          <Button
            onClick={() => nextPage()}
            disabled={!pageNumber === pageCount}
          >
            Next
          </Button>
        </div>
        <div className='hidden sm:flex sm:flex-1 sm:items-center sm:justify-between'>
          <div className='flex gap-x-2'>
            {count && (
              <div>
                Всего: <span className='font-bold'>{count}</span>
              </div>
            )}
            {pageNumber && (
              <span className=' text-gray-700'>
                | Страница <span className='font-medium'>{pageNumber}</span> из
                <span className='font-medium'>{' ' + pageCount}</span>
              </span>
            )}

            {/* <label>
              <span className='sr-only'>Items Per Page</span>
              <select
                className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'
                value={state.pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 20]?.map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </label> */}
          </div>
          {pageCount > 1 && (
            <div>
              <nav
                className='relative z-0 inline-flex -space-x-px rounded-md shadow-sm'
                aria-label='Pagination'
              >
                <PageButton
                  className='rounded-l-md'
                  onClick={() => gotoPage(1)}
                  disabled={pageNumber === 1}
                >
                  <span className='sr-only'>First</span>
                  <ChevronDoubleLeftIcon
                    className='h-5 w-5'
                    aria-hidden='true'
                  />
                </PageButton>
                <PageButton
                  onClick={() => previousPage()}
                  disabled={pageNumber === 1}
                >
                  <span className='sr-only'>Previous</span>
                  <ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
                </PageButton>
                <PageButton
                  onClick={() => nextPage()}
                  disabled={pageNumber === pageCount}
                >
                  <span className='sr-only'>Next</span>
                  <ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
                </PageButton>
                <PageButton
                  className='rounded-r-md'
                  onClick={() => gotoPage(pageCount)}
                  disabled={pageNumber === pageCount}
                >
                  <span className='sr-only'>Last</span>
                  <ChevronDoubleRightIcon
                    className='h-5 w-5'
                    aria-hidden='true'
                  />
                </PageButton>
              </nav>
            </div>
          )}
        </div>
      </div>
      <div className='mt-2 flex flex-col'>
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
            <div className='overflow-hidden border-b border-gray-200 shadow sm:rounded-lg'>
              <table
                {...getTableProps()}
                className='min-w-full divide-y divide-gray-200'
              >
                <thead className='bg-gray-50'>
                  {headerGroups?.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers?.map((column) => {
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props

                        let orderingStatus = null;
                        if (
                          orderingFields.includes(
                            `-${column.accessor_for_ordering}`,
                          )
                        ) {
                          orderingStatus = `-${column.accessor_for_ordering}`;
                        } else if (
                          orderingFields.includes(column.accessor_for_ordering)
                        ) {
                          orderingStatus = column.accessor_for_ordering;
                        }
                        return (
                          <th
                            scope='col'
                            className='group px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500'
                            key={column.Header}
                            onClick={() => {
                              if (column.accessor_for_ordering) {
                                handleOrdering(column.accessor_for_ordering);
                              }
                            }}
                          >
                            <div className='flex items-center justify-between'>
                              {column.render('Header')}
                              {/* Add a sort direction indicator */}
                              <span className='flex flex-row gap-0.5'>
                                {column.accessor_for_ordering ? (
                                  orderingStatus?.startsWith('-') ? (
                                    <>
                                      <ChevronDownIcon className='h-4 w-4 text-gray-400' />
                                      {orderingFields.length > 1 &&
                                        orderingFields.indexOf(orderingStatus) +
                                          1}
                                    </>
                                  ) : orderingStatus ? (
                                    <>
                                      <ChevronUpIcon className='h-4 w-4 text-gray-400' />
                                      {orderingFields.length > 1 &&
                                        orderingFields.indexOf(orderingStatus) +
                                          1}
                                    </>
                                  ) : (
                                    <FunnelIcon className='h-4 w-4 text-gray-400 opacity-0 group-hover:opacity-100' />
                                  )
                                ) : null}
                              </span>
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className='divide-y divide-gray-200 bg-white'
                >
                  {loading ? (
                    <tr>
                      {/* {headerGroups.map(() => ( */}
                      <td className='px-2 py-1'>loading...</td>
                      {/* ))} */}
                    </tr>
                  ) : (
                    page?.map((row, i) => {
                      // new
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells?.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className='px-2 py-1'
                              >
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
