import bilAPI from '../api';

export const getClassrooms = ({
  page,
  page_size,
  year,
  exam_time,
  exam_center,
  ordering,
  school,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/core/classrooms/', {
          params: {
            page,
            page_size,
            year,
            exam_time,
            exam_center,
            ordering,
            school,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getAvailableClassrooms = ({
  exam_time,
  exam_center,
  page_size,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/core/classrooms/get_available', {
          params: {
            exam_time,
            exam_center,
            page_size,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const setClassroomSeats = (uuid, seats) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/core/classrooms/${uuid}/set_seats/`, { seats })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const postClassroom = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/core/classrooms/', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const patchClassroom = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/core/classrooms/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getAvailableSeats = ({ classroom, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/core/seats/get_available/', {
          params: {
            classroom,
            page_size,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
