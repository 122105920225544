import { PlusIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { postForm } from '../../../services/forms/formsService';

export default function Add() {
  const navigate = useNavigate();

  return (
    <div
      className='relative flex h-48 w-72 cursor-pointer flex-col
        items-center justify-center rounded border-4 text-lg font-bold
        hover:border hover:border-violet-500
        '
      onClick={() => {
        postForm({})?.then((res) => {
          navigate(`${res.uuid}/edit`);
        });
      }}
    >
      <PlusIcon className='text-amethyst h-12' />
      <div className='from-amethyst bg-gradient-to-r to-error bg-clip-text text-transparent'>
        {'create new form'}
      </div>
    </div>
  );
}
