import bilAPI from '../api';

export const getFormStats = ({
  params: { form, exam_year, school },
  signal,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/forms/form_stats/`, {
          params: { form, exam_year, school },
          signal,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getFormStatsAnswers = ({
  params: { content, exam_year, school } = {},
  signal,
} = {}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/forms/content_answers/`, {
          params: { content, exam_year, school },
          signal,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};
