import bilAPI from '../api';
import classicBilAPI from '../classicApi';
// import contentApi from '../contentApi';

export const getForms = ({ page, page_size, name, search }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/forms/form/`, {
          params: { page, page_size, name, search },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getForm = (uuid, session) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/forms/form/${uuid}/`, { params: { session } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getFormSession = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/forms/form/${uuid}/get_or_create_session/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postForm = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/forms/form/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const patchForm = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/forms/form/${uuid}/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const deleteForm = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/forms/form/${uuid}/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postSubmitForm = (uuid, session) => {
  return new Promise((resolve, reject) => {
    try {
      classicBilAPI
        .post(`/forms/form/${uuid}/submit/`, { session })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getFormResponse = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/forms/forms_answers/${uuid}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getFormResponses = ({ form, page, page_size, uuid }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/forms/forms_answers/`, {
          params: { page, page_size, form, uuid },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getFormUsers = ({ form, page, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/forms/forms_users/`, {
          params: { page, page_size, form },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};
